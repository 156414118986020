
const NUMBER = 0;
const TIME = 1;
const RL = 0;          //направление справа -> налево (для приведения количества символов целой части числа и шаблона)
const LR = 1;          //направление слева -> направо (для приведения количества символов дробной части числа и шаблона)

// Простые текстовые исправления содержимого ячейки
const FixCellForShow = (cell, colInfo) => {
    if (colInfo.Options) {
        if (cell == null) {
            if (colInfo.DataType === 0)
                cell = 0;
            else
                cell = '';
        }
        for (let o of colInfo.Options) {
            if (o.value + "" === cell + "") {
                cell = o.name;
                break;
            }
        }
        return cell;
    }
    if (cell && colInfo.DataType===2) {
        let parts = cell.split('-');
        cell = parts[2]+'.'+parts[1]+'.'+parts[0];
    }
    return cell;
}

function formatNumber(src, format) {
    let result = "";
    if (src.length > format.length) {
        for(let i=0; i<(src.length - format.length); i++) {
            format = "#" + format;
        }
    }
    if (src.length < format.length) {
        for(let i=0; i<(format.length - src.length); i++) {
            if (format[i] ==='0')  result = result + "0";
            if (format[i] ==='#' && result.length>0)   result = result + "0";
        }
    }
    let f;
    if (src.length < format.length) {
        f = format.slice(format.length - src.length);
    } else  {
        f=format;
    }
    for (let i=0; i<src.length; i++) {
        if (src[i]!=='0')  result = result + src[i];
        if (src[i]==='0' && f[i] ==='0')  result = result + src[i];
        if (src[i]==='0' && f[i] ==='#' && result.length>0)  result = result + src[i];
    }
    return result;
}

function formatIntPart(arr) {
    let text = arr[0];
    let format = arr[1];
    let tmp = '';
    let result = '';
    let triad =false;
    let pos = format.indexOf(',');
    if (pos!==-1)  {
        triad = true;
    }
    let c = false;
    for(let i=0; i<format.length; i++) {
        if (format[i] ===',')  continue;
        if ((format[i]==='"' || format[i]==="'") && !c)   c = true;
        if ((format[i]==='"' || format[i]==="'") && c)   c = false;
        if (format[i] ==='0' && c)                                      tmp += text[i];
        if (format[i] ==='0' && text[i]!==' ' && !c)                    tmp += text[i];
        if (format[i] ==='0' && text[i]===' ' && !c)                    tmp += '0';
        if (format[i] ==='#' && c)                                      tmp += text[i];
        if (format[i] ==='#' && text[i]!=='0' && !c )                   tmp += text[i];
        if (format[i] ==='#' && text[i]==='0' && !c && tmp.length>0)    tmp += text[i];
    }
    c = 0;
    for(let i=(tmp.length-1); i>=0; i--) {
        c++;
        result = tmp[i] + result;
        if (i!==(tmp.length-1) && triad && (c % 3) === 0) {
            result = ' ' + result;
        }
    }
    return result;
}

function doRoundFractionalPart(dg, iterator) {
    let num = Number(dg) + iterator;
    if (num<5) return 0;
    if (num>5) return 1;
    let random = Math.random();
    if (random < 0.5)  {
        return 0;
    } else {
        return 1;
    }
}

function formatFractionalPart(arr) {
    let text = arr[0];
    let format = arr[1];
    let tmp = '';
    let result = '';
    let triad =false;
    let pos = format.indexOf(',');
    if (pos!==-1)  {
        triad = true;
    }
    let iterator = 0;
    let isRound = false;
    let c = false;
    for(let i=(format.length-1); i>=0; i--) {
        if (format[i] ==='r') {
            isRound = true;
            iterator = doRoundFractionalPart(text[i], iterator);
            continue;
        }

        if ((format[i]!=='"' && format[i]!=="'" && format[i]!==",") && isRound) {
            isRound = false;
            let char = String(Number(text[i]) + iterator);
            tmp = char + tmp;
            iterator = 0;
            continue;
        }
        if ((format[i]==='"' && format[i]==="'") && isRound) {
           c = true;
           isRound = false;
           iterator = 0;
           continue;
        }
        if (format[i] ===',' && i<1)  { isRound = false; continue;  }
        if (format[i] ===',' && i>=1)    continue;
        if ((format[i]==='"' || format[i]==="'") && !c)   c = true;
        if ((format[i]==='"' || format[i]==="'") && c)   c = false;
        if (format[i] ==='0' && c)                                      tmp = text[i] + tmp;
        if (format[i] ==='0' && text[i]!==' ' && !c)                    tmp = text[i] + tmp;
        if (format[i] ==='0' && text[i]===' ' && !c)                    tmp = '0' + tmp;
        if (format[i] ==='#' && c)                                      tmp = text[i] + tmp;
        if (format[i] ==='#' && text[i]!=='0' && !c )                   tmp = text[i] + tmp;
        if (format[i] ==='#' && text[i]==='0' && !c && tmp.length>0)    tmp = text[i] + tmp;
    }
    c = 0;
    for(let i=0; i<tmp.length; i++) {
        c++;
        result += tmp[i];
        if (i!==0 && triad && (c % 3) === 0) {
            result += ' ';
        }
    }

    return [result, iterator];
}



function formatShowCellTime(text, colInfo) {
    let format = colInfo.Format;
    let arr_src = text.split(':');
    if (arr_src.length>2) {
        return text;
    }
    let arr_f = format.split(':');
    let left = formatNumber(arr_src[0], arr_f[0]).trim();
    let right = formatNumber(arr_src[1], arr_f[1]).trim();
    return left + ":" + right;
}

function normalizeCountChars(direct, src, format) {
    let n_src = '';
    let n_f = '';
    let j;
    switch (direct) {
        case RL:
            j = src.length-1;
            for(let i=(format.length-1); i>=0; i--) {
                if (format[i]==="," || format[i]==='"' || format[i]==="'") {
                    n_f = format[i] + n_f;
                    n_src = ' ' + n_src;
                } else {
                    n_f = format[i] + n_f;
                    if (j>=0) {
                        n_src = src[j] + n_src;
                        j--;
                    } else {
                        n_src = ' ' + n_src;
                    }
                }
            }
            if (j>=0) {
                let hand = "".padEnd(j+1, "#");
                n_f = hand + n_f;
                let head = src.slice(0, j+1);
                n_src = head + n_src;

            }
            break;
        default:
            j = 0;
            for(let i=0; i<=(format.length-1); i++) {
                if (format[i]==="," || format[i]==='"' || format[i]==="'") {
                    n_f +=format[i];
                    n_src += ' ';
                } else {
                    n_f += format[i];
                    if (j<src.length) {
                        n_src += src[j];
                        j++;
                    } else {
                        n_src += ' ';
                    }
                }
            }
            if (j<src.length) {
                for(let i=j; i<src.length; i++) {
                    n_f += "r";
                }
                let rest_src = src.slice(j, src.length);
                n_src += rest_src;

            }
    }
    return [n_src, n_f];
}

function iteratorLeft(src, dt) {
    let num = Number(src);
    return String(num + dt);
}

function formatShowCellNumberMain(cell, format) {
    let text = String(cell);
    text.replaceAll(" ", "");
    let arr_src = text.split('.');
    let arr_f = format.split('.');
    if (arr_src.length>2 || arr_src.length>2) {
        return cell;
    }
    let right_src = arr_src[1];
    if (arr_src[1]==null)  right_src = '';
    let right_f = arr_f[1];
    if (arr_f[1]==null)  right_f = '';
    let n_right = normalizeCountChars(LR, right_src, right_f);
    let right = formatFractionalPart(n_right);
    let left = arr_src[0];
    if (right[1]>0)   {
        left = iteratorLeft(arr_src[0], right[1]);
    }
    let left_f = '0';
    if (arr_f[0]!=='') left_f = arr_f[0];
    let n_left = normalizeCountChars(RL, left, left_f);
    left = formatIntPart(n_left);

    let rt = right[0].trim();
    let lt = left.trim();
    if (rt ==='') {
        return lt;
    } else {
        return lt + '.' + rt;
    }
}

function formatShowCellNumber(num, text, colInfo, isFormat, isFormatForNeg, isFormatForNull) {
    if (num===0 && isFormatForNull) {
        return formatShowCellNumberMain(text, colInfo.FormatForNull);
    }
    if (num===0 && !isFormatForNull && isFormat) {
        return formatShowCellNumberMain(text, colInfo.Format);
    }
    if (num<0 && isFormatForNeg) {
        return formatShowCellNumberMain(text, colInfo.FormatForNeg);
    }
    if (num<0 && !isFormatForNeg && isFormat) {
        return formatShowCellNumberMain(text, colInfo.Format);
    }
    if (num>0 && isFormat) {
        return formatShowCellNumberMain(text, colInfo.Format);
    }
    return text;
}

export const formatShowCell = (cell, colInfo) => {
    let text = FixCellForShow(cell, colInfo);
    let isFormat = false;
    let isFormatForNeg=false;
    let isFormatForNull=false;
    if (colInfo.Format!==undefined && colInfo.Format.length>0)          isFormat=true;
    if (colInfo.FormatForNeg!==undefined && colInfo.FormatForNeg.length>0)    isFormatForNeg=true;
    if (colInfo.FormatForNull!==undefined && colInfo.FormatForNull.length>0)   isFormatForNull=true;
    if (!isFormat && !isFormatForNeg && !isFormatForNull)  {
        return text;
    }
    let type;
    let stext = String(text);
    let posT = stext.indexOf(':');
    if (posT!==-1)  {
        type = TIME;
    }  else {
        type = NUMBER;
    }
    if (type===TIME && !isFormat) {
        return text;
    }
    if (type===TIME && isFormat) {
        return formatShowCellTime(stext, colInfo);
    }
    let num = Number(stext);
    if (isNaN(num) || text==null || text==='') {
        return text;
    } else {
        return formatShowCellNumber(num, stext, colInfo, isFormat, isFormatForNeg, isFormatForNull);
    }
}