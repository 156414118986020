import React from 'react';
import { LOAD } from "../../common/load";
import { KANBAN_DRAG_TYPE } from './kanban';
import { convertDateInputValueToString, convertDateStringToInputValue, convertWebValueToKompas, FieldType, findColumnPosition, findValueByAnotherValue } from "../../common/helpers";

const STICKER_LEFT_BB = 'LEFT_BOTTOM_BLOCK';
const STICKER_RIGHT_BB = 'RIGHT_BOTTOM_BLOCK';

export class Sticker extends React.Component {
    constructor(props) {
        super(props);  
            
        this.state = {            
            inputing: {
                name: false,
                date: false
            }
        }
    }

    handleDragStart = (e)=>{
        let kanban = this.props.kanban; 
        let dragData = kanban.dragData

        this.elem.classList.add('sticker-dragging');
        dragData.stateId = this.props.id_state;     
        kanban.setState({dragging: true}); 
        dragData.stickerDragging = this;
    }

    handleDragEnter = (e)=>{   
        e.stopPropagation();
        let kanban = this.props.kanban;         

        document._DEBUG(4,"handleDragEnter Sticker ==> id: ", this.props.id, "prior: ",this.props.priority) 
        kanban.dragLeaveCommon(KANBAN_DRAG_TYPE.sticker, this, e);  
    }

    handleDragEnd = (e)=>{ 
        let kanban = this.props.kanban; 
        let dragData = kanban.dragData;
        let dropTarget = dragData.dropTarget;

        this.elem.classList.remove('sticker-dragging'); 
        
        // if(dragData.deleting){
        //     this.askStickerForDelete();
        // }     
        // if(dragData.canceling){
        //     this.chooseStickerPlace(DIALOG_CANCEL, kanban.xhrDict.CANCEL_VALUE);
        // }  
        // if(dragData.finishing){
        //     this.chooseStickerPlace(DIALOG_FINISH, kanban.xhrDict.FINISH_VALUE);
        // }     
        this.props.kanban.setState({dragging: false});    
        
        let dropColumn;
        if(dragData.type === KANBAN_DRAG_TYPE.sticker){
            dropColumn = dropTarget.props.column;
        }else if (dragData.type === KANBAN_DRAG_TYPE.column){
            dropColumn = dropTarget;
        }else{
            kanban.sticker = this;
            kanban.setState({dialog: dragData.type});
        }
        
        if(dropColumn && dropColumn.props.id !== dragData.stateId){                
            dropColumn.setState({dragOverActive: false})

            let values = [dropColumn.props.id];
            let fields = [kanban.KWEB.ID_STATE];
            this.saveSticker({values: values, fields: fields});            
        } 
        document._DEBUG(4, "handleDragEnd dragData ==> ", dragData)    
        kanban.setEmptyDragdata();         
    }

    deleteSticker =()=>{  
        let kanban = this.props.kanban; 
        let data = kanban.state.loaded.data;       
        let tfalias = kanban.props.info.TfAlias;

        let requestBody = {};
        requestBody.data = {};        
        requestBody.data.key = this.props.row.key;  
        
        document._DEBUG(5, "deleteSticker ==> ",data.data, this.props.index);
        
        data.data.splice(this.props.index, 1);
       
        kanban.setStateLoaded('data', data);
        LOAD.del_row(tfalias, requestBody, kanban.requestDataFromServer)        
    }

  
    


    handleMouseOver = (e)=>{
        this.elem.classList.add('mouse-over');
    }

    handleMouseLeave = (e)=>{
        this.elem.classList.remove('mouse-over');
    }

    saveSticker = async (_requestBody)=>{
        let kanban = this.props.kanban;        
        let tfalias = kanban.props.info.TfAlias;

        let requestBody = {};
        requestBody.data = {};
        
        requestBody.data.key = this.props.row.key;            
        requestBody.data.row = _requestBody.values;
        requestBody.fields = _requestBody.fields;

        let changeList = []
        for (let i in requestBody.fields ) {
            let field = requestBody.fields[i];            
            let value = requestBody.data.row[i];            
            changeList.push({rowIndex: this.props.index, field: field, value: value})            
        }        
        document._DEBUG(5, "saveSticker ==> row ", this.props.row);
        kanban.handleChangeData(changeList);        
        LOAD.save_row(tfalias, requestBody, kanban.requestDataFromServer);
    }

    handleClickSticker = (e)=>{
        if (e.detail === 2) {
            this.handleDoubleClickSticker();
        }
    }

    onClick =(key, e)=>{
        let st = {};
        st.inputing = {};
        st.inputing[key] = true;
        this.setState(st);          
        this.old_value = e.target.firstChild.data;
        document._DEBUG(5, 'onClick ==> e ', e, this.old_value);

    }
    onBlur =(_key, e)=>{
        let kanban = this.props.kanban;
        let st = {};
        let key = _key.toUpperCase();
        st.inputing = {};
        st.inputing[key] = false;
        this.setState(st);        
        let value = e.target.value;          
        if(key === 'DATE'){value = convertDateInputValueToString(value)};  
                
        document._DEBUG(5, "onBlur ==> ", key, value, this.old_value)
        if(value !== this.old_value){  
            if(key === 'DATE'){                
                value = convertWebValueToKompas(value, FieldType.tftDate)
            };             
            let values = [value];            
            let fields = [kanban.KWEB[key]];            
            this.saveSticker({values: values, fields: fields})
        }       
    }
    onChange =(key, e)=>{        
        let val = e.target.value
        let kanban = this.props.kanban;
        let field = kanban.KWEB[key.toUpperCase()];
        kanban.handleChangeData([{rowIndex: this.props.index, field: field, value: val}])
    }

    handleDoubleClickSticker = ()=>{
        let kanban = this.props.kanban;        
        document._DEBUG(5, 'handleDoubleClickSticker ==> ', this.props.index);
        kanban.setState({sf_show: this})
    }

    getBottomBlock =(type)=>{
        let childrens = '';       
        let kanban = this.props.kanban;  
        if(kanban.KWEB[type]){
            let fields = kanban.KWEB[type];
            let columns = kanban.state.loaded.data.info.Columns;            
            

            let childrensIn = fields.map((field, index)=>{
                let pos = findColumnPosition(columns, field);
                let title = columns[pos].ColName;
                let val = this.props.row.row[pos];
                
                return <div key={index} title={title+": "+val} className="sticker-any-text">{val}</div>
            })
            let css = '';
            if(type === STICKER_LEFT_BB){css = 'sticker-left-bottom'}
            else if (type === STICKER_RIGHT_BB){css = 'sticker-right-bottom'}
            childrens = <div className={css}>{childrensIn}</div>
        }

        return childrens;
    }    

    getStatusStyle =()=>{
        let kanban = this.props.kanban;        
        let style = {}; 
        let statusDiv = '';   
        if(kanban.KWEB.IN_STATUS){
            let colorSet = kanban.KWEB.IN_STATUS.COLOR;
            let status = kanban.findData("ID_STATUS", this.props.row.row);    
            let statusID = kanban.KWEB.IN_STATUS.ID; 
            let color = null;     
            let title = null;   
            if(typeof colorSet == "object"){                
                if(colorSet[status]){color = colorSet[status]}                
            }else if(typeof colorSet == "string"){                 
                let statusData = kanban.state.loaded.status;            
                color = findValueByAnotherValue(statusData, colorSet, statusID, status);                
            }
            if(kanban.KWEB.IN_STATUS.TITLE){              
                title = findValueByAnotherValue(kanban.state.loaded.status, kanban.KWEB.IN_STATUS.TITLE, statusID, status)      
            }
            statusDiv = <div title={title} style={{background: color}} className='status-div'></div>
            document._DEBUG(5, "getStatusColor ==> ", color);
            style = {borderBottom: "2px solid "+color}
        }        
        return [style, statusDiv];
    }

    componentDidMount (){
        this.elem.addEventListener('dragstart', this.handleDragStart);
        this.elem.addEventListener('dragend', this.handleDragEnd);
        this.elem.addEventListener('dragenter', this.handleDragEnter); 

        this.elem.addEventListener('mouseover', this.handleMouseOver);   
        this.elem.addEventListener('mouseleave', this.handleMouseLeave);       
    }

    componentDidUpdate (){
        let input = document.querySelector('.kanban-input')
        if(input){
            input.focus();
        }        
    }

    render () {        
        let id = this.props.id; 
        let kanban = this.props.kanban        
        let row = this.props.row.row;
       
        let nameList = kanban.findData("NAME", row, true);
        let dateList = kanban.findData("DATE", row, true);   
        
        let name = nameList[0];
        let nameTitle = nameList[1];
        let date = dateList[0];
        let dateTitle = dateList[1];

        let namePlace = <div title={nameTitle+': '+name} className='sticker-name' onClick={this.onClick.bind(this, "name")}>{name}</div>
        let datePlace = <div title={dateTitle+': '+date} className='sticker-date' onClick={this.onClick.bind(this, "date")}>{date}</div>     
        if(this.state.inputing.name){
            namePlace = <input className='sticker-name kanban-input' value={name} 
                onBlur={this.onBlur.bind(this, "name")} 
                onChange={this.onChange.bind(this, "name")}
                >                
            </input>            
        }        
        if(this.state.inputing.date){ 
            date = convertDateStringToInputValue(date);
            datePlace = <input className='sticker-date-input kanban-input' type="date" value={date} 
                onBlur={this.onBlur.bind(this, "date")} 
                onChange={this.onChange.bind(this, "date")}
                >                
            </input>            
        }     
        let styleList = this.getStatusStyle();
        let style = styleList[0];
        let statusDiv = styleList[1];        
        
        return (
            // <div className='sticker-main'>
                <div style={style} className='sticker-body' draggable="true" ref = {(el)=>{this.elem = el}}
                onClick={this.handleClickSticker}
                >
                    <div className='flex-space'>
                        {statusDiv}
                        <div className='sticker-id'>{id}</div>
                        {datePlace}                 
                    </div>                
                    {namePlace}  
                    <div className='flex-space'>
                        {this.getBottomBlock(STICKER_LEFT_BB)}  
                        {this.getBottomBlock(STICKER_RIGHT_BB)}                 
                    </div>              
                </div>
            // </div>
            
        )
    }
}