import { useState } from "react";
import nextId from "react-id-generator";
import { CatalogControl } from "../../components/dialogs/catalog";

export function InputRadio(props) {    
    let id = nextId();
    
    return (
        <div className='form-check'>
            <input className="form-check-input" type="radio" name="flexRadioDefault" id={"flexRadioDefault_"+id} 
                defaultChecked ={props.isCheck}
                value={props.value}  
                onClick={props.onClick ? props.onClick: null}          
            ></input>
            <label className="form-check-label" htmlFor={"flexRadioDefault_"+id}>{props.label}</label>
        </div>
    )
}

export function InputWithCatalog(props) {
    // должно быть передано в props: ТФ каталога, поле откуда брать текст, тип связи с каталогом, если связь строгая то поле откуда брать код, 
    // функция callback при изменении значения input куда выдаем если
    // нужно передать компонент который будет использоваться в CatalogControl
    const [value, setValue] = useState(props.value); // value формат: value
    const catalogControl = new CatalogControl(props.component);
    let id = nextId();

    function openCatalog(){
        alert('Ждем подключения');
    }

    function onChange(e){
        setValue(e.target.value)
    }

    return (
        <div className='input-group'>
            <label htmlFor={"flexRadioDefault_"+id}>{props.label}</label>
            <input className="form-control cell-input" type={props.type} name="flexRadioDefault" id={"flexRadioDefault_"+id}  
                placeholder={props.label}               
                defaultValue={props.value}  
                onChange={onChange}        
            ></input>  
            <div>
                <button className="catalog-btn btn btn-secondary"
                    onClick={openCatalog}
                    style={{height:'28px', width:'28px', lineHeight: '1', padding: '0px' }}
                >...</button>
                {/* <datalist id={list}> */}
                    {/* {this.getOptionsForDatalist()} */}
                {/* </datalist> */}
            </div>        
        </div>
    )
}



{/* <ScreenField key={index} index={index} value={val} title={title} rowKey={this.state.rowKey} column={column}
onInput={'onSave' in this.props ? this.onInputScreenField : null}
saveValueInSF={'onSave' in this.props ? this.onSaveValueInSF : null}
catalogControl={this.catalogControl}
directMode = {this.state.directMode}
ref={'onSave' in this.props ? (el)=>{this.ScreenFields[index] = el} : null}>
</ScreenField> */}