import './App.css';
//import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import {isMobile} from 'react-device-detect';

import { Login, LoginLevels } from './components/login';
import { GenerateMenu } from './components/menu.js';
import { GenerateTForm } from './components/tform/tform.js';
import { SettingFS } from './components/fSetting.js';
import { LOAD } from './common/load';
import { BpCall } from './common/bp_call';
import { parseUrlParams } from './common/helpers';
import { AppContext } from './common/app_context';
import { ChoiceDialog } from "./components/dialogs/choiceDialog.js";
import { ChoiceFiles } from "./bp_lang/bp_choiceFile.js";
import { Bill } from './templates/bill';


const MENU_LV = 4;         // получение меню
const TF_WAIT_LV = 5;         // ожидание данных тф (после запроса этих данных)
const STATUS_LV = 6;         // проверка статуса сервиса

export class App extends React.Component {
    constructor(props) {
        super(props);
        let login = props.login;
        let need_ask_access = false;
        if (login == null) {
            login = "";
            need_ask_access = true;
        }
        this.state = {
            level: props.level,
            user: props.fio,
            user_login: login,
            menu: {},
            tform: {},
            tfalias: "",
            fixRow: true,
            choice_prm: [],
            choice: false,

        };
        if (this.props.level === STATUS_LV || this.props.level === LoginLevels.USER_LV) {
            (this.reqStatusSrv.bind(this))();
        }
        this.tformRef = React.createRef();
        this.menuRef = React.createRef();
        this.activeComponent = null;
        this.bpCall = new BpCall(this);

        if (need_ask_access) {
            let _ask_xhr = LOAD.ask_access(()=>{
                //let a = _ask_xhr;
                let usrStatus = JSON.parse(_ask_xhr.responseText);
                if (usrStatus != null && usrStatus.login != null && usrStatus.login.length > 0) {
                    this.state.level = LoginLevels.USER_LV;
                    (this.reqStatusSrv.bind(this))();
                }
            },()=>{
                //let a= _ask_xhr;
                let usrStatus = JSON.parse(_ask_xhr.responseText);
                if (usrStatus != null && usrStatus.login != null && usrStatus.login.length > 0) {
                    this.state.level = LoginLevels.USER_LV;
                    (this.reqStatusSrv.bind(this))();
                }
            });
        }
    }

    // обрабатываем все нажатия клавиш
    onKeydown = (e) => {
        let processed = false;
        // if (this.tformRef && this.tformRef.current && this.tformRef.current.onAppKeydown) {
        //     processed = this.tformRef.current.onAppKeydown(e);
        // }
        if (this.activeComponent) {
            processed = this.activeComponent.onAppKeydown(e);
        }
        if (!processed && Object.keys(this.state.menu).length > 0) {
            let objBack;
            objBack = this.menuRef.current.onAppKeydown(e);
            processed = objBack.flag;
        }
        if (processed) {
            e.preventDefault();
        }
    }

    // обрабатываем отпускания клавиш
    onKeyUp = (e) => {
        let processed = false;
        if (this.activeComponent && this.activeComponent.onAppKeyUp) {
            processed = this.activeComponent.onAppKeyUp(e);
        }
        if (!processed && Object.keys(this.state.menu).length > 0) {
            let objBack;
            objBack = this.menuRef.current.onAppKeyUp(e);
            processed = objBack.flag;
        }
        if (processed) {
            e.preventDefault();
        }
    }

    handleChangeStateLogOut() {
        LOAD.logout();

        let lev = LoginLevels.SIGN_LV;
        let user = "";
        let login = "";
        let menu = {};
        this.setState({
            level: lev,
            user: user,
            user_login: login,
            menu: menu
        });
    }

    handleClickGotoSetting() {
        this.xhr = LOAD.get_serv_cfg(this.waitServCfg.bind(this));
        this.setState({
            level: STATUS_LV
        });
    }

    waitServCfg(){
        let xhr = this.xhr;
        console.log("xhrCfg -->", xhr);
        if (xhr.status === 200) {
            let rText = xhr.responseText;
            let objCfg = JSON.parse(rText);
            console.log("cfgObj >>", objCfg);
            let lev = LoginLevels.SETTING_LV;
            this.setState({
                level: lev,
                servCfg: objCfg
            });
        }

    }

    handleClickMenuItem(arr_tf) {
        let obj_f = {};
        obj_f.column = "";
        let obj_sort = {};
        obj_sort.counter = 0;  //счетчик подключенных к сортировке колонок
        if (this.activeComponent!=null)
            this.activeComponent.setState({s_filtr: obj_f, s_sort: obj_sort, curCell: {row: 0, column: {}, columnKey: 0, edit: false}});

        if (this.tformRef.current != null) {
            document._DEBUG(5, "******** CLEAR addedTfOptions");
            this.tformRef.current._clearFromBp();
        }

        this.setState({
            level: TF_WAIT_LV,
            tfalias: arr_tf[0],
            title_tf: arr_tf[1],
        });
    }

    handleClickFixhItem(fixHRow) {
        let data = {
            "isFixedRowHeight": fixHRow
        }
        LOAD.save_fixHRow(data);
        this.setState({
            fixRow: fixHRow,
        });
    }

    handleSaveCfgFS() {
        let lev = LoginLevels.SIGN_LV;
        let fs = false;
        this.setState({
                level: lev,
                firstStart: fs
        });
    }

    reqStatusSrv = () => {
        this.xhr = LOAD.get_serv_status(this.waitStatusSrv.bind(this));
    }

    waitStatusSrv(){
        let xhr = this.xhr;
        console.log("xhrStatusSrv -->", xhr.status, xhr);
        if (xhr.status === 200) {
            let rText = xhr.responseText;
            let objStatus = JSON.parse(rText);
            console.log("status >>", objStatus);
            let lev;
            let fs;
            if (objStatus.service.isReady) {
                lev = LoginLevels.SIGN_LV;
                fs = false;
            } else {
                lev = LoginLevels.SIGN_FIRST_LV;
                fs=true;
            }
            if (objStatus.user !==undefined)
                this.admin = (objStatus.user.isAdmin || objStatus.user.isConstAdmin) ? true : false;
            if (this.props.level===LoginLevels.USER_LV || this.state.level===LoginLevels.USER_LV)
                (this.reqMainMenu.bind(this))();
            else
                this.setState({
                    level: lev,
                    firstStart: fs
                });
        }
    }

    reqMainMenu(){
        this.xhr = LOAD.get_main_menu(this.waitMainMenu.bind(this));
    }

    waitMainMenu(){
        let xhr = this.xhr;
        console.log("xhrMenu -->", xhr);
        if (xhr.status === 200) {
            let rText = xhr.responseText; //.replace(/\\/gi, '\\\\');
            let objMenu = JSON.parse(rText);
            console.log("menu >>", objMenu);
            let lev = MENU_LV;
            let fixHR = objMenu.options.isFixedRowHeight;
            this.setState({
                level: lev,
                menu: objMenu,
                fixRow: fixHR,
                admin: this.admin
            });
        }
    }

    handleClickTbBtnCloseTf = () =>{
        let lev = MENU_LV;
        this.setState({
            level: lev
        });
    }

    isChoiceDialog = ()=>{
        if (this.state.choice_prm.length!==0)    {
            //console.log("isChoiceDialog choice_prm", this.state.choice_prm);
            return (
                <ChoiceDialog
                    title = {this.state.choice_prm[0]}  textChoice = {this.state.choice_prm[1]} variants = {this.state.choice_prm[2]}
                    screenOut = {this}
                    promise = {this.state.choice_prm[3]}
                />
            )
        }
        else
            return ('');
    }

    getGenerateMenu=()=>{
        return (
            <GenerateMenu menu ={this.state.menu}
                user ={this.state.user}  user_login ={this.state.user_login}
                fixHRow = {this.state.fixRow}  adminFlag = {this.state.admin}
                onClickLogOut = {() => this.handleChangeStateLogOut()}
                handleClickGotoSetting = {() => this.handleClickGotoSetting()}
                handleClickMenuItem = {(tfalias) => this.handleClickMenuItem(tfalias)}
                handleClickFixhItem = {(flag) => this.handleClickFixhItem(flag)}
                app = {this}
                ref = {this.menuRef}
            />
        );
    }

    renderRingPage = (messageText) => {
        const messageBox = {
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }
        return (
            <div>
                <main role="main" className="container-fluid" style={messageBox}>
                    <div className="lds-dual-ring"></div>
                    <p style={messageBox}> {messageText} </p>
                </main>
            </div>
        );
    }

    render() {
        console.log("!!!!! [render]", this.state.tfalias);
        console.log("state -->", this.state.level);

        if(this.props.billParam != null && this.props.billParam !== ''){
            return (
                <Bill number={this.props.billParam}></Bill>
            )
        }

        switch(this.state.level) {
            case LoginLevels.INVALID_LPA_LV:
            case LoginLevels.INVALID_LOGIN_AUTH_LV:
            case LoginLevels.INVALID_PASS_AUTH_LV:
            case LoginLevels.NO_LPA_LV:
            case LoginLevels.SIGN_LV:
            case LoginLevels.SIGN_FIRST_LV:
                return (
                    <Login app={this} level={this.state.level} />
                )

            case STATUS_LV:
                return this.renderRingPage("Загрузка...");

            case LoginLevels.POST_LPA_LV:
            case LoginLevels.USER_LV:
                return this.renderRingPage("Авторизация...");

            case LoginLevels.SETTING_LV:
                let menu = (!this.state.firstStart) ? this.getGenerateMenu() : null;
                return (
                    <div className="page-setting">
                        <AppContext.Provider value={{ app: this }}>
                            {menu}
                            <div className="setting">
                                <SettingFS
                                    handleSaveCfgFS = {() => this.handleSaveCfgFS()}
                                    handleSaveCfg = {() => this.handleClickGotoSetting()}
                                    fstart = {this.state.firstStart}  setting ={this.state.servCfg}
                                />
                                <ChoiceFiles component={this}  />
                            </div>
                        </AppContext.Provider>
                    </div>

                );

            case MENU_LV:
                console.log("state -->", this.state.level, this.state.user, this.state.menu);
                return (
                    <div>
                        <AppContext.Provider value={{ app: this }}>
                            {this.getGenerateMenu()}
                            {this.isChoiceDialog()}
                            <ChoiceFiles component={this}  />
                        </AppContext.Provider>
                    </div>
                );

           default:
                document._DEBUG(5, "state tf -->", this.state.tform, this.state.tfalias, this.state.title_tf);
                return (
                    <AppContext.Provider value={{ app: this }}>
                    <>
                        {this.getGenerateMenu()}
                        <main role="main" className="container-fluid full-flex">
                            <GenerateTForm
                                tfalias = {this.state.tfalias}  title_tf = {this.state.title_tf}
                                fixHRow = {this.state.fixRow}
                                app = {this}
                                parent = {this}
                                ref = {this.tformRef}
                                handleClickTbBtnCloseTf = {()=>this.handleClickTbBtnCloseTf()}
                                rerender = {Date.now()}
                            />
                        </main>
                    </>
                    </AppContext.Provider>
                )

        }
    }
}


export function Main() {
    window.onload = function() {

        let params = prepareDebug();
        let contextmenuParam = params.contextmenu;
        let billParam = params.bill;

        const domContainer = document.querySelector('#root');
        const session_ok = document.querySelector('.server-session-ok');
        const session_fail = document.querySelector('.server-session-fail');
        let lev=STATUS_LV;
        let fio="";
        let login="";
        if (session_ok!=null) {
            lev = LoginLevels.USER_LV;
            login = session_ok.dataset.login;
            fio = session_ok.dataset.fio;
        } else if (session_fail == null) {
            login = null;
        }
        let appRef = React.createRef();
        ReactDOM.render(
            <App
            level = {lev}  fio = {fio}   login = {login}   billParam={billParam}
            ref = {appRef} contextmenuParam = {contextmenuParam}
            />,
            domContainer
        );
        window.addEventListener('keydown', e => {
            appRef.current.onKeydown(e);
        });
        window.addEventListener('keyup', e => {
            appRef.current.onKeyUp(e);
        });
    }
}

export function prepareDebug() {
    let params = parseUrlParams();
    let debugParam = params.debug;
    if (!debugParam) {
        debugParam = 0;
    } else if (debugParam === 'all') {
        debugParam = 10;
    } else {
        debugParam = debugParam - 0;
    }

    let _console_log = console.log;

    document._DEBUG = (level, ...args) => {
        if (level > 10) {
            level = 10;
        } else if (level <= 0) {
            level = 1;
        }
        if (debugParam < level) {
            return
        }
        let prefix = "";
        for (let i=1; i<level; i++) {
            prefix += "  ";
        }
        _console_log('[debug]' + prefix, ...args);
    }
    console.log = (...args) => {document._DEBUG(10, ...args);}

    document._DEBUG(1, "[REACT] version:", React.version, "MOBILE:", isMobile);
    return params;
}

export default Main;
