export class TfEditRows {

    constructor(tf_component) {
        this.tf_component = tf_component;
        this.edit_rows = {};
    }

    onChangeCellValue = (rowKey, colKey, value) => {
        document._DEBUG(5, "--- onChangeCellValue", rowKey, colKey, value);
        let data_row = this.tf_component.rowByKey(rowKey);
        if (data_row == null) {
            return;
        }
        let cell = data_row.row[colKey];
        let edit_row = this.edit_rows[rowKey];
        let edit_cell = null;
        let column = null;
        let base_value = cell;
        if (edit_row != null) {
            edit_cell = edit_row[colKey];
        }
        if (edit_cell != null) {
            base_value = edit_cell.base_value;
            column = edit_cell.column;
        }
        if (column == null) {
            column = this.tf_component.state.tf.info.Columns[colKey];
        }

        let is_identic = value === base_value;
        document._DEBUG(5, ">>> CELL:", base_value, "->", value, "is_identic:", is_identic);

        if (edit_row == null) {
            if (!is_identic) {
                edit_row = this.edit_rows[rowKey] = {};
                edit_row[colKey] = {
                    "value": value, "base_value": base_value, "column": column
                };
            }
        } else {
            if (is_identic) {
                if (colKey in edit_row) {
                    delete edit_row[colKey];
                }
            } else {
                edit_row[colKey] = {
                    "value": value, "base_value": base_value, "column": column
                };
            }
            if (Object.keys(edit_row).length === 0) {
                delete this.edit_rows[rowKey];
            }
        }
        document._DEBUG(5, ">>> edit_rows:", this.edit_rows);
    }

    findChangedCell = (rowKey, colKey) => {
        let edit_row = this.edit_rows[rowKey];
        if (edit_row == null) {
            return null;
        }
        return edit_row[colKey];
    }

    hasEditRow = (rowKey) => {
        return (this.edit_rows[rowKey]!==undefined);
    }

    clearAll = () => {
        this.edit_rows = {};
    }

}
