import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

import './css/index.css';
import './css/menu.css';
import './css/tform.css';
import './css/screenform.css';
import './css/fsetting.css';
import './css/templates.css';
import './css/kanban.css';

import { Main } from './App';
import reportWebVitals from './reportWebVitals';

Main();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
