import React, { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { Restrict } from "../../common/helpers";
import { GUTTER_SIZE, ROW_HEIGHT } from "./tf_components";
import { formatShowCell } from "./tf_formatShow";
import { ADD_ROW_INDEX, TITLE_ROW_INDEX } from "./tf_row";
import { ScreenField } from "../screenField";
import { convertKompasValueToWeb } from "../../common/helpers";
import { TfContext } from "./tf_funcs";

// export const TfContext = createContext();

// Простая ячейка заголовка
export const TitleCellOnly = ({i, fixed, colInfo, initialLeft}) => {
    const {tf_component} = useContext(TfContext);

    let s_filtr = tf_component.state.s_filtr;
    let s_sort = tf_component.state.s_sort;
    let title = colInfo.ColTitle.replace(/;/gi, ' ');
    if (s_filtr[i] !== "" && s_filtr[i] != null) {
        title = <>{title}<FontAwesomeIcon icon={faFilter} /></>
    }
    if (s_sort[i] !==undefined && s_sort[i] != null) {
        let icon = (s_sort[i].order==="asc") ? <FontAwesomeIcon icon={faAngleUp}/> : <FontAwesomeIcon icon={faAngleDown}/>;
        let pic_sort = (
            <div style={{display: "flex",  flexDirection: "column", marginRight: "2px", color: "red"}}>
                {icon}
                <p>{s_sort[i].num}</p>
            </div>
        );
        title = <div style={{display: "flex"}}>{pic_sort}{title}</div>;
    }
    return (
        <div
            className={"GridItem context-menu-one col-title tf-title-cell " +
                (fixed ? 'tf-title-cell-sticky' : 'tf-title-cell-absolute') +
                (colInfo.FieldKind===2 && colInfo.FieldName==="" ? ' col-title-danger' : '')}
            style={{
                left: initialLeft,
                height: tf_component.getRowHeight(TITLE_ROW_INDEX),
                width: tf_component.calcCellWidth(colInfo),
                }}
            unselectable="on"
            data-col-num={i}
            onClick={(e) => {tf_component.handleClickHeaderTF(e, i)}}
            key={"sticky-cell-"+i}
            title={title}
        >
            {title}
        </div>
    );
}

// Ячейка чистая - без выделения и редактирования.
export const TfCell = ({rowIndex, i, style, fixCol, fixed, colInfo, intoTitle, initialAddCls=''}) => {

    const { tf_component } = useContext(TfContext);

    let lines_start = tf_component.state.lines_start;
    let lines_end = tf_component.state.lines_start + tf_component.state.lines_count;

    let is_adding_row = rowIndex === ADD_ROW_INDEX;
    if (is_adding_row) {
        return AddRowCell({rowIndex: ADD_ROW_INDEX, i, style, fixCol, fixed, colInfo, intoTitle})
    }

    let rowIndexCalced = rowIndex
    if (rowIndexCalced > lines_end || rowIndexCalced < lines_start) {
        // FIXME не бывает такого
        return null;
    }

    let j = tf_component.IndexInRows(rowIndexCalced);
    if (j < 0 || j >= tf_component.getData().length)
        return null;

    let cell_data = tf_component.CellData(rowIndexCalced, i);
    let cell = convertKompasValueToWeb(cell_data/*.value*/, colInfo.DataType);

    let isChanged = tf_component.tfEditRows.findChangedCell(rowIndexCalced, i) != null;

    let add_cls = '';
    let restricts = tf_component.restricts;
    if (restricts[rowIndexCalced] && restricts[rowIndexCalced][i]) {
        let restrict = restricts[rowIndexCalced][i];
        if (restrict === Restrict.rWarning) {
            add_cls = "field-warning "
        } else if(restrict === Restrict.rForbid) {
            add_cls = "field-forbidden "
        }
    }

    let ReadOnly = colInfo.ReadOnly;
    let text = formatShowCell(cell, colInfo);
    let customStyle = {
        ...style,
        left: colInfo.initialLeft,
        top: 0,
        width: tf_component.calcCellWidth(colInfo, false),
        height: style.height - GUTTER_SIZE,
        border: null,
        borderBottom: ( rowIndex >= 0 ? '1px solid #cccccc' : '2px solid #9ec9f3' ),
        fontWeight: rowIndex !== TITLE_ROW_INDEX ? '' : 'bold',
        cursor: rowIndex > 0 ? '' : 'pointer',
        position: fixed ? 'sticky' : 'absolute',
        zIndex: fixed ? 3 : 0,
    }
    if (tf_component.isIncludedInLinesIns(rowIndex))  {
        customStyle.color = "red";
    }
    if (ReadOnly) {
        if (rowIndex % 2 !== 0)
            customStyle.backgroundColor = i < fixCol ? 'rgb(198 221 211)' : 'rgb(222 241 233)';
        else if (i < fixCol)
            customStyle.backgroundColor ='#d3ede5';
    } else {
        customStyle.backgroundColor = (!isChanged ? (
            i < fixCol ? (rowIndex % 2 === 0 ?'#ededed':'#dddddd') : (rowIndex % 2 === 0 ?'':'#f5f5f5')
            ) : '#f8d122');
    }
    return (
        <div
            className={add_cls + "GridItem tf-cell context-menu-two " + (ReadOnly ? ' readOnlyCell-css' : '') + " " + initialAddCls}
            style={customStyle}
            data-col-num={i}
            onClick={tf_component.handleClickCell.bind(tf_component, null/*info_columns*/, i, null, rowIndexCalced)}
            unselectable="on"
            key={"cell-" + rowIndex + "-" + i}
            title={text ? (text === true ? "" : text) : ""}
        >
            {text}
        </div>
    )
}

// Ячейка новой строки
export const AddRowCell = ({rowIndex, i, style, fixCol, fixed, colInfo, intoTitle}) => {
    const {tf_component} = useContext(TfContext);

    let curCell = tf_component.state.curCell;
    let columnKey = tf_component.currentRowRef.current != null ? tf_component.currentRowRef.current.columnKey : curCell.columnKey;
    let curCellBorderNeed = false;
    if (columnKey === i && !intoTitle) {
        curCellBorderNeed = true;
    }
    let curCellEdit = curCellBorderNeed && curCell.edit;
    let isChanged = tf_component.tfEditRows.findChangedCell(ADD_ROW_INDEX, i) != null;
    rowIndex = ADD_ROW_INDEX;

    let ReadOnly = colInfo.ReadOnly;
    return (
        <div
            className={"GridItem context-menu-two " + (ReadOnly ? ' readOnlyCell-css' : '') + " tf-title-cell " + (fixed ? "tf-title-cell-sticky" : 'tf-title-cell-absolute')}
            style={{
                ...style,
                left: colInfo.initialLeft,
                top: 0,
                width: tf_component.calcCellWidth(colInfo, curCellEdit),
                height: ROW_HEIGHT,
                border: curCellBorderNeed && !curCellEdit ? ( '2px solid #777777' ) : '1px solid #cccccc',
                borderBottom: curCellBorderNeed && !curCellEdit ? ( '2px solid #777777' ) : ( rowIndex > 0 ? '1px solid #cccccc' : '2px solid #cccccc' ),
                overflow: curCellEdit ? null : 'hidden',
                backgroundColor: curCellBorderNeed ? '#ffffff' : (!isChanged ? (
                    i < fixCol ? (rowIndex % 2 === 0 ?'#ededed':'#dddddd') : (rowIndex % 2 === 0 ?'':'#f5f5f5')
                    ) : '#f8d122'),
                fontWeight: rowIndex > 0 ? '' : 'bold',
                padding: '5px',
                paddingTop: 0 ? curCellEdit : '5px',
                lineHeight: '20px',
                cursor: rowIndex > 0 ? '' : 'pointer',
                position: fixed ? 'sticky' : 'absolute',
                zIndex: fixed || curCellEdit ? 5 : 0
            }}
            unselectable="on"
            data-col-num={i}
            onClick={tf_component.handleClickCell.bind(tf_component, null/*info_columns*/, i, null, ADD_ROW_INDEX)}
            key={"cell-row-edit-" + rowIndex + "-" + i}
        >
            {formatShowCell(tf_component.state.add_row.row[i], colInfo)}
        </div>
    )
}

// Текущая ячейка - с выделением или в режиме редактирования
export const TfCurrentCell = ({rowIndex, i, style, fixCol, fixed, colInfo, initialAddCls=''}) => {

    const { tf_component } = useContext(TfContext);

    let rowIndexCalced = rowIndex

    let on_not_found = [false,];
    //document._DEBUG(5, "TfCurrentRow.TfCurrentCell:", rowIndexCalced, i);
    let cell_data = tf_component.CellData(rowIndexCalced, i, on_not_found);
    //document._DEBUG(5, "TfCurrentRow.TfCurrentCell.cell_data:", cell_data, "on_not_found:", on_not_found[0]);
    if (on_not_found[0])
        return null;

    let cell = convertKompasValueToWeb(cell_data/*.value*/, colInfo.DataType);

    let curCell = tf_component.state.curCell;
    let curCellEdit = curCell.edit;
    let isChanged = tf_component.tfEditRows.findChangedCell(rowIndexCalced, i) != null;

    let add_cls = '';
    let restricts = tf_component.restricts;
    if (restricts[rowIndexCalced] && restricts[rowIndexCalced][i]) {
        let restrict = restricts[rowIndexCalced][i];
        if (restrict === Restrict.rWarning) {
            add_cls = "field-warning "
        } else if(restrict === Restrict.rForbid) {
            add_cls = "field-forbidden "
        }
    }

    if (tf_component.isIncludedInLinesIns(rowIndex))  {
        style.color = "red";
    }

    let ReadOnly = colInfo.ReadOnly;
    let title = FixCellData(cell, rowIndexCalced, i, colInfo, true);
    return (
        <div
            className={add_cls + "GridItem tf-cell context-menu-two " + (ReadOnly ? ' readOnlyCell-css' : '') + ' ' + initialAddCls}
            style={{
                ...style,
                left: colInfo.initialLeft,
                top: 0,
                width: tf_component.calcCellWidth(colInfo, curCellEdit),
                height: style.height - GUTTER_SIZE,
                border: !curCellEdit ? ( '2px solid #777777' ) : null,
                borderBottom: !curCellEdit ? ( '2px solid #777777' ) : ( rowIndex >= 0 ? '1px solid #cccccc' : '2px solid #9ec9f3' ),
                overflow: curCellEdit ? 'inherit' : 'hidden',
                backgroundColor: (!isChanged ? (
                    i < fixCol ? (rowIndex % 2 === 0 ?'#ededed':'#dddddd') : (rowIndex % 2 === 0 ?'':'#f5f5f5')
                    ) : '#f8d122'),
                fontWeight: rowIndex !== TITLE_ROW_INDEX ? '' : 'bold',
                paddingTop: 0 ? curCellEdit : '5px',
                cursor: rowIndex > 0 ? '' : 'pointer',
                position: fixed ? 'sticky' : 'absolute',
                zIndex: fixed || curCellEdit ? 3 : 0,
            }}
            unselectable="on"
            data-col-num={i}
            onClick={tf_component.handleClickCell.bind(tf_component, null/*info_columns*/, i, null, rowIndexCalced)}
            key={"cell-" + rowIndex + "-" + i}
            title={title}
        >
            {title}
        </div>
    )
}

// Исправление содержимого ячейки - в том числе для редактирования
const FixCellData = (cell, rowKeyAbsolute, key, colInfo, editable) => {
    const {tf_component} = useContext(TfContext);

    let curCell = tf_component.state.curCell;
    let curCellRow = null;
    if (tf_component.currentRowRef.current) {
        curCellRow = tf_component.currentRowRef.current.state.curCell;
    }
    let may_fix = true;
    //let colInfo = this.props.colInfo;
    let add_row = tf_component.state.add_row;
    let style = {};
    if (tf_component.isIncludedInLinesIns(rowKeyAbsolute))  {
        style.color = "red";
    }

    if (editable && curCell && curCellRow && curCell.edit &&
        ((rowKeyAbsolute === curCell.row && add_row == null) || (add_row != null && (rowKeyAbsolute === ADD_ROW_INDEX)))
        /*&& colInfo.FieldName === curCell.column.FieldName*/) {
        // FIXME проверка для вычисляемых колонок без имен
        if(curCellRow.columnKey === key){
            // // FIXME в описаниях запрещено редактировать вычисляемые по таблице колонки, поэтому пришлось сделать обход
            // Перенесено на этап получения данных
            // if(columns[key].FieldKind === 2){columns[key].ReadOnly = false;}
            if((!('catalog' in tf_component.props) || !(tf_component.props.catalog)) && !(colInfo.ReadOnly)){
                //style={padding: '0px'};
                cell = <ScreenField tf={true} index={key} value={cell} rowKey={rowKeyAbsolute} column={colInfo}
                    columnKey={key}
                    style = {style}
                    onSaveInTableForm={tf_component.onSaveSField}
                    onCancel={tf_component.onCancelEditField}
                    catalogControl={tf_component.catalogControl}
                    openCatalog={tf_component.openCatalog}
                    resetOpenCatalog = {tf_component.resetOpenCatalog}
                    ref={(el)=>{tf_component.CurFieldRef = el}}
                    onInput={tf_component.tfEditRows.onChangeCellValue}
                    tfEditRows={tf_component.tfEditRows}
                    >
                </ScreenField>
                may_fix = false;
            }
        }
    }

    if (!may_fix)
        return cell;

    return formatShowCell(cell, colInfo);
}