import {isMobile} from 'react-device-detect';
import { LOAD } from "../../common/load";
//import { cloneTFdata } from './tf_funcs.js';
//import { LEFT_SPACE } from './tf_components';
import { knotify } from "../../common/notify";
//import { ROW_HEIGHT } from "./tf_components";
import { SORT_OFF_TIMEOUT, SORT_OFF } from "./tform";

export const TF_WAIT_VARIANT = 5;         // ожидание данных тф (после запроса этих данных)
export const TF_SUCCESS_VARIANT = 6;         // получение тф
export const TF_ERROR_VARIANT = 7;   // получена пустая тф или ошибка при запросе тф(отсутсвие тф, ошибка сервера)


export class TfLoadControl {

    constructor(component) {
        this.component = component;
        component.state.lines_start = 0;
        component.state.lines_count = 100;
        this.loading = [];
        this.loading_timer = null;
    }

    needShowCell = (tf_component, tf, i) =>{
        let flag = true;
        let catalog = (!('catalog' in tf_component.props) || !(tf_component.props.catalog)) ? false : true;
        let canUseAsCatalog = tf.info.CanLookup;
        let isLookInCatalog = tf.info.Columns[i].IsLookupVisible;
        let isStdHide = tf.info.Columns[i].IsStdHidden;
        if (!catalog && isStdHide) flag=false;
        if (catalog && canUseAsCatalog && !isLookInCatalog)  flag=false;
        // if (i==0)
        //     document._DEBUG(5, "!!!!!!!!!!needShowCell >> ", tf.info.TfAlias, i, "catalog:", catalog,
        //         "canUseAsCatalog:", canUseAsCatalog, "isLookInCatalog:", isLookInCatalog, "isStdHide:", isStdHide, "--->", flag, tf.info.Columns[i]);
        return flag;
    }

    filtrColumn = (tf) =>{
        let columns = (tf.info === undefined) ? [] : tf.info.Columns;
        let columns_del = [];
        let new_columns = [];
        for (let i in columns) {
            let need = this.needShowCell(this.component, tf, i);
            if(!need) {
                columns_del.push(Number(i));
            }
        }
        if (columns_del.length >= columns.length) {
            columns_del = [];
        }
        new_columns = columns.filter((element, index) => {
            let pos=columns_del.indexOf(index);
            if (pos===-1) return true;
            return false;
        });
        let data = (tf.data === undefined || tf.data === null) ? [] : tf.data;
        for (let data_row of data) {
            let new_row = data_row.row.filter((element, index) => {
                let pos=columns_del.indexOf(index);
                if (pos===-1) return true;
                return false;
            });
            data_row.row = new_row;
        }
        tf.info.Columns = new_columns;
        tf.data = data;
        return tf;
    }

    load_first = (filtr, tfalias) => {
        this._fix_filter(filtr);
        document._DEBUG(5, "load_first : tf >> ", tfalias, "filter:", filtr);
        let columns = (this.component.state.tf.info === undefined) ? [] : this.component.state.tf.info.Columns;
        let sort = this.component.state.s_sort;
        this.xhr = LOAD.get_tf(tfalias, columns, filtr, sort, this.parseTfResponse.bind(this, filtr),
            () => { this.component.showTfLoadError(0, {message: null}); });
    }

    _fix_filter = (filtr) => {
        if (this.component._from_bp != null && this.component._from_bp.connect_filter != null) {
            filtr.connect_filter = this.component._from_bp.connect_filter;
        }
    }

    parseTfResponse = (filtr) => {
        let xhr = this.xhr;
        document._DEBUG(5, "xhrTF -->", xhr);
        let objTf1 = null;
        try {
            objTf1 = JSON.parse(xhr.response);
        } catch (e) {
            //this.component.showTfLoadError(500, {message: null});
            return;
        }
        document._DEBUG(5, "parseTfResponse tf -->", objTf1, "catalogScreenField:", this.component.props.catalogScreenField);
        let new_state = {};
        if (xhr.status === 200) {
            if (objTf1.istf && (objTf1.info!=null || objTf1.data!=null)) {
                //this.component.startData = cloneTFdata(objTf.data);
                let objTf = this.filtrColumn(objTf1);

                this._hackInfoColumns(objTf);
                this._calcColumns(objTf.info, objTf.total);
                this._numerateData(objTf.data, 0, objTf.info);

                if (objTf.info["#KWEB"] != null) {
                    let kweb = objTf.info["#KWEB"];
                    // FIXME почему каждый ключ kweb записывается в _from_bp, там же не только бизнес-процедуры?
                    for (const key in kweb) {
                        this.component._from_bp[key] = kweb[key];
                    }
                    this.component.updateFromBp(kweb);
                }

                new_state = {
                    variant: TF_SUCCESS_VARIANT,
                    tf: objTf,
                    s_filtr: filtr,
                    lines_start: 0,
                };
                if (this.component.selectedRowsToNull)  {
                    new_state.countSelectRows = 0;
                    this.component.selectedRowsToNull = false;
                }
                if (!('title_tf' in this.component.props) || this.component.props.title_tf === '') {
                    new_state.title_tf = objTf.info.Title;
                }
                if (this.component.sortMode === SORT_OFF_TIMEOUT)  {
                    this.component.sortMode = SORT_OFF;
                    new_state.countSelectRows = 0;
                }

                this.component.setState(new_state);

                if (this.component.props.catalogScreenField && this.component.props.catalogScreenField.props.column) {
                    let col = this.component.props.catalogScreenField.props.column;
                    let val = this.component.props.catalogScreenField.props.value;
                    document._DEBUG(5, "catalogScreenField COLUMN:", col.LookupFieldName, "value:", val);
                    let i = -1;
                    let found_name = -1;
                    let found_row = -1;
                    for (let coli of objTf.info.Columns) {
                        i++;
                        if (coli.FieldName === col.LookupFieldName) {
                            found_name = i;
                            break;
                        }
                    }
                    document._DEBUG(5, "catalogScreenField ->", found_name);
                    if (found_name >= 0) {
                        i = -1;
                        for (let row of objTf.data) {
                            i++;
                            if (row.row[found_name] === val) {
                                found_row = i;
                                break;
                            }
                        }
                        document._DEBUG(5, "catalogScreenField ROW ->", found_row);
                    }
                    if (found_row > 0) {
                        let state = this.component.makeEnableCellEditOrNot(found_row, null, found_name, false);
                        this.component.setState(state, ()=>{
                            this.component.GridRef.current.scrollToItem({
                                columnIndex: 0,
                                rowIndex: found_row,
                                align: 'start'
                            });
                        });
                    }
                }

                this.component.toolbarRef.current.setState({
                    rerender: -this.component.toolbarRef.current.state.rerender
                });
            }
            else
                this.component.showTfLoadError(xhr.status, objTf1);
        } else
            this.component.showTfLoadError(xhr.status, objTf1);
    }

    load = (start, filtr) => {
        let want_end = start + 100;
        let lines_end = this.component.state.lines_start + this.component.state.lines_count;

        if (this.loading.indexOf(start) >= 0)
            return;
        console.log("..want_load: [", start, ":", want_end, "] was: [", this.component.state.lines_start, ":", lines_end, "]");
        this.loading.push(start);

        if (this.loading_timer) {
            clearTimeout(this.loading_timer);
        }

        this.loading_timer = setTimeout(() => {
            let _start = start;
            let limit = 100;
            let loading = this.loading.slice(-2);
            loading.sort(function(a, b) {
                return a - b;
            });

            if (loading.length === 2 && loading[1] === loading[0] + 100) {
                _start = loading[0];
                limit = 200;
            }
            document._DEBUG(5, '[ LOAD ]', _start, "(", start, ")", this.loading, "=>", loading);
            let sort = this.component.state.s_sort;
            this._fix_filter(filtr);

            this.xhr = LOAD.get_tf_page(this.component.props.tfalias, this.component.state.tf.info.Columns, filtr, sort, _start+1, limit,
                this.parseTfPageResponse.bind(this, _start), () => {
                    knotify.cwarn("notify", null, "Не удается получить страницу данных");
                }
            );
        }, 300);
    }

    parseTfPageResponse = (start) => {
        document._DEBUG(5, "!!! [parseTfPageResponse]", this.component.state.lines_start, "->", start);
        this.loading.length = 0;

        let xhr = this.xhr;
        if (xhr.status === 200) {
            var objTf1 = JSON.parse(xhr.response);
            let objTf = this.filtrColumn(objTf1);
            let new_rows = objTf.data;
            if (new_rows == null)
                new_rows = [];
            let end = start + new_rows.length;

            let component = this.component;
            let tf = component.state.tf;

            let last_rows = tf.data;
            let data = new_rows;
            let new_start = start;
            let last_start = component.state.lines_start;
            let last_count = component.state.lines_count;
            let component_state_lines_end = last_start + last_count;

            if (start < component.state.lines_start) {
                document._DEBUG(5, "  -> 1", "start:", start, "end:", end, "component.state.lines_start:", last_start, "component_state_lines_end:", component_state_lines_end);
                if (end === last_start) { //component_state_lines_end) {
                    document._DEBUG(5, "  -> 2");
                    Array.prototype.push.apply(new_rows, last_rows);
                    data = new_rows;
                }

            } else if (start === component_state_lines_end && end > component_state_lines_end) {
                document._DEBUG(5, "  -> 3");
                Array.prototype.push.apply(last_rows, new_rows);
                data = last_rows;
                let ln = data.length;
                new_start = last_start; //end - ln;
                while (ln > 200) {
                    data = data.slice(100, data.length);
                    ln = data.length;
                    new_start += 100;
                    // data = data.slice(data.length-200, data.length);
                    // ln = data.length;
                }

            } else if (start > component_state_lines_end) {
                document._DEBUG(5, "  -> 4 ln:", data.length);
            } else {
                document._DEBUG(5, "  -> 5", "start:", start, "end:", end, "component_state_lines_end:", component_state_lines_end);
                data = last_rows;
                new_start = last_start;
            }

            if (data.length > 200) {
                data = data.slice(0, 200);
            }

            tf.data = data;
            this._numerateData(data, new_start, tf.info);

            document._DEBUG(5, "..[page] loaded.", last_start, "->", new_start, "ln:", data.length);

            component.setState({
                tf: tf,
                variant: TF_SUCCESS_VARIANT,
                lines_start: new_start,
                lines_count: data.length
            });
        }
    }

    _calcColumns = (info, countAllRows) => {
        let info_columns = info.Columns;
        let widthCalced = this.component.calcLeftSpaceWidth(countAllRows);
        let fixCol = info.nFixedCols;
        if (fixCol > 1 && isMobile)
            fixCol = info.nFixedCols = 1;
        let titleWords = 1;
        let lst;
        let fixedWidth = 0;
        for (var i=0; i<info_columns.length; i++) {
            let colInfo = info_columns[i];
            colInfo.initialLeft = widthCalced;
            const colTitle = colInfo.ColTitle;
            lst = colTitle.split(" ");
            if (lst.length > titleWords)
                titleWords = lst.length;
            let colWidthCalced = this.component.calcCellWidth(colInfo);
            colInfo.widthCalced = colWidthCalced;
            widthCalced += colWidthCalced;
            if (i < fixCol) {
                fixedWidth += colWidthCalced;
            }
        }
        info_columns.widthCalced = widthCalced;
        info_columns.titleWords = titleWords;
        info_columns.fixedWidth = fixedWidth;
    }

    _numerateData = (data, lines_start, info) => {
        //document._DEBUG(5, "DATA:", data);
        lines_start = lines_start - 0
        let need_fix_type = info.Columns.map((val, index)=>{
            if (val.SubType === 136)
                return (vl) => {return fixUUID(vl);}
            else
                return null;
        });
        for (let j in data) {
            let data_row = data[j];
            data_row.index = j - 0 + lines_start;
            //data_row.key = "row-" + data_row.index;
            //let row = data_row.row;
            // for (let i in row) {
            //     let cell = row[i];
            //     if (typeof cell !== _Value) {
            //         row[i] = cell = new _Value(row[i]);
            //     }
            //     cell.index = i;
            // }
            // if (j == 0) {
            //     document._DEBUG(5, "^^^^", data_row.row[data_row.row.length-1], typeof data_row.row[data_row.row.length-1]);
            //     let row = data_row.row;
            //     for (let i in row) {
            //         //     let cell = row[i];
            //         let ff = need_fix_type[i];
            //         if (ff != null)
            //             row[i] = ff(row[i]);
            //     }
            //     document._DEBUG(5, "   :", data_row.row[data_row.row.length-1], typeof data_row.row[data_row.row.length-1]);
            // }
            let row = data_row.row;
            for (let i in row) {
                let ff = need_fix_type[i];
                if (ff != null)
                    row[i] = ff(row[i]);
            }
        }
    }

    _hackInfoColumns = (tf) => {
        let columns = tf.info.Columns;
        //let nick = "/" + tf.info.TfAlias;
        // let json = localStorage.getItem(nick);
        // let o = null;
        // if (json)
        //     o = JSON.parse(json);
        for (let i=0; i<columns.length; i++) {
            // FIXME в описаниях запрещено редактировать вычисляемые по таблице колонки, поэтому пришлось сделать обход
            if (columns[i].FieldKind === 2) {
                columns[i].ReadOnly = false;
            }
        }
    }

}

function fixUUID(val) {
    let lst = val.split("-");
    lst = lst.map((v, i) => {
        if (i > 2)
            return v;
        let arr = [];
        for (let j=v.length-2; j>=0; j-=2) {
            arr.push(v[j]);
            arr.push(v[j+1]);
        }
        return arr.join('');
    })
    return lst.join('-').toUpperCase();
}
// function fixUUID(val) {
//     return val;
// }

// function readInt(array, size) {
// 	let value = 0;
// 	let first = true;
//     let pos = 0;
// 	while (size--) {
//         let byte = array[pos++]
//         document._DEBUG(5, "  *****", byte);
//         byte = byte.charCodeAt(0);
//         document._DEBUG(5, "     :::", byte);
// 		if (first) {
// 			value += byte & 0x7f;
// 			if (byte & 0x80) {
// 				value -= 0x80;
// 				// Treat most-significant bit as -2^i instead of 2^i
// 			}
// 			first = false;
// 		}
// 		else {
// 			value *= 256;
// 			value += byte;
// 		}
// 	}
// 	return value;
// }