import React from "react";
import {isMobile} from 'react-device-detect';
import $ from 'jquery';
import {} from "jquery-contextmenu";
import "jquery-contextmenu/dist/jquery.contextMenu.css";
//import { BpLang } from "./../bp_lang/bp_lang";
import { processKeyEvent } from "../common/helpers";
import { LOAD } from "../common/load";

export class ToolBar extends React.Component {
    constructor(props) {
        super(props);
        this.tform = this.props.tform;
        this.app = this.props.app;
        this.state = {
            rerender: 1, // hack специальное состояние для ручного ререндера
            send_file: false,
        }
    }

    onAppKeydown = (e) => {
        let objBack;
        objBack = processKeyEvent(e, this.tform.tb_controls.keys);
        let processed = objBack.flag;
        if (processed) {
            (this.tform.tb_controls.getProcessEventOnKey(objBack.ckey))();
        }
        return processed;
    }

    generateUniqueBtnList(toolbar) {  // создает список кнопок, индивидульных для тф (третий блок тулбара)
        if (!toolbar) {
            return [];
        }
        let listItems = [];
        let addButtons = [];
        let index = 0;
        for (let el of toolbar) {
            if (el.Disabled) {
                continue
            }
            index += 1;
            let key = "3:"+index;
            if (index === 10) {
                addButtons.push(el);
                let button_menu = this.tform.tb_controls.getButtonsGroup("buttons_fourth");
                let item = (
                    <button type="button" className="btn btn-outline-warning btn-sm context-menu-add"  key={key}>
                        <div className="fullsize" style={this.tform.tb_controls.buttonStyle(button_menu[0])} title={"..."}></div>
                    </button>
                )
                listItems.push(item);
                continue
            } else if (index > 10) {
                addButtons.push(el);
                continue
            }
            let src = el.Path;
            let title = (el.Hint!==undefined) ? el.Hint : "";
            let item = this.renderButton([src, title, key, el], el.BPName, key);
            listItems.push(item);
        }
        if (addButtons.length) {
            let items = {}
            let app = this.props.app;
            addButtons.map((b, index) => {
                items[index] = {
                    name: (b.Hint!=='') ? b.Hint : "Без названия",
                    disabled: (b.BPName !== '') ? false : true,
                    callback: (b.BPName === '') ? null : function(key, opt){
                        app.bpCall.call_bp(b.BPName);
                        //(new BpLang({component: this.tform, app: this.app})).run_bp(b.BPName);
                    }
                }
                return null
            })
            $.contextMenu( 'destroy', '.context-menu-add' );
            $.contextMenu({
                selector: '.context-menu-add',
                trigger: 'left',
                items: items
            });
        }
        return listItems;
    }

    renderButton = (item, BPName, key, need_simplify) => {
        let style = this.tform.tb_controls.buttonStyle(item);
        let hide_object = "";
        let handleClick = null;
        if (BPName == null && item[2] === "4:1") {
            BPName = "OTLADKA.BP_EXAMPLE";
        }
        if (BPName == null || BPName === '' ) {
            if (this.tform.tb_controls.isEnabledButton(item[2])) {
                handleClick = () => {
                    (this.tform.tb_controls.getProcessEventFromId(item[2]))();
                };
                hide_object = null;
            } else if (item[3] && item[3].ItemID === "VIEW_TEXTDOC") {
                handleClick = () => {
                    document._DEBUG(5, ">>> VIEW_TEXTDOC");
                    this.tform.fileAddControl.prepareFileAdd({
                        tfalias: null,
                        fieldName: null,
                        onChoose: null
                    });

                    this.tform.fileAddControl.showFileAdd({
                        title: null,
                        show: true,
                    });
                }
            } else if (item[3] && item[3].ItemID === "EDIT_TEXTDOC") {
                handleClick = () => {
                    document._DEBUG(5, ">>> EDIT_TEXTDOC");

                    let tf_info = this.tform.state.tf.info;
                    document._DEBUG(5, "tf_info:", tf_info, "tf:", this.tform.state.tf);

                    let rowIndex = this.tform.state.curCell.row;
                    document._DEBUG(5, 'rowIndex-->', rowIndex);

                    let row = this.tform.getRow(rowIndex);
                    document._DEBUG(5, 'row-->', row);

                    let tfAlias = tf_info.TfAlias;
                    let recordKey = row.key;
                    let xhr = LOAD._POST("/api/row/"+tfAlias+"/info", {
                        "FilesCount": true,
                        "FilesList": true,
                        "data":{"key": recordKey, "row": []}
                    }, ()=>{
                        if (xhr.status === 200) {
                            document._DEBUG(5, "xhrTF -->", xhr);
                            let objTf = JSON.parse(xhr.response);
                            document._DEBUG(5, "objTf -->", objTf);
                            if (objTf.FilesList) {
                                this.tform.filesListControl.prepareFilesList({
                                    tfalias: null,
                                    fieldName: null,
                                    onChoose: null,
                                    files: objTf.FilesList,
                                });

                                this.tform.filesListControl.showFilesList({
                                    title: null,
                                    show: true,
                                });
                            }
                        }
                    });
                }
            } else if (item[3] && item[3].ItemID === "LIST_TEXTDOC" && false) {
                handleClick = () => {
                    document._DEBUG(5, ">>> LIST_TEXTDOC");
                    let xhr = LOAD._POST("/api/row/ORG_TASKS/info", {
                        "FilesCount": true,
                        "FilesList": true,
                        "data":{"key": {"ID": 7773}, "row": []}
                    }, ()=>{
                        if (xhr.status === 200) {
                            document._DEBUG(5, "xhrTF -->", xhr);
                            let objTf = JSON.parse(xhr.response);
                            document._DEBUG(5, "objTf -->", objTf);
                        }
                    })
                }
            } else  {
                hide_object = <div className="fullsize hide-all" title={item[1]}></div>;
                handleClick = ()=> {return false;};
            }
        } else {
            handleClick = () => {
                this.props.app.bpCall.call_bp(BPName);
                //(new BpLang({component: this.props.tform, app: this.props.app})).run_bp(BPName);
            }
        }
        let btnStyle = {}
        if (need_simplify) {
            if ((key==="2:1"||key==="2:7"||key==="2:8"||key==="2:9"||key==="2:10"))
                btnStyle["display"] = "none";
        }
        return (
            <button type="button" className="btn btn-outline-warning btn-sm" onClick={handleClick.bind(this)} key={key}
                style={btnStyle}
            >
                <div className="fullsize" style={style} title={item[1]}></div>
                {hide_object}
            </button>
        )
    }

    render() {
        let unique_btn_list = this.generateUniqueBtnList(this.props.toolbar);
        const styleBoxTb = {flex: '2', paddingBottom: "2px", paddingTop: "2px", display: "flex",  flexWrap: "wrap",
            maxWidth: "50vw"
        };
        const buttons_first = this.tform.tb_controls.getButtonsGroup("buttons_first");
        const buttons_second = this.tform.tb_controls.getButtonsGroup("buttons_second");
        let send_file = null;
        if (this.state.send_file) {
            send_file = (
                <div>
                    <input id="file" type="file" name="file" />
                    <button onClick={()=>{
                        let file = document.getElementById("file").files[0];  // file from input
                        let req = new XMLHttpRequest();
                        let formData = new FormData();
                        formData.append("file", file);
                        req.onload = () => {
                            document._DEBUG(5, "response:", req.responseText);
                            let lst = req.responseText.split("\nuid:");
                            if (lst.length > 1) {
                                let uid = lst[1].split('\n')[0].trim()
                                document._DEBUG(5, "UID:", uid);
                            }
                        };
                        req.onerror = () => {
                            document._DEBUG(5, "error:", req.response);
                        };
                        req.open("POST", 'http://localhost:8000/file/post/');
                        req.send(formData);
                    }} >Send</button>
                </div>
            )
        }
        let is_mobile = isMobile;
        // FIXME:
        if (this.props.addedTfOptions != null && this.props.addedTfOptions._from_bp != null) {
            if (this.props.addedTfOptions._from_bp.minimize_buttons_count)
                is_mobile = true;
        }
        return (
            <div style={styleBoxTb}>
                <div className="btn-toolbar toolbar" role="toolbar" aria-label="Toolbar with button groups">
                    <div className="btn-group mr-2" role="group" aria-label="First group">
                        {buttons_first.map((item, index) => this.renderButton(item, null, "1:"+(index+1)))}
                    </div>
                    <div className="btn-group mr-2" role="group" aria-label="Second group">
                        {buttons_second.map((item, index) => this.renderButton(item, null, "2:"+(index+1), is_mobile))}
                    </div>
                    <div className="btn-group mr-2" role="group" aria-label="Third group">
                        {unique_btn_list}
                    </div>
                    {send_file}
                </div>
            </div>

        );
    }
}
