import React from "react";
import $ from 'jquery';


export class MenuBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            wsize: $( window ).width()
        }
    }

    handleResize = () =>{
        this.setState({wsize: $( window ).width()})
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    generateBtnItems(btns) {
        const btnStyle = {width: "36px",  height: "34px"}

        let listItems = [];
        let i = 0;
        for (let el of btns) {
            let handleClick = null;
            let key = String(i);
            if (el.tfalias!=="" && el.script==="") {
                handleClick = () => {
                    this.props.app.handleClickMenuItem([el.tfalias, el.tftitle]);
                };
            }

            let elStyle = {
                ...btnStyle,
                position: "relative", top: -5, left: -9,
                backgroundImage: 'url(/icons_menu/'+el.ItemCode+'.png)',
                backgroundRepeat:"no-repeat",
                backgroundPosition: "center center",
            };

            let elem = (
                <button type="button" className="btn btn-outline-secondary btn-sm mr-1 my-1" style = {btnStyle} onClick={handleClick} key={key}>
                    <div style={elStyle} title={el.tftitle}></div>
                </button>
            );
            listItems.push(elem);
            i++;
        }

        return listItems;
    }

    render() {
        const downMenu_div = (this.state.wsize < 992) ? {height: '57px'} : {height: '49px'};
        const items = this.generateBtnItems(this.props.btns);
        const stylePanel = {background: "#E0E1E1"};

        return (
            <div >
                <div className="downMenu" style = {downMenu_div}> </div>
                <div className="btn-toolbar container-fluid" style = {stylePanel}>
                    {items}
                </div>
            </div>
        );
    }
}

