import { useState } from "react";

export const TfError = ({error, detail}) => {

    const [errDetailShow, setErrDetailShow] = useState(false);
    
    let detailDiv = (
        ''
    );

    if (detail) {
        let display = 'none';
        if (errDetailShow) {
            display = 'flex';
        }
        detailDiv = (
            <div>
                <br></br>
                <div className='detail-btn'
                    onClick={(e) => {
                        e.stopPropagation();
                        setErrDetailShow(!errDetailShow);
                    }}
                >детали</div>
                <div style={{display: display}}>{detail}</div>
            </div>
        );
    }

    return (
        <TfMessage>
            <div className="alert alert-danger" role="alert">
                {error}
                {detailDiv}
            </div>
        </TfMessage>
    )
}

export const TfLoading = () => {
    return (
        <TfMessage>
            <div className="lds-dual-ring"></div>
            <p> Загрузка... </p>
        </TfMessage>
    )
}

const TfMessage = ({children}) => {
    const size = {
        width: '100%',
        height: '100%', //'calc(100vh - 50px)',
    }
    const messageBox = {
        ...size,
        overflow: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
    return (
        <div style={size}>
            <main role="main" className="container-fluid" style={messageBox}>
                {children}
            </main>
        </div>
    )
}