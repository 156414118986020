import React from "react";

export function StatusBar({strings, total, selectRows}) {
    let msg = "[ " + strings + " ] / " + total;
    const selectRowsStatus = (selectRows>0) ?
        ( 
        <>
            <div className="input-group-append h-100">
                <span className="input-group-text" style = {{fontWeight: 'bold'}}>Выделено строк</span>
            </div>              
            <div className="input-group-append h-100">
                <span className="input-group-text">{selectRows}</span>
            </div>
        </>
        ) : null;

    return (
        <div className="input-group input-group-sm my-1 w-auto" style={{backgroundColor: "#eee"}}>
            <div className="input-group-prepend h-100">
                <span className="input-group-text" style = {{fontWeight: 'bold'}}>Показаны строки</span>
            </div>
              
            <div className="input-group-append h-100">
                <span className="input-group-text">{msg}</span>
            </div>
            {selectRowsStatus}
            
        </div>
    )


}