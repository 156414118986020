import React from 'react';
import { KANBAN_DRAG_TYPE } from './kanban';
import { Sticker } from './kanban_sticker';

export class KanbanColumn extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dragOverActive: false
        }
    }

    handleDragEnter = (e)=>{ 
        e.stopPropagation(); 
        let kanban = this.props.kanban;         

        document._DEBUG(4, "handleDragEnter Column ==> e ", e);   
        kanban.dragLeaveCommon(KANBAN_DRAG_TYPE.column, this, e);
    }

    getStickers = (_id, column) =>{    
        let kanban = this.props.kanban;    
        let objTf = kanban.state.loaded.data;      
        let sum = 0;
        let new_place = <div key="drop" className='sticker-new-place' 
            onDragEnter={(e)=>{e.stopPropagation();}} 
        ></div>;        
        let dragOverActive = column.state.dragOverActive;
        let stickers = [];
        for(let i in objTf.data){
            let val = objTf.data[i];
            let id_state = kanban.findData("ID_STATE", val.row);
            let id = kanban.findData("ID", val.row);
            let name = kanban.findData("NAME", val.row);
            let date = kanban.findData("DATE", val.row);
            if(_id === id_state){
                sum += 1; 
                if(dragOverActive && dragOverActive === sum){                                
                    sum += 1; 
                }
                stickers.push(
                    <Sticker key={i} index={i} row={val} id_state={id_state} id={id} name={name} date={date} priority={sum} column={column} kanban={kanban}></Sticker>
                )  
            }
        }
        document._DEBUG(5, "getStickers ==> dragOverActive: ", dragOverActive);
        if(dragOverActive && dragOverActive !== 'column'){
            stickers.splice(dragOverActive-1, 0, new_place);
            sum -= 1;
        }
        return [stickers, sum];      
    }

    getHeaderCss =()=>{
        let css = 'kanban-column-header';
        if(this.state.dragOverActive === 'column'){
            css+= ' kanban-shadow';
        }      
        return css;
    }

    render () {        
        let header = this.props.header;
        let stickersInfoList = this.getStickers(this.props.id, this)
        let stickkers = stickersInfoList[0];
        let sum = stickersInfoList[1];
        let stickerSumText = "Всего стикеров: ";  
        
         
        document._DEBUG(4, "KanbanColumn render ==> dragOverActive: ", this.state.dragOverActive)
        return (
            <div className={'kanban-column'} onDragEnter={this.handleDragEnter}>
                <div className={this.getHeaderCss()}>
                    <div title={header} className='name-header'>{header}</div> 
                    <div className='sticker-sum'>{stickerSumText+sum}</div>                        
                    <div className='kanban-line'></div>                                       
                </div>                
                <div className='stickers-block' onDragEnter={(e)=>{e.stopPropagation()}}>
                    {stickkers}                                   
                </div>                                             
            </div>            
        )
    }
}