import { useEffect, useState } from "react";
import { Dialog } from "../../components/dialogs/dialog";
import { InputRadio } from "./allInput";

export function RadioGroup(props) {
    const [value, setValue] = useState(props.value); // value формат: value
    const dictList = props.dictList // dictList формат: [{value: label}]

    function onClick(e){        
        let val = e.target.value;
        setValue(val);       
    }

    useEffect(()=>{
        if(props.giveValueOut){
            props.giveValueOut(value)
        }
    })

    return(
        <div>
            {dictList.map((dict, index) => (
                <InputRadio 
                    key={index}
                    isCheck ={dict.value === value}
                    value={dict.value}
                    label = {dict.label}
                    onClick={onClick}
                ></InputRadio>           
            ))} 
        </div>        
    )
}

export function ChoiceFromRadioGroup (props) {
    const [show, setShow] = useState(true); // указывает отображать ли форму
    let value = props.value;
    function handleCancel() {
        setShow(false);
    }

    function handleOk() {
       alert('Стандартной реакции нет! Внесите функцию реакции!')
    }
    
    if(!show){
        return ''
    }else{
        return(
            <Dialog
                children={<RadioGroup dictList={props.dictList} value={value} giveValueOut={props.giveValueOut ? props.giveValueOut: null}></RadioGroup>}
                handleOk={props.handleOk ? props.handleOk: handleOk}
                handleCancel={props.handleCancel ? props.handleCancel: handleCancel}
                title={props.title}
                styleBox={{}}
                modalContent={props.cssModalContent}
            ></Dialog>          
        )
    }
}
