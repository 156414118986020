import React from 'react';

export class ChoiceFiles extends React.Component  {
    constructor(props) {
        super(props);
        this.component = this.props.component;
        this.nosave = true;
        this.inputs = [];
    }

    handleFiles = (e) =>{
        this.nosave = true;
        let nn = this.component.state.choiceFiles.counterCall;
        this.component.state.choiceFiles.promise.ctrl.resolve(e.target.getAttribute('id') + "/" + e.target.files[0].name);
        this.component.setState({choiceFiles: {input: [], counterCall: nn, status: 2}});
    }

    resetNoSave = () =>{
        this.nosave = false;
    }

    cancel = () =>{
        if (!this.nosave) {
            let nn = this.component.state.choiceFiles.counterCall;
            this.component.state.choiceFiles.promise.ctrl.resolve('');
            this.component.setState({choiceFiles: {input: [], counterCall: nn, status: 2}});
        }
    }

    checkCancel =() =>{
        let nn = this.component.state.choiceFiles.counterCall;
        let input = document.querySelector("#openFileInput-" + nn);
        if (!this.nosave && input.files.length === 0) {
            setTimeout(this.cancel, 100);
        }
    }

    componentDidUpdate() {
        if (this.component.state.choiceFiles === undefined) {
            return;
        }
        if (this.component.state.choiceFiles !== undefined && this.component.state.choiceFiles.status===0) {
            this.inputs = [];
            return;
        }
        if (this.component.state.choiceFiles !== undefined && this.component.state.choiceFiles.status===2) {
            return;
        }
        let nn = this.component.state.choiceFiles.counterCall;
        if(document.querySelector("#openFileInput-" + nn)!=null)  {
            let input = document.querySelector("#openFileInput-" + nn);
            this.nosave = true;
            input.click();
            setTimeout(this.resetNoSave, 500);
        }
    }

    renderInput = () => {
        let inputs = this.inputs;
        let nn = this.component.state.choiceFiles.counterCall;
        let new_input = <input key={String(nn)} type="file" id={"openFileInput-" + nn} accept={this.component.state.choiceFiles.input[4]} style={{display: "none"}} onChange={this.handleFiles} />;
        if (this.component.state.choiceFiles.status ===1)
                inputs.push(new_input);
        this.inputs = inputs;
    }

    render() {
        if (this.component.state.choiceFiles !== undefined && this.component.state.choiceFiles.status>0) {
            this.renderInput();
            let button = (this.component.state.choiceFiles.status===1) ?
                <button className="btn btn-outline-secondary btn-sm" autoFocus type="button" style={{position: "absolute", top: "-1px", height: "0px", width: "0px", padding: "0px", margin: "0px"}} onFocus={this.checkCancel}></button>
                : null;
            let block = (
                <>
                    <div>
                        {this.inputs}
                    </div>
                    {button}
                </>
            );
            return block;
        } else {
            return null;
        }
    }

}
