import React from "react";
import { CommonTemplate } from "./common_template";
import { LOAD } from '../common/load';
import { rubles } from 'rubles';
import { convertKompasDateToTemplate } from "../common/helpers.js";

export class Bill extends React.Component {
    constructor(props) {
        super(props);   
        this.state = {
            show: 0,
        }          
    }

    noBill = () =>{
        return (
            <div>Такого счета не существует</div>
        )
    }

    getBill = () =>{      
            function getValue(name, _index){
                let index = 0;
                if(_index){
                    index = _index;
                }
                let value = values[index][columns.indexOf(name)];             
                if(typeof value == "string"){
                    value = value.trim();
                }                                
                if(!value || value == null || value === ""){
                    value = "-";
                }
                return value;
            }
            function getMovies (){
                let movies = values.map((val, index)=>{
                    let name = getValue('D_NAME', index);
                    let price = getValue('CENA_O_TOT', index);
                    let quantity = getValue('KOL', index);;
                    let sum = getValue('SUM_O_TOT', index);;
                    return (
                        <tr key={'movie_'+index}>
                            <td>{name}</td>
                            <td>{price}</td>
                            <td>{quantity}</td>
                            <td>{sum}</td>
                        </tr>                        
                    )
                })
                return movies;
            }

            let response = JSON.parse(this.xhr.response);
            let columns = response.columns;
            let values = response.values;
        
            let name = getValue('PYR3_PL');
            let inn = getValue('INNP');
            let kpp = getValue('KPP');;
            let data = getValue('DATA_D');
            data = convertKompasDateToTemplate(data);
            let sum = getValue('SUM_DN').toString();
            let sum_text = "";
            if(sum !== "-"){
                if(typeof sum == "string"){
                    if(sum.indexOf('.') === -1){
                        sum = sum+'.00';                    
                    }
                    sum_text = rubles(sum)
                    document._DEBUG(1, "sum_text= ", sum_text, sum);
                    sum_text = sum_text.charAt(0).toUpperCase() + sum_text.substring(1);   
                }                
            }
            
            let nds = "Без НДС";
            let addres = '-';
            addres = getValue('ADR_PL');
            document._DEBUG(1, 'response: ',response);
            document._DEBUG(1, 'address: ',addres);            
            let tel = getValue('TELEF');
            let account = getValue('RS1_PL');
            let corAccount = getValue('RS2_PL');
            let bank = getValue('BANK_PL');
            let bik = getValue('COD2_PL');

            let billNumber = values[0][columns.indexOf('N_DOK')];
                 
            
            return (
                <div className="bill-main">
                    <div className="template-header">{name}</div> 
                    <div className="flex left-align topBlock">                     
                        <div className="leftBlock">
                            {/* <div className="template-header">Плательщик</div>      */}
                            <div className="template-h3">ИНН/КПП</div> 
                            <div className="template-h3">Адрес</div>   
                            <div className="template-h3">Телефон</div>   
                            <div className="template-h3">Расч. счет</div>      
                            <div className="template-h3">Кор. счет</div> 
                            <div className="template-h3">Банк</div>          
                            <div className="template-h3">БИК</div> 
                        </div>
                        <div className="leftBlock">    
                            <div className="template-h3">{inn+"/"+kpp}</div>                        
                            <div className="template-h3">{addres}</div>   
                            <div className="template-h3">{tel}</div>  
                            <div className="template-h3">{account}</div> 
                            <div className="template-h3">{corAccount}</div>     
                            <div className="template-h3">{bank}</div> 
                            <div className="template-h3">{bik}</div>             
                        </div>
                    </div>
                    <div className="line"></div>
                    <div className="centerBlock">
                        <div className="template-header">{"Счет №   "+billNumber+"  от  "+data}</div>
                    </div>  
                    <div className="line"></div> 
                    <table className="table movies-table">
                        <tbody>
                            <tr className="movies-header">
                                <th>Наименование</th>
                                <th>Цена</th>
                                <th>Кол-во</th>
                                <th>Сумма</th>
                            </tr>
                            {getMovies()}
                        </tbody>                        
                    </table>
                    <div className="flex right-align bottomBlock">
                        <div className="rightBlock">
                            <div className="template-h3">Итого без скидки:</div>
                            <div className="template-h3">В том числе НДС:</div>
                            <div className="template-header">Итого к оплате:</div>
                        </div>
                        <div className="rightBlock">
                            <div className="template-h3">{sum+" ₽"}</div>
                            <div className="template-h3">{nds}</div>
                            <div className="template-header">{sum+" ₽"}</div>
                        </div>
                    </div>
                    <div className="left-align lastBlock">
                        <div>{"Всего к оплате: "+sum_text}</div>
                        <div>{nds}</div>
                        <div>Основание: НДС не облагается в соответствии с п.2 ст. 346.11 гл. 26.2 НК РФ.</div>
                    </div>
                </div>  
            )   
    }

    onGetBillFromServer = () =>{
        if (this.xhr.status === 200){
            document._DEBUG(1, 'onLoad', this.xhr);
            this.response = JSON.parse(this.xhr.response);
            if(this.response.values){
                this.setState({show: 1});
            }else{
                this.setState({show: 2});
            }   
        }
    }

    onError = () =>{
        document._DEBUG(1, 'onError');
    }

    componentDidMount() {
        document._DEBUG(1, 'componentDidMount');
        this.xhr = LOAD.run_sql_public("_CHET___PublicSql", 0, [this.props.number-0], this.onGetBillFromServer, this.onError);
        
    }

    componentDidUpdate() {
        document._DEBUG(1, 'componentDidUpdate');
        // this.xhr = LOAD.run_sql_public("_CHET___PublicSql", 0, [this.props.number-0], this.onGetBillFromServer, this.onError);        
    }

    render() { 
        switch(this.state.show) {
            case 0:
                return (
                    <CommonTemplate>                
                    </CommonTemplate>
                );
            case 1:
                return (                                       
                    <CommonTemplate body={this.getBill()}>                
                    </CommonTemplate>
                );
            case 2:
                return (
                    <CommonTemplate body={this.noBill()}>                
                    </CommonTemplate>
                );
            default:
                return
        }             
    }
}