import React from 'react';
import nextId from "react-id-generator";
import { Dialog } from "./dialog.js";
import { LOAD } from '../../common/load.js';


export class ExportDocControl {
    constructor(tform) {
        this.tform = tform;
    }

    sendExport = (prms, fname, i, j) =>{
        this.xhr = LOAD.export_doc(prms.tfalias, fname, prms.typefile, prms.columns, 
            prms.filtr, prms.sort, i, j, this.parseResponseExportDoc.bind(this, fname, prms.web_tfile, prms, i, j),
            () => { console.log("!!!!!!!Ошибка при экспорте табличной формы"); });
        this.xhr.responseType = 'blob';      
    } 

    handleExportDoc = (prms) =>{
        let i=1;
        if (!prms.split) {
            this.xhr = LOAD.export_doc(prms.tfalias, prms.fname, prms.typefile, prms.columns, 
                prms.filtr, prms.sort, 1, prms.limit, this.parseResponseExportDoc.bind(this, prms.fname, prms.web_tfile, prms, 1, prms.limit),
                () => { console.log("!!!!!!!Ошибка при экспорте табличной формы"); });
            this.xhr.responseType = 'blob';      
        } else {
            
            //let j=100;
            //for (i; i<=prms.limit; i+=100) {
                //let fname = prms.fname.replace(".",i + "."); 
                setTimeout(this.sendExport, 200, prms, prms.fname, i, 100);                
            //}
        }        
    }

    downloadFiles = (data, file_name, file_type) =>{
        var file = new Blob([data], {type: file_type});
        if (window.navigator.msSaveOrOpenBlob) 
            window.navigator.msSaveOrOpenBlob(file, file_name);
        else { 
            var a = document.createElement("a"),
                    url = URL.createObjectURL(file);
            a.href = url;
            a.download = file_name;
            document.body.appendChild(a);
            a.click();
            setTimeout(function() {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);  
            }, 0); 
        }
    }

    parseResponseExportDoc = (fname, typefile, prms, i, j) => {
        let xhr = this.xhr;                        
        if (xhr.status === 200) {
            console.log("!!!server_export_answer: ", xhr.response);

            this.downloadFiles(xhr.response, fname, typefile);
            if (i+j<prms.limit)  {
                let fname = prms.fname.replace(".", (i+100) + ".");
                alert(fname); 
                setTimeout(this.sendExport, 200, prms, fname, i+100, 100);  
            } else {
                let obj = {status: null};
                this.tform.setExportDoc(obj);   
            }         
        } else {
            console.log("!!!server_export_answer: invalid");     
        }
    }

}


class ExportDoc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            check: this.props.check,  
            filename: this.props.fname           
        }
    }

    idCheck  = nextId("expd_");
    idFile  = nextId("expd_");

    handleChecked = () => {
        let check = (this.state.check) ? false : true;
        this.props.changeChoice(check, this.state.filename);
        this.setState({check: check});
    }

    handleChangeFName =(e) => {
        let fname = e.target.value;
        //let fname = e.target.files[0].name;
        this.props.changeChoice(this.state.check, fname);
        this.setState({filename: fname});
    }

    render() {        
        return (
            <form className="form-export" style={{display: "flex", flexDirection: "column", width: '59vh'}}>           
                <div className="form-floating my-2">                
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" onChange={this.handleChecked} id = {this.idCheck} checked = {this.state.check}/>
                        <label className="form-check-label" htmlFor={this.idCheck}> разделить на файлы </label>
                    </div>
                </div>    
                <div className="form-floating mb-2">
                    <label htmlFor={this.idFile}>Экспортировать в файл:</label>
                    <input type="text" className="form-control" id={this.idFile}  placeholder="Имя файла(без расширения)" onChange={this.handleChangeFName}/>
                </div>                       
            </form>
        )



    }
}

export class ExportDocDialog extends React.Component {
        constructor(props) {
        super(props);
        this.state = {            
        };

        this.choice = [false, ""];

    }

    handleCancel = () => {
        this.choice = [false, ""];
        let obj = {status: null};
        this.props.setExportDoc(obj); 
    }  

    handleClickChoose = () => {
        let prms = this.props.prms;
        prms.status = 2;
        prms.split = this.choice[0];
        prms.fname = this.choice[1];
        this.props.setExportDoc(prms);           
        this.choice = [false, ""];
        
    }

    
    changeChoice(check, fname) {       
        let fname_e = fname + '.' + this.props.prms.typefile;       
        this.choice = [check, fname_e];
    }   

    render() {
      

        return (
            <Dialog handleOk={this.handleClickChoose} handleCancel={this.handleCancel}
                title={this.props.title} textOk='Применить' styleBox = {{height: '50vh', width: '65vh'}}   >  

                <div >
                    <ExportDoc check = {this.choice[0]}  fname = {this.choice[1]}
                        changeChoice = {(check, fname) => this.changeChoice(check, fname)}
                    />
                </div>

            </Dialog>
        );
    }
}