
export function notImplemented(name) {
    throw new Error("WARNING: function '" + name + "' not implemented");
}

export function MakePromise() {
    let ctrl = {
        resolve: null,
        init: (resolve) => {
            ctrl.resolve = resolve;
        }
    };
    let promise = new Promise(resolve => ctrl.init(resolve));
    promise.ctrl = ctrl;
    return promise;
}
