import { LOAD } from "../common/load";

class PrintForm {
    
    makeForm = (tfalias, documentName, keys, fields) => {
        this.xhr = LOAD.make_printform(documentName, keys, fields, this.parseResponseMakePrintForm.bind(this),
            () => {alert("Ошибка запроса формирования печатной формы! Алиас ТФ: " + tfalias)});          
    }

    downloadFiles = (uid, url) =>{
        var a = document.createElement("a");
        a.href = url;
        a.download = uid + "docx";
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);  
        }, 0); 
        
    }

    parseResponseMakePrintForm() {
        let xhr = this.xhr;        
        let objPf = null;
        try {
            objPf = JSON.parse(xhr.response);
        } catch (e) {            
            return;
        } 
        if (xhr.status === 200) {
            //console.log("!!!ResponseMakePrintForm: ", objPf);
            this.downloadFiles(objPf.uid, objPf.url);
        }               
    }
}

export let printForm = new PrintForm();