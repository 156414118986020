import ReactDOM from 'react-dom';
import $ from 'jquery';

let notify_str = "";

const MSG = 1;            //информационное сообщение
const WARN = 2;           //предупреждение
const CRITICAL = 3;       //критическое предупреждение

class Notify {

    showNotify(levelDanger, classContainer, lw, msg){
        let maxWidth = (lw===1) ? '50vh': '100vh';
        let domContainer = document.querySelector('.' + classContainer);
        let message = notify_str + "\n" + msg;
        notify_str = message;
        console.log("showNotify render");
        ReactDOM.render(
            <NotifyWindow
            level = {levelDanger}  maxWidth = {maxWidth}   message = {message}
            />,
            domContainer
        );
        $(".toast").toast('show');

        $('.' + classContainer).on('hidden.bs.toast', function () {
            notify_str = "";
            console.log("showNotify close");
        })
    }

    msg = (classContainer, lw, message) => {
        this.showNotify(MSG, classContainer, lw, message);
    }

    warn = (classContainer, lw, message) => {
        this.showNotify(WARN, classContainer, lw, message);
    }

    cwarn = (classContainer, lw, message) => {
        this.showNotify(CRITICAL, classContainer, lw, message);
    }

}

function NotifyWindow(props) {

    let delay = "5000";
    let autohide = true;
    let title = "Сообщение";
    if (props.level === 1)  { delay = "2000";  }
    if (props.level === 2)  { delay = "5000"; title = "Предупреждение"; }
    if (props.level > 2)    { autohide = false;  title = "Внимание"; }

    return(

        <div id="liveToast" className="toast fade hide m-3" style={{zIndex: '5', maxWidth: props.maxWidth}} role="alert" aria-live="assertive" aria-atomic="true"
                    data-autohide={autohide} data-delay={delay}>
            <div className="toast-header" >
              <strong className="mr-auto">{title}</strong>
              <button type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="toast-body">
                {props.message}
            </div>
        </div>


    );

}

export let knotify = new Notify();