//import React from 'react';
import { BpLang } from '../bp_lang/bp_lang';

export class BpCall {
    constructor(app) {
        this.app = app;
    };

    call_bp = async (name, args, component) => {
        return await (this._createBpLang(component)).run_bp(name, args);
    }

    call_func = async (func, args, component) => {
        return await (this._createBpLang(component)).run_func(func, args);
    }

    call_func_or_bp = async (name, args, component) => {
        if (typeof name === 'function') {
            return await this.call_func(name, args, component);
        } else {
            return await this.call_bp(name, args, component);
        }
    }

    _createBpLang = (component) => {
        if (component == null)
            component = this.app.activeComponent;
        return new BpLang({component: component, app: this.app});
    }
}