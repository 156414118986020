import React from 'react';
import { getSepMonthYear } from '../../common/helpers';
import { getMonthAsPrint } from '../../common/helpers';
import { Dialog } from "./dialog.js";

const MONTH = 0;
const YEAR = 1; 

class Choice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //hideMinus: true
        }
        let date = new Date();
        if (this.props.input[0]===MONTH)  { 
            this.state.month = (this.props.input[1] !=='') ? this.props.list[2] : getMonthAsPrint(date.getMonth());  
            this.state.year = (this.props.input[1] !=='') ? this.props.list[0] : date.getFullYear();             
        }
        else   {
            this.state.month = getMonthAsPrint(date.getMonth());  
            this.state.year = (this.props.input[1] !=='') ? this.props.list[0] : date.getFullYear();            
        }
    }

    handleChangeYear = (e) =>{
        let year = (Number(e.target.value)<1997) ? "1997" : e.target.value;
        year = (Number(e.target.value)>2100) ? "2100" : year;
        this.props.setNameMothYear(YEAR, year);
        this.setState({year: e.target.value});
    }

    handleChangeMonth = (e) =>{        
        let lm = ["январь", "февраль", "март", "апрель", "май", "июнь", "июль", "август", "сентябрь", "октябрь", "ноябрь", "декабрь"];
        let i = lm.indexOf(e.target.value);
        let fi = ((i+1)<10) ? "0"+(i+1) : String(i+1);        
        this.props.setNameMothYear(MONTH, fi);
        this.setState({month: e.target.value});
    }

    handleClickMinusYears = () =>{        
        let year = String(Number(this.state.year) - 1);
        this.props.setNameMothYear(YEAR, year);
        this.setState({year: year});
    }


    handleClickPlusYears = () =>{        
        let year = String(Number(this.state.year) + 1);
        this.props.setNameMothYear(YEAR, year);
        this.setState({year: year});
    };

    getChoiceMonth = (month) => {        
        let options = [];
        let lm = ["январь", "февраль", "март", "апрель", "май", "июнь", "июль", "август", "сентябрь", "октябрь", "ноябрь", "декабрь"];
        let elem;
        //options.push(elem); 
        //let style = {display: 'flex', flexFlow: 'column wrap'};
        //let flag = false;
        for(var i=0; i<12; i++) {                             
            if (Number(month)===(i)) {
                elem = <option selected key={i}>{lm[i]}</option>;
                options.push(elem);                           
                continue;
            }                        
            elem = <option key={i}>{lm[i]}</option>;                
            options.push(elem);             
        }
        let months =  <select value={lm[Number(this.state.month)-1]} onChange={this.handleChangeMonth}> {options} </select>                     
        return months;
    }

    getChoiceYear = (year) => {                                          
        let prev = '<';
        let next = '>';
        let block_years =( 
            <div className="input-group input-group-sm ml-3">
                <input type="text" className="form-control" placeholder="Введите год" aria-label="Введите год" 
                    onChange={this.handleChangeYear} value={this.state.year} />
                <div className="input-group-append" >
                    <button className="btn btn-primary mr-1" type="button" onClick={this.handleClickMinusYears}>{prev}</button>
                    <button className="btn btn-primary" type="button" onClick={this.handleClickPlusYears}>{next}</button>
                </div>
            </div>
        )                                            
        return block_years;        
    }

    createChoiceList = () => {
        let date = new Date();
        let choiceObj;
        let param =this.props.input[1];
        switch (this.props.input[0]) {
            case MONTH:
                let first;
                let second;
                if (param === '')  { first = this.getChoiceMonth(getMonthAsPrint(date.getMonth())); second = this.getChoiceYear(date.getFullYear()); }
                else               { first = this.getChoiceMonth(this.props.list[2]);  second = this.getChoiceYear(this.props.list[0]);  }                
                choiceObj = (
                    <div style={{display: "flex", flexDirection: 'row', justifyContent: "space-between"}}> 
                        {first}
                        {second}
                    </div>
                );                
                return choiceObj;                
            case YEAR:
                if (param === '')  choiceObj = this.getChoiceYear(date.getFullYear());
                else               choiceObj = this.getChoiceYear(param);                
                return choiceObj;                
            default:                 
                return null;
        }
    }    

    render() {
        let choiceObj = this.createChoiceList(this.props.input[1]);
        return choiceObj;
    }    
}

export class AskRgmGodDialog extends React.Component {
    constructor(props) {
        super(props); 
        let list = ["", "", ""];
        this.sep = "-"; 
        let date = new Date();       
        if (this.props.input[0]===MONTH && this.props.input[1]!=='')  list = this.getSepMonthYear(this.props.input[1]);
        if (this.props.input[0]!==MONTH && this.props.input[1]!=='')  list = [this.props.input[1], "", ""];
        if (this.props.input[0]===MONTH && this.props.input[1]==='')  list = [date.getFullYear(), '', getMonthAsPrint(date.getMonth())];        
        if (this.props.input[0]!==MONTH && this.props.input[1]==='')  list = [date.getFullYear(), "", ""];
        this.month = list[2];
        this.year = list[0];
        let nameVar = (this.props.input[0]===MONTH) ? this.year + '' + this.month : this.year;
        this.state = {
            nameVar: nameVar, 
            list: list
        };        
    }
        
    getSepMonthYear = (src) =>{
        let list = getSepMonthYear(src);
        return list;
    }

    createNameVar = () =>{
        if (this.year===undefined) return '';        
        let nameVar = (this.props.input[0]===MONTH) ? this.year + '' + this.month : this.year;
        return nameVar;
    }

    handleCancel = () => {
        this.props.askRgmGodDialogClose();
        this.props.promise.ctrl.resolve();
    }

    handleClickChoose = () => {           
        this.props.setChoiceRgmGodDialog(this.state.nameVar);         
        this.props.promise.ctrl.resolve(this.state.nameVar);
    }

    setNameMothYear = (my, name) => {        
        if (my===MONTH)  {
            this.month = name;
        }  else  {
            this.year = name;
        }
        let nameVar = this.createNameVar();  
        this.setState({nameVar: nameVar});  
    }    


    render() {
        let title = (this.props.input[0] === MONTH) ? "Выберите месяц и год (диапазон 1997-2100 гг.)" : "Выберите год (диапазон 1997-2100 гг.)"; 
        let variantPrint = (this.props.input[0]===MONTH) ? this.year + this.sep + this.month : this.year;

        return (

            <Dialog handleOk={this.handleClickChoose} handleCancel={this.handleCancel}
                title={title} textOk='Выбрать' styleBox = {{height: '50vh', width: '65vh'}}     >

                <div style={{display: 'flex', flexDirection: 'column', justifyContent: "space-between"}}>
                    <Choice input ={this.props.input} list={this.state.list}
                        setNameMothYear = {(my, name) => this.setNameMothYear(my, name)}                           
                    />   
                    <div className="input-group input-group-sm mb-3">   
                        <span type="text" className="input-group-text" value="Выбрано: " > Выбрано: </span>                         
                        <span type="text" className="input-group-text" value={variantPrint} > {variantPrint} </span>
                    </div>
                </div> 

            </Dialog>
        );        
    }
}