import React from 'react';
import { GenerateTForm } from "../tform/tform.js";
import { fillDictByExistKeys } from '../../common/helpers.js';
import { Dialog } from './dialog.js';

export class CatalogControl {

    constructor(component) {
        this.component = component;
        component.state.catalog_show = 0;
        component.state_catalog = null;
        this._resolve = null;
        this._showing = false;
        this._currentScreenField = null;
    }

    prepareCatalog = (cat) => {
        document._DEBUG(7, "prepareCatalog:", cat, "component:", this.component);
        this.component.state_catalog = cat;
    }

    showCatalog = (cat) => {
        document._DEBUG(5, "[CatalogControl].showCatalog - screenField", this._currentScreenField);
        document._DEBUG(5, ">> state_catalog:", this.component.state_catalog);
        this._showing = true;
        this.component.state_catalog.title = cat.title;
        this.component.setState({catalog_show: this.component.state.catalog_show + 1},
            () => {
                if (this._currentScreenField)
                    this._currentScreenField.blur();
            });
    }

    hideCatalog = () => {
        this._showing = false;
        document._DEBUG(5, "@@@ this.component:", this.component);
        this.component.setState({catalog_show: 0});
        if (this._resolve) {
            this._resolve();
        }
        if (this.component._from_bp != null) {
            if (this.component._from_bp.AfterCatalogEvent != null) {
                document._DEBUG(5, "@@@ this.component._from_bp.AfterCatalogEvent:", this.component._from_bp.AfterCatalogEvent, typeof this.component._from_bp.AfterCatalogEvent);
                //if (typeof this.component._from_bp.AfterCatalogEvent === 'function') {
                this.component.props.app.bpCall.call_func_or_bp(this.component._from_bp.AfterCatalogEvent, null, this.component);
                //}
            }
        }
    }

    isCatalogShowing = () => {
        return this._showing; //this.component.state.catalog_show != 0;
    }

    setCurrentScreenField = (field) => {
        document._DEBUG(5, "setCurrentScreenField", field);
        this._currentScreenField = field;
    }

    wait = () => {
        let self = this;
        function start(resolve) {
            self._resolve = resolve;
        }
        return new Promise(resolve => start(resolve));
    }

    renderCatalog = () => {
        let catalog = '';
        if (this.component.state.catalog_show) {
            let state_catalog = this.component.state_catalog;
            document._DEBUG(5, "renderCatalog component:", this.component, "state_catalog:", state_catalog,
                "catalogOptions:", state_catalog.catalogOptions, "tfOptions:", state_catalog.tfOptions);
            catalog = (
                <Catalog ref={state_catalog.ref} tfalias={state_catalog.tfalias} fieldName={state_catalog.fieldName}
                    onChoose={state_catalog.onChoose} title={state_catalog.title}
                    catalogControl={this} catalogOptions={state_catalog.catalogOptions}
                    app = {this.component.props.app}
                    currentScreenField = {this._currentScreenField}
                    tfOptions={state_catalog.tfOptions}
                ></Catalog>
            )
        }
        return (
            <div className="catalog-container">
                {catalog}
            </div>
        )
    }
};

export class Catalog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
        };
        fillDictByExistKeys(this.state, this.props);
    }

    handleClickChoose=(e)=>{
        e.stopPropagation();
        this.handleChoose();
    }

    handleChoose=()=>{
        document._DEBUG(5, 'handleChoose--> ',this.CatalogTform.state.curCell, this.CatalogTform.state.tf.data);
        if(this.CatalogTform.state.curCell){
            let row = this.CatalogTform.state.curCell.row;
            let column = this.CatalogTform.findColumnPosition(this.props.fieldName);
            let data_row = this.CatalogTform.rowByKey(row);
            document._DEBUG(5, 'handleChoose--> row:', row, 'column:', column, 'data_row:', data_row);
            if (data_row != null) {
                let value = data_row['row'][column];
                //this.props.onChoose(value);
                if (this.props.catalogControl._currentScreenField)
                    this.props.catalogControl._currentScreenField.onChooseCatalog(value);

                // FIXME приходится сделать хак
                setTimeout(()=>{
                    this.props.catalogControl.hideCatalog();
                }, 300);

                return;
            }
        }
        this.props.catalogControl.hideCatalog();
    }

    handleCancel=()=>{
        this.props.catalogControl.hideCatalog();
    }

    render() {
        let display = 'none';
        let show = true;
        if(show){
            display = 'flex';
        }
        let hideCancelBtn = false;
        let textOk = 'Выбрать';
        let is_catalog = true;
        let tfOptions = this.props.tfOptions;
        let disable_close_on_buttons = false;
        if (tfOptions != null && tfOptions._from_bp != null) {
            if (tfOptions._from_bp.tf_editable)
                is_catalog = false; // FIXME
            if (tfOptions._from_bp.ok_button_text != null)
                textOk = tfOptions._from_bp.ok_button_text;
            if (tfOptions._from_bp.disable_close_on_buttons != null)
                disable_close_on_buttons = tfOptions._from_bp.disable_close_on_buttons;
        }
        let onEscape = disable_close_on_buttons? null : this.handleCancel;
        let onEnter = disable_close_on_buttons? null : this.handleChoose;
        // console.log('Catalog tfalias: ', this.props.tfalias)
        if(show){
            document._DEBUG(5, 'Catalog tfalias: ', this.props.tfalias, "app:", this.props.app, "tfOptions:", this.props.tfOptions);
            return (
                <Dialog handleOk={this.handleChoose} handleCancel={this.handleCancel}
                    title='Выберите из справочника' textOk={textOk} hideCancelBtn={hideCancelBtn}>

                    <GenerateTForm
                        tfalias = {this.props.tfalias}
                        catalog = {is_catalog}
                        catalogChoose = {this.handleChoose}
                        catalogScreenField = {this.props.currentScreenField}
                        ref = {(el)=>{this.CatalogTform = el}}
                        app = {this.props.app}
                        parent = {this}
                        onEscape = {onEscape}
                        onEnter = {onEnter}
                        catalogOptions = {this.props.catalogOptions}
                        addedTfOptions = {tfOptions}
                        rerender = {Date.now()}
                    />

                </Dialog>
            );
        }else{
            return(
                <div style={{display: display}} ></div>
            )
        }
    }
}
