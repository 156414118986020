import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faFilter, faTimes} from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';

export class TfFilter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filtr_txt: ""
        };

        this.timeout = false;
        this.prev_value = "";
        this.filtr_txt = "";
        this.multiF = false;
        this.filtr = React.createRef();
    }

    //обработка потери фокуса окном ввода фильтра
    handleBlurInputFiltr = (filtr) => {
        let obj_f = this.props.s_filtr;
        let colNum = obj_f.column;
        if (colNum!=="" && filtr==="") {
            obj_f.column = "";
            delete obj_f[colNum];
            this.filtr_txt = "";
            this.props.setFilter(obj_f);
        }
    }

    //обработка щелчка по тэгу для фильтра с названием колонки (набор кнопок появляется под окном ввода значения фильтра)
    handleClickBtnReadFiltr = (e) => {
        let colNum = e.target.parentNode.dataset.colNum;
        this.filtr.current.focus();
        this.columnSelection([colNum, true]);
    }

    handleClickHeaderTF = (e, colNum) => {
        this.filtr.current.focus();
        //let colNum = e.target.dataset.colNum;
        this.columnSelection([colNum, e.ctrlKey]);
    }

    handleClickTbFiltr = (column) =>{
        this.columnSelection([column, false]);
    }

    //обработчик щелчка по кнопке фильтра с крестиком, относящейся к определенной колонке(набор кнопок появляется под окном ввода значения фильтра)
    handleClickBtnOneFiltr = (e) => {
        let colNum = e.target.parentNode.dataset.colNum;
        //let colNum2 = e.target.dataset.colNum;
        let obj_f = this.props.s_filtr;
        obj_f.column = "";
        delete obj_f[colNum];
        this.filtr_txt = "";
        this.props.setFilter(obj_f);
    }

    //обработка щелчка по кнопке сброса всех фильтров
    handleClickBtnResetFiltr = (e) => {
        let obj_f = {};
        obj_f.column = "";
        this.filtr_txt = "";
        this.props.setFilter(obj_f);
    }

    modified = () =>{
        if (this.prev_value!==$(this.filtr.current).val()) {
            this.prev_value=$(this.filtr.current).val();
            setTimeout(this.modified, 300);
        } else {
            this.timeout=false;
            let colNum = this.props.s_filtr.column;
            //console.log("сhangeFiltrTF modified >> ", filtr, this.props.s_filtr);
            let obj_f = this.props.s_filtr;
            if (!this.multiF) {
                obj_f = {};
                obj_f.column = colNum;
                obj_f[colNum] = this.state.filtr_txt;
            } else {
                obj_f.column = colNum;
                obj_f[colNum] = this.state.filtr_txt;
            }
            this.props.setFilter(obj_f);
        }
    }

    сhangeFiltrTF = (filtr) => {
        if(!this.timeout) {;
            this.prev_value = filtr;
            setTimeout(this.modified, 300);
        }
        this.filtr_txt = filtr;
        this.setState({filtr_txt: filtr});


    }

    columnSelection = (arr) => {
        let colNum;
        if (arr[0]!=="") colNum = arr[0];  //последняя выбранная мышью колонка тф
        if (arr[1]!=="")
            this.multiF = arr[1];  // флаг тычка мыши на колонку тф вместе с зажатой клавишей ctrl
        let obj_f = this.props.s_filtr;
        let filtr = (obj_f[colNum] == null) ? "" : obj_f[colNum];
        switch (this.multiF) {
            case true:
                obj_f.column = colNum;
                obj_f[colNum] = filtr;
                break;
            default:
                obj_f = {};
                obj_f.column = colNum;
                obj_f[colNum] = filtr;
        }
        this.filtr_txt = filtr;
        this.props.setFilter(obj_f);
    }

    //генерация списка тегов для кнопок набора текущих фильтров по колонкам
    generateBtnsFiltr = (obj_f, columns) => {
        let listItems = [];
        let nn = obj_f.column;
        for (let key in obj_f) {
            let f_str = obj_f[key];
            if (key !== "column" && key !== "connect_filter" && f_str!=null) {
                let titleColumn = columns[key].ColTitle.replace(/;/gi, ' ') + " ";
                let btn_cls = (nn===key) ? "btn btn-info btn-sm py-0 px-1 mt-1 fltr_1" : "btn btn-outline-info btn-sm py-0 px-1 mt-1 fltr_1";
                //console.log("generateBtnsFiltr : nn key = >>", nn, key, nn===key, btn_cls);
                let elem = (
                    <div key={key} className="btn-group filtr_grp" role="group" data-col-num={key} >
                        <button
                            type="button"
                            className={btn_cls}
                            onClick={this.handleClickBtnReadFiltr}
                            title={f_str}
                        >
                            {titleColumn}
                        </button>
                        <button
                            type="button"
                            className="btn btn-outline-danger btn-sm py-0 px-1 mt-1 mr-1"
                            onClick={this.handleClickBtnOneFiltr}
                            data-col-num={key}
                            title={f_str}
                        >
                            <FontAwesomeIcon icon={faTimes} data-col-num={key} />
                        </button>
                    </div>
                    );
                listItems.push(elem);
            }
        }
        return listItems;
    }

    render() {
        let access_fltr = (this.props.s_filtr.column === "") ? true : false;
        let focus = (this.props.s_filtr.column !== "") ? true : false;
        //let filtr_txt = (this.props.s_filtr.column !== "") ? this.props.s_filtr[this.props.s_filtr.column] : "";

        let filtr_lst = this.generateBtnsFiltr(this.props.s_filtr, this.props.tfInfoColumns);

        const styleBtnGrp = {padding: "0px",
                            display: "flex",  flexWrap: "wrap" };
        return (
        <div className="tf-filter-field" style={{maxWidth: '400px', flex: 1, display: 'flex', flexDirection: 'column'}}>
            <div className="input-group input-group-sm mt-1 mb-0">

                <div className="input-group-prepend">
                    <span className="input-group-text" style={{height: '31px'}}>
                        <FontAwesomeIcon icon={faFilter} />
                    </span>
                </div>

                <input
                    type="text"
                    className="form-control"
                    ref={this.filtr}
                    id="filtr"
                    readOnly={access_fltr}
                    autoFocus={focus}
                    onInput={() => {
                        let filtr = $(this.filtr.current).val(); //SEE: FOR @gizs
                        console.log("((((filtr:", filtr);
                        this.сhangeFiltrTF(filtr);
                    }}
                    onBlur={() => {
                        let filtr = $(this.filtr.current).val(); //SEE: FOR @gizs
                        this.handleBlurInputFiltr(filtr);
                    }}
                    value={this.filtr_txt}
                />

                <div className="input-group-append" style={{height: '31px'}}>
                    <button
                        className="btn btn-outline-secondary"
                        type="button"
                        id="Reset_filtr"
                        title = "Сброс всех фильтров"
                        onClick={this.handleClickBtnResetFiltr}
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </div>
            </div>
            <div style={styleBtnGrp} >
                {filtr_lst}
            </div>
        </div>
        );
    }
}
