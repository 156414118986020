
class Load {

    ask_access(onload, onerror) {
        return LOAD._GET("/api/access", onload, onerror, true);
    }

    login(data, onload, onerror) {
        return LOAD._POST("/api/login", data, onload, onerror, true);
    }

    logout(onload, onerror) {
        return LOAD._POST("/api/logout", null, onload, onerror, true);
    }

    get_serv_status(onload, onerror) {
        return LOAD._GET("/api/status", onload, onerror, true);
    }

    get_serv_cfg(onload, onerror) {
        return LOAD._GET("/api/admin/configure", onload, onerror, true);
    }

    get_main_menu(onload, onerror) {
        return LOAD._GET("/api/menu", onload, onerror, true);
    }

    get_config(name, onload, onerror) {
        let data = {
            param2: ""
        }
        return LOAD._POST("/api/config/"+name, data, onload, onerror, true);
    }

    createFilters(columns, obj_f) {
        console.log("!!!!createFilters obj_f >> ", obj_f, "columns: ", columns);
        let filters = [];
        for (let key in obj_f) {
            let f_str = obj_f[key];
            if (key !== "column" && key !== "connect_filter" && f_str!=null && f_str!=='') {
                let field = (columns[key].FieldName==='' || columns[key].FieldName===undefined) ? columns[key].CustCalcFieldName : columns[key].FieldName;
                filters.push(
                    {   "field": field,
                        "operator": "like",
                        "values": ["%" + f_str + "%"]
                    }
                );
            }
        }
        if (obj_f.connect_filter != null) {
            if (obj_f.connect_filter.key != null) {
                for (const my_key in obj_f.connect_filter.key) {
                    let to_key = obj_f.connect_filter.key[my_key];
                    filters.push({
                        "field": my_key,
                        "operator": "=",
                        "values": [to_key]
                    });
                }
            }
            if (obj_f.connect_filter.TYP_D != null)
                filters.push({
                    "field": "TYP_D",
                    "operator": "=",
                    "values": [obj_f.connect_filter.TYP_D]
                });
            if (obj_f.connect_filter.DOK_ID != null)
                filters.push({
                    "field": "DOK_ID",
                    "operator": "=",
                    "values": [obj_f.connect_filter.DOK_ID]
                });
        }
        return filters;
    }

    createSorts(columns, objSort) {
        console.log("!!!!createSorts objSort >> ", objSort, "columns: ", columns);
        let sorts = [];
        if (objSort.counter<1) {
            console.log("!!!!createSorts sorts >> ", sorts);
            return sorts;
        }
        let tmp = {};
        for (let key in objSort) {
            if (key !== "counter") {
                let field = (columns[key].FieldName==='' || columns[key].FieldName===undefined) ? columns[key].CustCalcFieldName : columns[key].FieldName;
                tmp[objSort[key].num] = {   "order": objSort[key].order, "field": field  };
            }
        }
        for (let key in tmp) {
            sorts.push(tmp[key]);
        }
        console.log("!!!!createSorts sorts >> ", sorts);
        return sorts;
    }

    get_tf_info(tfalias, onload, onerror) {
        let data = {
            info: true
        }
        return LOAD._POST("/api/tf/" + tfalias, data,
            onload, onerror);
    }

    get_tf(tfalias, columns, filtr, sort, onload, onerror) {
        let data = {
            info: true,
            data: true,
            sort: this.createSorts(columns, sort),
            filters: this.createFilters(columns, filtr)
        }
        console.log("get_tf > data >> ", data);
        return LOAD._POST("/api/tf/" + tfalias, data,
            onload, onerror);
    }

    get_tf_page(tfalias, columns, filtr, sort, start, limit, onload, onerror,) {
        document._DEBUG(5, "[get_tf_page] -> [", start, ":", limit, "]");
        return LOAD._POST("/api/tf/" + tfalias, {
            info: true,
            data: true,
            start: start,
            limit: limit,
            sort: this.createSorts(columns, sort),
            filters: this.createFilters(columns, filtr)
        }, onload, onerror, true);
    }

    get_tf_with_uniqFilter(dict){
        let tfalias = dict.tfalias;
        let filters = dict.filters;
        let sort = dict.sort;
        let onload = dict.onload;
        let onerror = dict.onerror;
        let data = {
            info: true,
            data: true, 
            sort: sort,       
            filters: filters
        }
        console.log("get_tf > data >> ", data);
        return LOAD._POST("/api/tf/" + tfalias, data,
            onload, onerror);
    }

    get_tbl(tblalias, onload, data) {
        return LOAD._POST("/api/tbl/" + tblalias,
        data, onload);
    }

    check_validation(tfalias, colInfo, value, tflag, onload, onerror) {
        let field = (colInfo.FieldName==='' || colInfo.FieldName===undefined) ? colInfo.CustCalcFieldName : colInfo.FieldName;
        let data = {
            name: field,
            value: value,
            tflag: tflag
        }
        return LOAD._POST("/api/tf/" + tfalias + "/validation", data,
            onload, onerror);
    }

    export_doc(tfalias, filename, reportType, columns, filtr, sort, start, limit, onload, onerror) {
         let data = {
            reportType: reportType,
            withHeaders: false,
            comma: ";",
            start: start,
            limit: limit,
            sort: this.createSorts(columns, sort),
            filters: this.createFilters(columns, filtr)
         }
         return LOAD._POST("/api/tf/" + tfalias + "/export/'" + filename + "'", data,
            onload, onerror);
    }

    make_printform(documentName, keys, fields, onload, onerror) {
        let data = {
            key: keys,
            //fields: fields
        }
        return LOAD._POST("/api/template/" + documentName, data,
            onload, onerror);
    }


    save_fixHRow(data, onload, onerror) {
        return LOAD._PUT("/api/options", data, onload, onerror);
    }

    save_row(tfalias, data, onload, onerror) {
        return LOAD._PUT("/api/tf/"+tfalias+"/record", data, onload, onerror);
    }

    add_row(tfalias, data, onload, onerror) {
        return LOAD._POST("/api/tf/"+tfalias+"/record", data, onload, onerror);
    }

    del_row(tfalias, data, onload, onerror) {
        return LOAD._send("DELETE", "/api/tf/"+tfalias+"/record", data, onload, onerror);
    }

    save_setting(data, flagFile, idFileInput, onload, onerror) {
        return LOAD._POST_multipart("/api/admin/configure", data, flagFile, idFileInput, onload, onerror, true);
    }

    run_sql(bp_name, sql_id, params, onload, onerror) {
        document._DEBUG(7, "-> bp_name:", bp_name);
        bp_name = bp_name.split(".js")[0] + ".js";
        document._DEBUG(7, "-> bp_name (2):", bp_name);
        if (bp_name.indexOf('/bp/') >= 0) {
            bp_name = bp_name.split('/bp/')[1];
        }
        return LOAD._run_sql_base("/api/bp/sql", bp_name, sql_id, params, onload, onerror);
    }

    run_sql_public(bp_name, sql_id, params, onload, onerror) {
        return LOAD._run_sql_base("/api/public/sql", bp_name, sql_id, params, onload, onerror);
    }

    _run_sql_base(url, bp_name, sql_id, params, onload, onerror) {
        let data = {
            bp: bp_name,
            queryIndex: sql_id,
            params: params
        }
        document._DEBUG(5, "[SQL]", data);
        var xhr = LOAD._POST(url, data, ()=>{
            document._DEBUG(5, "response:", xhr.responseText);
            let result = {};
            try {
                result = JSON.parse(xhr.responseText)
            } catch (e) {
                document._DEBUG(1, "ERROR (json)", e);
            }
            onload(result);
        }, (e)=> {
            document._DEBUG(5, "ERROR:", e);
            onerror(e);
        });

        return xhr;
    }

    load_script( src, callback, error_callback) {
        var s = document.createElement( 'script' );
        s.setAttribute( 'src', src );
        s.onload = callback;
        s.onerror = error_callback;
        document.body.appendChild( s );
    }

    register_file(tfAlias, recordKey, filename, type, uid, onload, onerror) {
        return LOAD._POST("/api/row/"+tfAlias+"/file", {
            "filename": filename,
            "type": type,
            "uid": uid,
            "data":{"key": recordKey}
        }, onload, onerror)
    }

    send_file(file, onload, onerror) {
        let xhr = LOAD.get_config("kfg_CfsAddress", () => {
            document._DEBUG(5, "Answer:", xhr.response);
            if (xhr.status === 200) {
                var objVal = JSON.parse(xhr.response);
                let server_address = objVal.value;
                if (server_address.indexOf(":") < 0) {
                    server_address += ":8000";
                }
                let url = 'http://' + server_address + '/file/post/';
                LOAD.send_file_to_url(file, url, onload, onerror);
                return
            }
        }, (e) => {
            document._DEBUG(1, "Error:", e, xhr.response);
            e.response = xhr.response;
            onerror(e);
        });
    }

    send_file_to_url(file, url, onload, onerror) {
        let req = new XMLHttpRequest();

        let formData = new FormData();
        formData.append("file", file);

        req.onload = () => {
            onload(req);
        }

        req.onerror = () => {
            document._DEBUG(5, "send_file ERROR:", req);
            onerror(req);
        };

        req.onreadystatechange=function() {
            if (req.readyState === 4) {
                if (req.status !== 200) {
                    document._DEBUG(5, "send_file ERROR:", req);
                    onerror(req);
                }
            }
        }

        //let url = 'http://localhost:8000/file/post/'; // FIXME need found server address
        let method = 'POST';
        req.open(method, url, true);

        setTimeout(function() {
            document._DEBUG(3, method+":", url, "SEND FILE", file);
            req.send(formData);
            document._DEBUG(3, method+":", url, "SEND FIN");
        }, 100);

        return req;
    }

    _POST(url, data, onload, onerror, silent) {
        if (!data) {
            data = {};
        }
        return this._send("POST", url, data, onload, onerror, silent);
    }

    _POST_multipart(url, data, flagFile, idFileInput, onload, onerror, silent) {
        if (!data) {
            data = {};
        }
        return this._send_multipart("POST", url, data, flagFile, idFileInput, onload, onerror, silent);
    }

    _GET(url, onload, onerror, silent) {
        if (!silent)
            document._DEBUG(3, "GET:", url);
        let xhr = new XMLHttpRequest();

        xhr.open("GET", url, true);
        xhr.onload = onload;
        xhr.onerror = onerror;

        setTimeout(function(){
            if (!silent)
                document._DEBUG(3, "GET:", url, "SEND");
            xhr.send();
            if (!silent)
                document._DEBUG(3, "GET:", url, "SEND FIN");
        }, 100);

        if (!silent)
            document._DEBUG(3, "GET:", url, "FIN");
        return xhr;
    }

    _PUT(url, data, onload, onerror, silent) {
        return this._send("PUT", url, data, onload, onerror, silent);
    }

    _send(method, url, data, onload, onerror, silent) {
        var jdata = JSON.stringify(data);
        let xhr = new XMLHttpRequest();

        xhr.open(method, url, true);
        xhr.setRequestHeader("Content-Type", "application-json; charset=utf-8");
        xhr.onload = onload;
        xhr.onerror = ()=> {
            if (!silent)
                document._DEBUG(5, "_send ERROR:", xhr);
            onerror();
        };
        xhr.onreadystatechange=function() {
            if (xhr.readyState === 4) {
                if (xhr.status !== 200) {
                    if (!silent)
                        document._DEBUG(5, "_send ERROR:", xhr);
                    onerror();
                }
            }
        }

        setTimeout(function() {
            if (!silent)
                document._DEBUG(3, method+":", url, "SEND", data);
            xhr.send(jdata);
            if (!silent)
                document._DEBUG(3, method+":", url, "SEND FIN");
        }, 100);

        return xhr;
    }

    _send_multipart(method, url, data, flagFile, idFileInput, onload, onerror, silent) {
        var formData = new FormData();
        formData.append("json", JSON.stringify(data));

        if (flagFile) {
            let fileInput = document.getElementById(idFileInput);
            formData.append("metaDataConfig", fileInput.files[0]);
        }

        let xhr = new XMLHttpRequest();
        xhr.open(method, url, true);
        //xhr.setRequestHeader("Content-Type", "application-json; charset=utf-8");
        xhr.onload = onload;
        xhr.onerror = onerror;

        setTimeout(function() {
            if (!silent)
                document._DEBUG(3, method+":", url, "SEND");
            xhr.send(formData);
            if (!silent)
                document._DEBUG(3, method+":", url, "SEND FIN");
        }, 100);

        return xhr;
    }

};

export let LOAD = new Load();
