import { LOAD } from "../../common/load";
import { MakePromise } from "../../bp_lang/bp_common.js";

export class TfCheckValidation {
    constructor(component) {
        this.component = component;  

    }

    server_check_uniq = async (tfalias, colInfo, value, flagIn) => {
        let promise = MakePromise();
        this.xhr = LOAD.check_validation(tfalias, colInfo, value, flagIn, this.parseResponseCntrlValid.bind(this, promise),
            () => {return false});        
        return promise;
    }

    control_uniq = async (tfalias, value, colInfo, data, columnKey) => {
        let flag = true;
        if (colInfo.UniqChkType===undefined || colInfo.UniqChkType<1 ) {            
            return flag;
        }
        let isNum2 = true;        
        if (isNaN(Number(value))) {
            isNum2 = false;
        }
        let cmpr2 = (value==null || isNum2) ? value : value.trim();
        if (!colInfo.UniqUseTrim) {                
            cmpr2 = value;
        }
        //let err_type = false;
        let isNum1;
        for (let elem of data) {                        
            if (isNaN(Number(elem.row[columnKey]))) {
                isNum1 = false;

            }  else {
                isNum1 = true;
            }
            //console.log("control_uniq elem isNum1>> ", elem.row[columnKey], Number(elem.row[columnKey]), isNaN(Number(elem.row[columnKey])), isNum1, isNum2);      
            let cmpr1 = (elem.row[columnKey]==null || isNum1) ? elem.row[columnKey] : elem.row[columnKey].trim();                
            if (!colInfo.UniqUseTrim) {
                cmpr1 = elem.row[columnKey];                
            }
            if (isNum1===isNum2) {
                if (isNum1) {
                    if (Number(cmpr1)===Number(cmpr2)) {
                        flag = false;
                        break;
                    }
                } else {
                    if (cmpr1===cmpr2) {
                        flag = false;
                        break;
                    }
                }
            }
            if (isNum1!==isNum2) {
                if (isNum1) {
                    if (String(cmpr1)===cmpr2) {
                        flag = false;
                        break;
                    }
                } else {
                    if (cmpr1===String(cmpr2)) {
                        flag = false;
                        break;
                    }
                }
            }

            /*if (isNum1!==isNum2) {
                err_type = true;
                console.log("!!!!!!!!!!control_uniq:", isNum1,  cmpr1, isNum2, cmpr2);
            }*/
        }        
        let server_not_uniq = await this.server_check_uniq(tfalias, colInfo, cmpr2, true);  //значение false последнего параметра остается для даты, но не работоспособно
        console.log("!!!!!!!!!!flag  server_not_uniq:", flag,  server_not_uniq);
        if (server_not_uniq) flag = false;
        /*if (err_type) {
            alert("Введенное значение и данные в колонке отличаются по типу. Контроль уникальности может работать некорректно.");
        }*/
        let msg;
        if (!flag && colInfo.UniqChkType!==undefined) {
            if(colInfo.UniqErrorMsg.length>0) {
               msg = colInfo.UniqErrorMsg;                       
            } else {
               msg = String(value) + ". Такое значение поля уже есть в таблице."
            }
            if (colInfo.UniqChkType===1) {
               alert(msg);           
            } else {
               flag = window.confirm(msg);     
            }
        }
        return flag;
    }

    control_range(value, colInfo) {
        let flag = true;
        if (!colInfo.IsCheckRange)
            return flag;
        let isNum = true;
        if (isNaN(Number(value))) {
            isNum = false;
        }
        let from = (isNum) ? Number(colInfo.ValChkRangeFrom) : colInfo.ValChkRangeFrom;
        let to = (isNum) ? Number(colInfo.ValChkRangeTo) : colInfo.ValChkRangeTo;
        let compr = (isNum) ? Number(value) : value;
        if ((colInfo.ValChkRangeFrom.length>0 && colInfo.ValChkRangeTo.length>0 && from>compr) || to<compr) {
            flag = false;
        }
        //console.log("!!!control_range flag");
        //console.log("!!!control_range len_from from to value>>", from, to, compr);
        let msg;
        if (!flag) {
            if(colInfo.RangeCheckErrorMsg.length>0) {
               msg = colInfo.RangeCheckErrorMsg;           
            } else {
               msg = "Значение должно входить в диапазон от " + colInfo.ValChkRangeFrom + " до " + colInfo.ValChkRangeTo;
            }
            alert(msg);                   
        }
        return flag;   
    }

    interp_expression(value, expression) {
        let flag = false;
        if (value.length>0)
            flag = true;
        return flag;
    }

    control_truth_expression(value, colInfo) {
        let flag = true;
        if (!colInfo.IsVerifyExpr)
            return flag;
        if (colInfo.ValChkExpr.length>0) {
            flag = this.interp_expression(value, colInfo.ValChkExpr);        
        }
        let msg;
        if (!flag) {
            if(colInfo.VerifyErrMsg.length>0) {
               msg = colInfo.VerifyErrMsg;           
            } else {
               msg = "нарушена истинность выражения: " + colInfo.ValChkExpr;
            }
            alert(msg);                   
        }
        return flag;
    }

    check_data_valid = (colInfo, value) =>{
        let flag = true;
        if (colInfo.DataType!==2)  
            return true;   
        let lst = value.split("-");
        let year = lst[0];
        if (year.length>4) {
            flag=false;
            alert("Допущена ошибка! Запись года не может состоять более чем из 4 символов!");
        }
        return flag;
    }

    

    control_validation = async (tfalias, value, colInfo, data, columnKey) => {          
        let checkD = this.check_data_valid(colInfo, value); 
        let uniq = await this.control_uniq(tfalias, value, colInfo, data, columnKey);
        let range = this.control_range(value, colInfo);
        let logic = this.control_truth_expression(value, colInfo);
        if (checkD && uniq && range && logic) {                   
            this.component.setState({
                curCell: this.component.make_NULL_CURCELL(this.component.state.curCell.row, columnKey)                
            });        
        }
    }

    parseResponseCntrlValid = (promise) => {
        let xhr = this.xhr;        
        let objTf = null;
        try {
            objTf = JSON.parse(xhr.response);
        } catch (e) {            
            return;
        }                
        if (xhr.status === 200) {
            console.log("!!!server_check_validation: ", objTf.isValue);
            promise.ctrl.resolve(objTf.isValue);

        } else {
            promise.ctrl.resolve(false);
        }
    }
}