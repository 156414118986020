//const SORT_OFF = 0;             // режим настройки сортировки выкл
const SORT_ON = 1;              // режим настройки сортировки активирован (но еще не прощелкивалась ни одна колонка)
const SORT_ON_MULTI = 2;        // режим настройки сортировки активирован и реализован уже как минимум на одной колонке

export class TfSort {

    constructor(tform) {
        this.tform = tform;  
    }

    changeSort = (colNum) =>{  
        console.log("changeSort SORT", this.tform.sortMode);      
        if (this.tform.sortMode === SORT_ON) {
            this.changeSortOn(colNum);
        } else {
            this.changeSortMulti(colNum);
        }
    }

    changeSortOn = (colNum) =>{
        this.tform.sortMode = SORT_ON_MULTI;
        let obj_sort = {};
        obj_sort.counter = 1;
        obj_sort[colNum] = {'order': "asc", 'num': 1};
        this.tform.setState({s_sort: obj_sort});
    }

    changeSortMulti = (colNum) =>{
        console.log("changeSortMulti SORT");      
        let objSort = this.tform.state.s_sort;
        console.log("changeSortMulti objSort", this.tform.state.s_sort);  
        let sortCol = objSort[colNum];
        if (sortCol===undefined) {
            this.addNewColumnInSort(colNum);
        } else {
            this.changeOrderSort(colNum);
        }
    }

    addNewColumnInSort = (colNum) =>{
        let objSort = this.tform.state.s_sort;   
        let count = objSort.counter;
        objSort.counter = count+1;
        objSort[colNum] = {'order': "asc", 'num': count+1};
        this.tform.setState({s_sort: objSort});
    }

    changeOrderSort = (colNum) =>{
        let objSort = this.tform.state.s_sort;   
        let order = objSort[colNum].order;
        let new_order;
        switch (order) {
            case 'asc':
                new_order = 'desc';
                objSort[colNum].order = new_order;
                break;
            default:
                let count = objSort.counter;
                objSort.counter = count-1;
                delete objSort[colNum];
                objSort = this.correctNumbersSort(colNum);
        }
        this.tform.setState({s_sort: objSort});
    }

    correctNumbersSort = (colNum) =>{
        let objSort = this.tform.state.s_sort;  
        for(let key in objSort) {
            if(objSort[key].num > Number(colNum)){
                let new_num = objSort[key].num - 1;
                objSort[key].num = new_num;
            }
        }
        return objSort;
    }

}