import { MakePromise } from "../bp_lang/bp_common";

export const Restrict = {
    'rAllowed' : 1,     // корректное значение
    'rWarning' : 2,   // предупреждение о некорректном значении
    'rForbid' : 3,       // введено запрещенное значение
};

// const valueAlloweded = 1
// const valueWarning = 2
// const valueForbidden = 3

export const FieldKind = {
  'tfkData' : 0,     // поле данных таблицы
  'tfkCalculated' : 1,   // вычисляемое поле
  'tfkLookup' : 2,       // поле, вычисляемое по таблице
  'tfkDictionary' : 3    // поле расшифровки значения
};

export const FieldType = {
    'tftNumeric' : 0,
    'tftCharacter': 1,
    'tftDate' : 2,
    'tftBoolean' : 3,
    'tftSpecial' : 4
};

export const DataSubType = {
    'ksql_stDefault' : 0,   // по-умолчанию

    // числовые подтипы
    'ksql_stTinyInt' : 1,   // 0-255
    'ksql_stReal' : 2,   // 3.4E-38 до 3.4E+38
    'ksql_stFloat' : 10,  // floating point numeric field
    'ksql_stInteger' : 11,  // 32-bit integer field
    'ksql_stSmallint' : 12,  // 16-bit integer field
    'ksql_stWord' : 13,  // 16-bit unsigned integer field
    'ksql_stCurrency' : 14,  // Money field
    'ksql_stAutoInc' : 15,  // Auto-incrementing 32-bit integer counter field
    'ksql_stAutoIncID' : 17,  // Auto-incrementing фы  ID_Server
    'ksql_stBCD' : 16,  // Binary-coded Decimal field

    // Символьные типы данных
    'ksql_stVarChar' : 33,   // переменной длины
    'ksql_stChar' : 34,   // заданной длины
    'ksql_stNVarChar' : 35,   // переменной длины  // gao for UNICODE in COM project
    'ksql_stNChar' : 36,   // заданной длины

    // Даты
    'ksql_stDateTime' : 65, // дата и время от 1.1.1753 - 12.31.9999г
    'ksql_stSmallDateTime' : 66, // дата и время от 1.1.1900 - 6.6.2079г

    //Логический
    'ksql_stLogical' : 97, // Логический

    // Cпециальные типы данных
    'ksql_stBit' : 129, // Бит
    'ksql_stTimeStamp' : 130, //
    'ksql_stBinary' : 131, // Двоичные данные (<255 байт)
    'ksql_stVarBinary' : 132, // Переменной длины двоичные
    'ksql_stText' : 133, // Текст длиной до 2 гигов
    'ksql_stImage' : 134, // Двухгигоб-ые образы.
    'ksql_stLongString' : 135,
    'ksql_stGUID' : 136
};


export function fillDictByExistKeys(to, from){
    for(let key in from){
        if(key in to){
            to[key] = from[key];
        }
    }
}

export async function openDialogVariants(component, title, text, variants) {
    let v_lst = variants.split("\n");
    let promise = MakePromise();
    component.setState({choice_prm: [title, text, v_lst, promise]});
    return promise;
}

export function compasTitleToWebTitle(_title){
    let title = _title.replaceAll('-;', '');
    title = title.replaceAll(';', ' ');
    return title
}

export function convertKompasValueToWeb(_value, _datatype){
    document._DEBUG(10, "convertKompasValueToWeb ==> ", _value, _datatype);
    let value = _value;
    if(value && _datatype === FieldType.tftDate){
        value = value.split('T')[0];
    }
    return value
}

export function convertWebValueToKompas(_value, _datatype){
    let value = _value;
    if(value && _datatype === FieldType.tftDate && value.length === 10){
        if(value.indexOf('.') !== -1){value = convertDateStringToInputValue(value)}
        value += 'T00:00:00Z';
    }
    return value
}


export function convertDateStringToInputValue(_value){
    let splitted = _value.split('.');
    let value = splitted[2]+'-'+splitted[1]+'-'+splitted[0]; 
    return value;
}

export function convertDateInputValueToString(_value){
    let splitted = _value.split('-');
    let value = splitted[2]+'.'+splitted[1]+'.'+splitted[0]; 
    return value;    
}

export function convertKompasDateToTemplate(_value){
    let value = _value;  
    if(value){
        value = value.split('T')[0];        
        let splitted = value.split('-');
        value = splitted[2]+'.'+splitted[1]+'.'+splitted[0]; 
    }else{
        value = "";
    }
    return value;
}

export function checkInDictAndFill(d, key, _value){
    let value = _value;
    if(key in d){
        value = d[key];
    }
    return value;
}

export function findColumnPosition(columns, fieldName){
    for (let pos in columns){
        if(columns[pos].FieldName === fieldName){
            return pos;
        }
    }
}

export function findRowByValue(objTf, fieldName, value){
    let row = null;
    let fieldPos = findColumnPosition(objTf.info.Columns, fieldName);
    let data = objTf.data;
    for (let i in data){
        let rowForCheck = data[i].row;        
        if(rowForCheck[fieldPos] == value){
            return rowForCheck;
        }
    }
    return row;
}

export function findValueByAnotherValue(objTf, findField, byField, byValue){
    let row = findRowByValue(objTf, byField, byValue);
    let pos = findColumnPosition(objTf.info.Columns, findField)
    document._DEBUG(5, "findValueByAnotherValue ==> ", objTf.info.Columns, findField, byField, byValue, row, pos);

    return row[pos];
}

export function getSepMonthYear(src){
    let year = src.slice(0, 4);
    //console.log("getSepMonthYear >>", src, year);
    let sep = '';
    let month = src.slice(4);
    return [year, sep, month];
}

export function getMonthAsPrint(m) {
    let fi = (Number(m+1)<10) ? "0"+Number(m+1) : String(m+1);
    return fi;
}

export function processKeyEvent(e, keys) {
    let names = [null, "Shift", "Ctrl", "Alt"];
    for (let name of names){
        let key = e.key;
        if (name) {
            key = name + "+" + key;
            if (keys[key] && e[name.toLocaleLowerCase()+"Key"]) {
                //console.log(name + "+" + e.key + " пункт: >> ", keys[name + "+" + e.key]);
                return {ckey: key, flag: true};
            }
        } else {
            if (keys[e.key] && !e.shiftKey && !e.ctrlKey && !e.altKey) {
                //console.log(e.key + " пункт: >> ", keys[e.key]);
                return {ckey: key, flag: true};
            }
        }
    }
    return {ckey: "", flag: false};
}

export function parseUrlParams() {
    let params_lst = window.location.search.split('&');
    let params = {
        'debug': 0,
        'contextmenu': false,
        'bill': '',
    }

    for (let i=0; i<params_lst.length; i++) {
        let param_str = params_lst[i];//window.location.search.split('debug=')[1];
        for (const o of Object.entries(params)) {
            let key = o[0];
            let lst = param_str.split(key + '=');
            if (lst.length > 1) {
                params[key] = lst[1];
            }
        }
    }

    return params;
}

export function showNowDt(start) {
    let ms = new Date().getTime() - start;
    return ms +  " ms = " + format_microseconds(ms);//77
}

function format_microseconds(ms) {
    var ss = Math.round(ms / 1000) //time.getSeconds()
    var mm = 0 //time.getMinutes()
    var hh = 0 //time.getHours()

    var timeString=hh + ':' + mm + ':' + ss + '.' + ms
    return timeString;
}

export function clone(obj) {
    if (obj === null || typeof (obj) !== 'object' || 'isActiveClone' in obj)
        return obj;

    let temp = null;
    if (obj instanceof Date)
        temp = new obj.constructor(); //or new Date(obj);
    else
        temp = obj.constructor();

    for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            obj['isActiveClone'] = null;
            temp[key] = clone(obj[key]);
            delete obj['isActiveClone'];
        }
    }
    return temp;
}

export function StartsWith(s, sub) {
    return s.indexOf(sub) === 0;
}

export function EndsWith(s, sub) {
    return s.indexOf(sub) === s.length - sub.length;
}
