import { LOAD } from "../common/load";
import { Sql } from "./bp_sql";
import { TForm } from "./bp_tform";
import { notImplemented, MakePromise } from "./bp_common";
import { openDialogVariants } from "../common/helpers";
//import { knotify } from "../common/notify";


export class BpLang {

    constructor({component, app}) {
        console.log("BpLang.component:", component, "app:", app);
        this.component = component;
        this.app = app;
        if (document.kompas == null) {
            document.kompas = {};
        }
        if (document.kompas._bp_groups == null) {
            document.kompas._bp_groups = {};
        }
        document.kompas.get_bp_group = (group_name) => {
            if (document.kompas._bp_groups[group_name] == null) {
                document.kompas._bp_groups[group_name] = new BpGroup();
            }
            return document.kompas._bp_groups[group_name];
        }
    }

    run_bp = async (alias, args) => {
        let lst = alias.split(".");
        let name = lst[lst.length-1];
        let group = "Основная";
        if (lst.length === 2) {
            group = lst[0];
        }
        let url = "/bp/" + group + "___" + name + ".js";
        console.log("alias:", alias, alias === "OTLADKA.BP_EXAMPLE");
        if (alias === "OTLADKA.BP_EXAMPLE") {
            url = "/static/js/bp_example.js";
        }
        url += "?t=" + new Date().getTime()
        document._DEBUG(5, "[ BP ] :", group, "/", name, "args:", args);
        document._DEBUG(7, "-> bp URL:", url);

        let promise = MakePromise();

        LOAD.load_script(url, async () => {
            let $ = PrepareEngine(this, {
                _args: args
            }, url);
            let bp_func = document.kompas.get_bp_group(group).get_bp(name);
            let _result = {result: null, error: null}
            if (bp_func) {
                try {
                    _result.result = await bp_func($);
                    console.log("run_bp finished!!!!!!!!!!!!!!!!!!!>>");
                    if (this.component != null &&
                            this.component.state.choiceFiles !== undefined && this.component.state.choiceFiles.status>0) {
                        this.component.setState({choiceFiles: {status: 0}});
                    }
                } catch (e) {
                    console.error(e);
                    _result.error = e;
                    promise.ctrl.resolve(_result);
                    return
                }
            } else {
                console.warn("No BP func:", group, "/", name);
            }
            promise.ctrl.resolve(_result);
        }, () => {
            //notify.showNotify(1, notifyContainer, 1, "Привет, мир! Это тост-сообщение. ееееееееееееееееееееееееееееееееееееееееееее");
            console.log("Cant load BP:", alias);
            promise.ctrl.resolve({});
        })

        return promise;
    }

    run_func = async (func, args) => {
        let $ = PrepareEngine(this, {
            _args: args
        }, null);
        return func($);
    }

    _init_engine = (args, url) => {
        let $ = PrepareEngine(this, {
            _args: args
        }, url);
        return $;
    }

}

class BpGroup {

    constructor() {
        this.bp_dict = {}
    }

    add_bp = (bp_name, func) => {
        this.bp_dict[bp_name] = func;
    }

    get_bp = (bp_name) => {
        return this.bp_dict[bp_name];
    }

}

class ListBox {
}

class KObject {

    constructor(engine, name, debug) {
        this.engine = engine;
        this.name = name;
        this.debug = debug;

        Object.defineProperty(this, "value", {
            get: () => {

                if (this.name === "ArgCount") {
                    return this.engine.$._args.length;
                } else if (this.name === "КОД_ПОЛЬЗОВАТЕЛЯ") {
                    return this.engine.$.КОД_ПОЛЬЗОВАТЕЛЯ;
                } else if (this.name === "ARG") {
                    return this.engine.$._args;
                } else if (this.name === "NOW") {
                    return getNow();
                }

                let found = this._findFieldNum();
                let data = null;
                if (found >= 0) {
                    let cur_row = this.engine.component.state.curCell.row;// - 0;
                    let data_row = this.engine.component.rowByKey(cur_row);
                    if (data_row != null) {
                        data = data_row.row[found];
                    }
                }

                if (data != null) {
                    return data;
                }

                document._DEBUG(5, "not found (val@val). Name: " + this.name + " (" + this.debug + ")");
                return null;
            },
            set: (val) => {
                //document._DEBUG(5, "not implemented (val@val = ...) (" + this.debug + ")");

                let found_col = this._findFieldNum();
                document._DEBUG(5, "found (val@val). Name: " + this.name + " (found=", found_col, ") set:", val);
                if (found_col >= 0) {
                    let cur_row = this.engine.component.state.curCell.row;// - 0;
                    // let data_row = this.engine.component.rowByKey(cur_row);
                    // document._DEBUG(5, "found (val@val). data_row: ", data_row);
                    // if (data_row != null) {
                    //     data_row.row[found] = val;
                    //     this.engine.component.setState({
                    //         _some_action: this.engine.component.state._some_action + 1
                    //     })
                    // }
                    //let data_row = this.engine.component.rowByKey(cur_row);
                    //let last_val = data_row.row[found_col];
                    let keyFields = {};
                    //keyFields[this.name] = val;
                    this.engine.component.tfEditRows.onChangeCellValue(cur_row, found_col, val);
                    this.engine.component.onSaveSFieldBase(cur_row, null, val, found_col, keyFields, null/*Restrict.rWarning*/, null);
                    document._DEBUG(5, "...and now [tf]:", this.engine.component.state.tf);
                }
            }
        });
    }

    getFieldName = () => {
        let found = this._findFieldNum();
        if (found >= 0) {
            return this.engine.component.state.tf.info.Columns[found].FieldName;
        }
        return "";
    }

    getExists = () => {
        return this._findFieldNum() >= 0;
    }

    _setVisibleValue = (val) => {
        document._DEBUG(5, "_setVisibleValue CO:", this.engine.component);
        let found = this._findFieldNum();
        document._DEBUG(5, "found (val@val). Name: " + this.name + " (found=", found, ") val:", val);
        if (found >= 0) {
            if (this.engine.component.state.curCell == null) {
                if (this.engine.component.ScreenFields != null) {
                    let field = this.engine.component.ScreenFields[found];
                    document._DEBUG(5, "  >>>", field, "val:", val);
                    let e = {target: {value: val}, nativeEvent: {}}
                    field.handleInput(e);
                }
                return;
            }
            let cur_row = this.engine.component.state.curCell.row;// - 0;
            let data_row = this.engine.component.rowByKey(cur_row);
            document._DEBUG(5, "found (val@val). data_row: ", data_row);
            if (data_row != null) {
                data_row.row[found] = val;
                this.engine.component.setState({
                    _some_action: this.engine.component.state._some_action + 1
                })
            }
        }
    }

    _findFieldNum = () => {
        let co = this.engine.component;
        if (!co) {
            return -1;
        }
        let columns = co.state.tf.info.Columns;

        let field_name = null;
        if (this.name.indexOf("_FIELDS@") === 0) {
            field_name = this.name.substring(8, this.name.length);
        } else if (this.name.indexOf("@") < 0) {
            field_name = this.name;
        }

        let found = -1;
        for (let i=0; i<columns.length; i++) {
            let col = columns[i];
            if (field_name) {
                if (col.FieldName === field_name) {
                    found = i;
                    break;
                }
            } else {
                if (col.Mesto) {
                    console.log("COL[", i, "]:", col.Mesto, ":", col);
                }
                if (this.name === col.Mesto) {
                    found = i;
                    break;
                }
            }
        }

        if (found < 0) {
            console.log("not found. But columns:", columns, "Mesto:", this.name);
        }

        return found;
    }
}

class BP_period {
}

class BP_Config {
    constructor(name) {
        this.name = name;
        this._value = null;
        this._value_got = false;

        Object.defineProperty(this, "value", {
            get: async () => {
                let promise = MakePromise();
                if (this._value_got)
                    return this._value;
                let xhr = LOAD.get_config(this.name, () => {
                    document._DEBUG(5, "Answer:", xhr.response);
                    if (xhr.status === 200) {
                        var objVal = JSON.parse(xhr.response);
                        promise.ctrl.resolve(objVal.value);
                        return
                    }
                    promise.ctrl.resolve();
                }, (e) => {
                    document._DEBUG(1, "Error:", e, xhr.response);
                    promise.ctrl.resolve();
                });
                return promise;
            },
            set: (val) => {
                document._DEBUG(5, "not implemented (bp_config.value = ...)");
            }
        });
    }
}
/*
$.tblaliasbytf = async (параметр) => {//notImplemented("TblAliasByTF");
        let promise = MakePromise();
        let tfalias = параметр;
        let xhr = LOAD.get_tf_info(tfalias, ()=>{
            if (xhr.status === 200) {
                var objTf = JSON.parse(xhr.response);
                console.log("TF:", objTf.info.TblAlias);
                promise.ctrl.resolve(objTf.info.TblAlias);
                return
            }
            promise.ctrl.resolve();
        }, ()=>{
            console.warn("ERROR...");
            promise.ctrl.resolve();
        });
        //throw new Error("TF: tblaliasbytf");
        return promise;
    }
*/

const PrepareEngine = (bp_lang, $, url) => {

    let component = bp_lang.component;
    let app = bp_lang.app;
    let _engine = {
        app: app,
        component: component,
        $: $,
        url: url,
    }
    $._engine = _engine;

    $.object = (name, debug) => {
        return new KObject(_engine, name, debug);
    }

    const get_curRow = () => {
        if (!_engine.component || _engine.component.getCurRowData == null) {
            return null;
        }
        return _engine.component.getCurRowData()
        // let cur_row = _engine.component.state.curCell.row;// - 0;
        // let data_row = _engine.component.rowByKey(cur_row);
        // if (data_row && data_row.row) {
        //     return data_row;
        // }
        // return null
    }

    const get_curPole = () => {
        let data_row = get_curRow();
        if (data_row == null)
            return null
        let cur_col = _engine.component.state.curCell.columnKey;// - 0;
        return data_row.row[cur_col];
    }

    // It is for us
    $._get_curRow = get_curRow;

    // Сущности ЯБП

    $.ТЕКПОЛЕ = get_curPole();
    $.КОД_ПОЛЬЗОВАТЕЛЯ = app.state.user_login;

    // Функции ффода/вывода (возвращают число)

    $.askchoice = async (заголовок, текст, варианты) => {
        //let v_lst = варианты.split("\n");
        let comp = (component!=null) ? component : app;

        let result = await openDialogVariants(comp, заголовок, текст, варианты);

        /*let promise = MakePromise();

        comp.setState({choice_prm: [заголовок, текст, v_lst, promise]});*/

        return result;
    } // #gizs

    $.batchmove = (получатель, набор_данных, флаг) => {notImplemented("BatchMove");} // #sql
    $.execcmd = (программа, параметры, режим) => {notImplemented("ExecCmd");} // #OS
    $.execquery = (запрос) => {notImplemented("ExecQuery");} // #sql
    $.formatimpexp = (набор_данных, флаг1, конфиграция, флаг2, заголовок, признак, имя_файла) => {notImplemented("FormatImpExp");}
    $.input = (строка, переменная) => {return window.prompt(строка, переменная);}
    $.inputval = (значение) => {
        //let tip = typeof(значение);
        let constructor = значение.constructor;
        return new constructor(window.prompt(значение));
    }

    $.mail = (получатель, приложение, тема, содержание) => {notImplemented("Mail");}
    $.messagebox = (заголовок, сообщение, режим_вывода_сообжения) => {
        let answ = window.confirm(заголовок + "\n" + сообщение);
        return answ;
    }
    $.print = (список) => {alert(список);/* FIXME */}
    $.spawn = (программа, параметры, признак) => {notImplemented("Spawn");} // #OS FIXME вызывает внешнюю программу
    $.waitbox = (строка, строка2) => {notImplemented("WaitBox");} // #gizs
    $.waitbox2 = (строка) => {notImplemented("WaitBox2");} // #gizs
    $.abs = (число) => {return Math.abs(число);}
    $.cos = (число) => {return Math.cos(число);}
    $.decround = (число, дес) => {
        let mnoz = Math.pow(10, дес);
        return Math.round((число + Number.EPSILON) * mnoz) / mnoz;
    }
    $.fix = (число) => {return Math.floor(число);/*FIXME проверить оригинал!!!*/}
    $.int = (число) => {return Math.ceil(число);/*FIXME проверить оригинал!!!*/}
    $.length = (массив) => {return массив.length;}
    //$.Length = (строка) => {}
    $.pos = (строка1, строка2) => {return строка1.indexOf(строка2);}
    $.md = (число) => {return Math.floor(Math.random() * число);}
    $.round = (число) => {return Math.round(число);/*FIXME проверить оригинал!!!*/}
    $.sgn = (число) => {return число < 0 ? -1 : (число === 0 ? 0 : 1);}
    $.sin = (число) => {return Math.sin(число);}
    $.strtoint = (строка) => {return строка - 0;}
    $.val = (строка) => {return строка - 0;} /*AAA FIXME проверить оригинал!!!*/

    // Строковые функции (возвращают текст)

    $.char = (число) => {return String.fromCharCode(число);}
    $.createguid = () => {return generateUUID();}
    $.date2sql = (дата, флаг) => {
        let dt = дата.toISOString().slice(0, 19).replace('T', ' '); // FIXME простое не очень правильное решение
        if (флаг) {
            dt = "'" + dt + "'";
        }
        return dt;
    }
    $.formatfloat = (Формат, Число) => {notImplemented("FormatFloat");} // #ASK где описание формата?
    $.left = (строка, число) => {return строка.substring(0, число);}
    $.lower = (строка) => {return строка.toLowerCase();}
    $.ltrim = (строка) => {return строка.trimLeft();}
    $.parsedelim = (строка1, строка2, число) => {
        let lst = строка1.split(строка2);
        if (число >= 0 && число < lst.length) {
            return lst[число];
        }
        return "";
    }
    $.randomstring = (число) => {return makeid(число);/*FIXME: чуть чуть не то*/}
    $.right = (строка, число) => {return строка.substring(строка.length-число, строка.length);}
    $.rtrim = (строка) => {return строка.trimRight();}
    $.str = (число) => {return число + "";}
    $.strencode = () => {notImplemented("StrEncode");} // #ASK где описание функции?
    $.strdecode = () => {notImplemented("StrDecode");} // #ASK где описание функции?
    $.string2sql = (строка, флаг) => {
        let s = строка.replaceAll("'", "''");
        if (флаг) {
            s = "'" + s + "'";
        }
        return s;
    }
    $.substring = (строка, число1, число2) => {return строка.substring(число1, число2);}
    $.trim = (строка) => {return строка.trim();}
    $.upper = (строка) => {return строка.toUpperCase();}

    // Функции для работы с датами

    $.ctod = () => {return null;} //FIXME нет описания этой функции
    $.bp_period = () => {return new BP_period();}
    $.dateformat = (строка, дата) => {notImplemented("DateFormat");} // #ASK где описание функции?
    $.dateserial = (день, месяц, год) => {return new Date(год, месяц, день);}
    $.daysinperiod = (дата1, дата2, номер) => {
        if (номер) {
            notImplemented("DaysInPeriod with номер");
        }
        return Math.ceil(Math.abs(дата2.getTime() - дата1.getTime()) / (1000 * 3600 * 24));
    }
    $.now = () => {return new Date(Date.now());}
    $.periodpart = (дата1, дата2, номер) => {
        switch (номер) {
            case 3:
                var diffDays = дата2.getDate() - дата1.getDate();
                return diffDays;
            case 2:
                var diffMonths = дата2.getMonth() - дата1.getMonth();
                return diffMonths;
            case 1:
                var diffYears = дата2.getFullYear() - дата1.getFullYear();
                return diffYears;
            default:
                break;
        }
        return 0;
    }
    $.tstrgm = (параметр1, параметр2, параметр3) => {
        //ггггмм
        if (typeof параметр1 !== "string" || параметр1.length !== 4)
            return false
        let ys = параметр1.substring(0, 4);
        let ms = параметр1.substring(4, 6);
        let timestamp = Date.parse(ys + "-" + ms + "-01");
        let ok = isNaN(timestamp) === false;
        return ok;
    }

    // Функции для работы с объектами

    $.askgod = async (параметры) => {
        let input;
        let promise = MakePromise();
        if (параметры == null) {
            input = [1, ''];
        } else  {
            input = [1, параметры];
        }
        component.setState({askRgmGod: {input: input, promise: promise}});

        return promise;
    } // #gizs
    $.askrgm = async (параметры) => {
        let input;
        let promise = MakePromise();
        if (параметры == null) {
            input = [0, ''];
        }  else  {
            input = [0, параметры];
        }
        component.setState({askRgmGod: {input: input, promise: promise}});

        return promise;
    } // #gizs
    $.bp_config = (имя, параметры) => {return new BP_Config(имя);}
    $.call = async (имя_процедуры, ...args) => {
        let full_args = [имя_процедуры];
        for (let i=0; i<args.length; i++) {
            full_args.push(args[i]);
        }
        let _result = await bp_lang.run_bp(имя_процедуры, full_args);
        if (_result.error) {
            throw new Error(имя_процедуры);
        }
        return _result.result;
    }
    $.clearins = () => {notImplemented("ClearIns");} // #ASK где отмеченные строки? в ТФ?
    $.connect = (параметр) => {notImplemented("Connect");} // FIXME other DB !!!
    $.createtable = (псевдоним, признак1, признак2) => {notImplemented("CreateTable");} // #sql
    $.enumerateid = (параметры) => {notImplemented("EnumerateID");}
    $.evaluate = () => {notImplemented("Evaluate");}
    $.excludepathfromfilename = (имя_папки, полное_имя_файла) => {notImplemented("ExcludePathFromFileName");}
    $.fieldbymesto = (таблица, мом) => {
        let obj = new KObject(this, мом); // FIXME нужно с именем таблицы
        return obj.getFieldName();
    }
    $.filedelete = (файл) => {notImplemented("FileDelete");} // #OS
    $.fileexists = (файл) => {notImplemented("FileExists");} // #OS
    $.fillbplist = (параметр_1, параметр_2) => {notImplemented("FillBPList");} // #ASK нет описания параметров!
    $.findelmom = (параметр) => {
        let obj = new KObject(this, параметр);
        return obj.getExists();
    }
    $.genuniqfilename = (путь_к_файлу) => {notImplemented("GenUniqFileName");} // #OS
    $.getabsencelist = (параметры) => {notImplemented("GetAbsenceList");} // #KADRY
    $.getcomputername = () => {notImplemented("GetComputerName");} // #OS
    $.getdir = (путь_к_исходной_папке, текст_сообщения_на_форме_выбора) => {notImplemented("GetDir");} // #OS
    $.getelmom = (мом) => { // ASK нет описания параметров!!!
        let obj = new KObject(this, мом);
        return obj.value;
    }
    $.getotpostandfill = (параметры) => {notImplemented("GetOtpOstAndFill");} // #KADRY #ZRP
    $.getuserfilter = (префикс_таблицы_в_SQL_выражении) => {notImplemented("GetUserFilter");}
    $.inserttoexcel = () => {notImplemented("InsertToExcel");}
    $.inscount = () => {notImplemented("InsCount");}
    $.isfloat = (строка) => {return isStrFloat(строка);}
    $.isint = (строка) => {return isStrInt(строка);}
    $.listbox = () => {return new ListBox();}
    $.lockopers = () => {notImplemented("LockOpers");} // #ASK не достаточное описание
    $.normativ = (параметры) => {notImplemented("Normativ");} // #KADRY
    $.opendialog = async (title, filename, defaultExt, initialDir, filter) =>{
        let input;
        let promise = MakePromise();
        input = [title, filename, defaultExt, initialDir, filter];
        let prevCounterCall = (component.state.choiceFiles != null && component.state.choiceFiles.status>0) ? component.state.choiceFiles.counterCall : 0;

        component.setState({choiceFiles: {input: input, status: 1, counterCall: prevCounterCall+1, promise: promise}});

        return promise;
    }

    $.postif = (имя_поля) => {notImplemented("PostIf");}
    $.printreport = (псевдоним, фильтр, параметры, формат, макропараметры) => {notImplemented("PrintReport");}
    $.qform = (псевдоним, параметры) => {notImplemented("QForm");}
    $.qformlive = (псевдоним, параметры) => {notImplemented("QFormLive");}
    $.qfwizard = () => {notImplemented("QFWizard");}
    $.reestr = (тип_документа, фильтр, режим) => {notImplemented("Reestr");}
    $.refreshcache = (псевдоним_таблицы) => {notImplemented("RefreshCache");}
    $.report = () => {notImplemented("Report");}
    $.resrictvalue = (параметр) => {notImplemented("ResrictValue");}
    $.restrictfield = (параметр) => {notImplemented("RestrictField");}
    $.runquery = (псевдоним, параметры) => {notImplemented("RunQuery");} // #sql
    $.runsql = async (Текст_Sql_запроса, __параметры) => {
        return (new Sql(_engine, Текст_Sql_запроса, __параметры)).run();
    }
    //$.RunSQL = (псевдоним, параметры) => {}
    $.rv_otdel = () => {notImplemented("RV_OTDEL");}
    $.savedialog = () => {notImplemented("SaveDialog");}
    $.selectfield = (параметры) => {notImplemented("SelectField");}
    $.selectpf = () => {notImplemented("SelectPF");}
    $.servertemptable = (псевдоним) => {notImplemented("ServerTempTable");}
    $.setconfigparam = (параметры) => {notImplemented("SetConfigParam");}
    $.setelmom = () => {notImplemented("SetELMOM");}
    $.setnull = () => {notImplemented("SetNULL");}
    $.show = (форма) => {notImplemented("Show");}
    $.showlookup = (форма) => {notImplemented("ShowLookup");}
    $.showmodal = (форма) => {return $.tform(форма).showmodal();}
    $.sleep = (число) => {return _sleep(число);}
    $.sql = async (id, __параметры) => {
        document._DEBUG(5, "[$.sql]", id, "params:", __параметры);
        return (new Sql(_engine, id, __параметры)).run();
    }
    //$.SQL = (псевдоним, параметры) => {}
    $.sqlpost = () => {notImplemented("SQLPost");}
    $.syslog = (название_события, текст_сообщения_с_описанием_события, Дополнительная_информация) => {notImplemented("SysLog");}
    $.tableexist = (таблица) => {notImplemented("TableExist");}
    $.tableversion = (таблица) => {notImplemented("TableVersion");}
    $.tblaliasbytf = async (параметр) => {//notImplemented("TblAliasByTF");
        let promise = MakePromise();
        let tfalias = параметр;
        let xhr = LOAD.get_tf_info(tfalias, ()=>{
            if (xhr.status === 200) {
                var objTf = JSON.parse(xhr.response);
                console.log("TF:", objTf.info.TblAlias);
                promise.ctrl.resolve(objTf.info.TblAlias);
                return
            }
            promise.ctrl.resolve();
        }, ()=>{
            console.warn("ERROR...");
            promise.ctrl.resolve();
        });
        //throw new Error("TF: tblaliasbytf");
        return promise;
    }
    $.tform = (псевдоним_ТФ, тип_документа, фильтр, режим) => {
        return new TForm(_engine, component, псевдоним_ТФ, тип_документа, фильтр, режим);
    }
    $.tfwizard = () => {notImplemented("TFWizard");}
    $.throwexception = () => {notImplemented("ThrowException");}
    $.tlookupform = (псевдоним_ТФ, тип_документа, фильтр, режим) => {notImplemented("TLookupForm");}
    $.use = (объект) => {notImplemented("Use");}
    $.viewdoks = () => {notImplemented("ViewDoks");}
    $.xfile = (таблица, ключевое_поле, значение_ключевого_поля) => {notImplemented("XFile");}
    $.xfileex = (параметры) => {notImplemented("XFileEx");}
    $.xrefresh = (псевдоним) => {notImplemented("xRefresh");}
    $.xml = (параметры) => {notImplemented("XML");}

    // $._web_refresh_tf = (row_id) => {
    //     component
    // }

    return $;
}

function getNow() {
    let now = new Date();
    return now.getDate() + "." + (now.getMonth()+1) + "." + now.getFullYear() + " " + now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();
}

function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        //return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        return (c === 'x' ? r : (r & (0x3 | 0x8))).toString(16);
    });
}

function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() *
 charactersLength));
   }
   return result;
}

function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

// function isFloat(n){
//     return Number(n) === n && n % 1 !== 0;
// }

// function isInt(n) {
//     return Number.isInteger(n);
// }

function isStrFloat(val) {
    var floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
    if (!floatRegex.test(val))
        return false;

    val = parseFloat(val);
    if (isNaN(val))
        return false;
    return true;
}

function isStrInt(val) {
    var intRegex = /^-?\d+$/;
    if (!intRegex.test(val))
        return false;

    var intVal = parseInt(val, 10);
    return parseFloat(val) === intVal && !isNaN(intVal);
}
