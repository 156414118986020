import { createContext } from "react";

export const TfContext = createContext();

export function cloneTFdata(data) {
    let copy = [];
    for (let i in data) {
        copy[i] = {key: {}, row: []};
        Object.assign(copy[i].key, data[i].key);
        copy[i].row =  data[i].row.slice();
    }
    return copy;
}
