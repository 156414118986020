import React from 'react';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
//import { BpLang } from '../bp_lang/bp_lang';
import { processKeyEvent } from '../common/helpers';
import { MenuBar } from "./menubar.js";
import { AppContext } from "../common/app_context.js";

export class GenerateMenu extends React.Component {
    static contextType = AppContext

    constructor(props) {
        super(props);
        this.keys = {F1: "\\?\\Вызов справки"};
        this.contextMenu = [];
        this.contextItems = {};
        this.iKeyAttr = [];

    }

    handleClickMenuItem(e) {
        if (e.target.disabled)
            return;
        $('.navbar-collapse').collapse('hide');
        if (e.target.dataset.script) {
            let BPName = e.target.dataset.script;
            console.log("SCRIPT:", BPName);
            this.props.app.bpCall.call_bp(BPName);
            return;
        }
        this.props.handleClickMenuItem([e.target.dataset.tfalias, e.target.dataset.tftitle]);
    }

    onAppKeydown = (e) => {
        return processKeyEvent(e, this.keys);
    }

    onAppKeyUp = (e) => {
        return processKeyEvent(e, this.keys);
    }

    generateMenu(menu){
        let items = this.circleInMenu(menu["MNUADM"], 0);
        return items;
    }

    circleInMenu = (arr, level) => {
        let listItems = [];
        let listItemsAdd = null;
        let contextItems = {};
        let canAddDivider = false;
        let i = 0;

        if (!arr)
            return [[], []];

        for (let obj of arr){
            let caption = "";
            let index;
            let flagSub = false;
            let subArr = [];
            let tfa = "";
            let ScriptAlias = "";
            let ToolBarCode = "";
            let Hidden = false;
            let shortCut = "";
            for (let key in obj){
                switch (key) {
                    case "Caption":
                        caption = obj[key];
                        if (obj[key]==="Вызов справки") shortCut = "F1";
                        break
                    case "ItemCode":
                        index = obj[key];
                        break
                    case "TfAlias":
                        tfa = obj[key];
                        break
                    case "ShortCut":
                        //console.log("ShortCut >> ", obj.FullPath);
                        let k = obj[key];
                        this.keys[k] = obj.FullPath;
                        shortCut = obj[key];
                        break
                    case "SubMenuItems":
                        flagSub = true;
                        subArr = obj[key];
                        break
                    case "ScriptAlias":
                        ScriptAlias = obj[key].split("[")[0].trim();
                        break
                    case  "ToolBarCode":
                        ToolBarCode = obj[key];
                        break
                    case "Hidden":
                        Hidden = obj[key];
                        break
                    default:
                        break
                }
            }


            if (!flagSub) {
                if (caption === "-" && !Hidden) {
                    if (canAddDivider) {
                        canAddDivider = false;
                        let ckey = "sep" + index;
                        if (!Hidden) contextItems[ckey] = "---------";
                    }
                } else {
                    if (ToolBarCode!==-1)  this.menuBarArray.push({tfalias: tfa, script:  ScriptAlias, tftitle: caption, ItemCode: index });
                    if (!Hidden) {
                        let disableLink = (tfa === "" && ScriptAlias === "") ? "disabled" : "";
                        let elem_cls = "nav-link py-1 " + disableLink;
                        let li_elem_cls1 = "nav-item";
                        let elem = (
                            <li key={index} className={li_elem_cls1}>
                                <a className={elem_cls} style={{display: "flex",  flexDirection: "row",  justifyContent: "space-between"}}
                                    onClick={this.handleClickMenuItem.bind(this)} data-tfalias={tfa} data-script={ScriptAlias} data-tftitle={caption}
                                    href={0/*() => false*/}
                                >
                                    {caption}
                                    <span  style={{position: 'absolute', right: '7px'}}>{shortCut}</span>
                                </a>
                            </li>
                        );
                        i++;
                        if (level === 0)  listItems.push(elem);
                        canAddDivider = true;
                        if (level>0) {
                            let props = this.props;
                            let iKeyAttr =this.iKeyAttr;
                            this.iKeyAttr[index] = {
                                tfalias: tfa,
                                script:  ScriptAlias,
                                tftitle: caption
                            };
                            contextItems[index] = {
                                name: caption,
                                disabled: (tfa === "" && ScriptAlias === ""),
                                callback: function(itemKey, opt, e){
                                    if (iKeyAttr[itemKey].script!=='') {
                                        let BPName = iKeyAttr[itemKey].script;
                                        props.app.bpCall.call_bp(BPName);
                                        return true;
                                    }
                                    props.handleClickMenuItem([iKeyAttr[itemKey].tfalias, iKeyAttr[itemKey].tftitle]);
                                    return true;
                                }
                            }
                        }
                        i++;
                    }
                }
            }
            else {
                if (level === 0 && i > 7 && (!Hidden)) {
                    if (listItemsAdd == null) {
                        listItemsAdd = [];
                    }
                    listItemsAdd.push(obj);
                    continue;
                }
                let elem;
                if (level === 0) {
                    elem = this.addSubMenu(Hidden, {subArr, level, caption, shortCut, index});
                    if (!Hidden)    {
                        this.contextItems ={selector: '.mmenu-'+index, items: {}};
                        this.contextItems.items = elem[1];
                        this.contextMenu.push(this.contextItems);
                        this.contextItems = {};
                    }

                } else {
                    elem = this.addSubMenu(Hidden, {subArr, level, caption, shortCut, index});
                    if (!Hidden)  {
                        contextItems[index] = {
                            name: caption,
                            items : elem[1]
                        }
                    }

                }
                if (level === 0 && (!Hidden))  {
                    listItems.push(elem[0]);
                    canAddDivider = true;
                    i++;
                }
            }
        }

        if (listItemsAdd != null) {

            let elem = this.addSubMenu(false, {subArr: listItemsAdd, level, caption: "...", shortCut: "", index: "..."});
            this.contextItems = {
                selector: '.mmenu-more',
                items: {}
            };
            this.contextItems.items = elem[1];
            this.contextMenu.push(this.contextItems);
            this.contextItems = {};
            listItems.push(elem[0]);
        }

        return [listItems, contextItems];
    }

    addSubMenu = (Hidden, {subArr, level, caption, shortCut, index}) => {
        let subItems = this.circleInMenu(subArr, level+1);
        if (!Hidden) {
            let prefix = (index==="...") ? "more" : "" + index;
            let li_elem_cls ="nav-item dropdown";
            let elem_cls = "nav-link dropdown-toggle py-1";
            let trigger_cls = " mmenu-"+prefix;
            elem_cls += trigger_cls;
            let elem_a = (level === 0) ? (
                <a className={elem_cls} role="button" href={0/*() => false*/} >
                    {caption}
                </a>
            ) : null;
            //let ul_cls = "dropdown-menu hmenu";
            let elem = (level === 0) ? (
                <li key={index} className={li_elem_cls}  >
                    {elem_a}
                </li>
            )  : null;
            return [elem, subItems[1]];
        }
        else {
            return [null, subItems[1]];
        }
    }

    componentDidMount() {
        for (let obj of this.contextMenu){
            $.contextMenu( 'destroy', obj.selector);
            if (!obj.items || Object.keys(obj.items).length === 0)
                continue
            $.contextMenu({
                selector: obj.selector,
                trigger: 'left',
                position: function(opt, x, y){
                    let left = document.querySelector(obj.selector).getBoundingClientRect().left;
                    let right = document.querySelector(obj.selector).getBoundingClientRect().right;
                    if (right<$(window).width()*0.8 || $(window).width()<992)    opt.$menu.css({top: 45, left: left});
                    else  opt.$menu.css({top: 45, right: $(window).width()-right});
            },
                items: obj.items
            });
        }
        $.contextMenu( 'destroy', '.mmenu-user');
        //let items_user;
        let props = this.props;
        let logout = {
            name: 'Выйти',
            callback: function(itemKey, opt, e){
                (props.onClickLogOut)();
                return true;
            }
        }
        let common_items = null;
        if (this.props.menu.disable_configure) {
            common_items = {
                logout: logout,
            }
        } else {
            let setting = {
                name: 'Настройка метаданных',
                disabled: (!this.props.adminFlag),
                callback: function(itemKey, opt, e){
                    (props.handleClickGotoSetting)();
                    return true;
                }
            }
            common_items = {
                logout: logout,
                set: setting
            }
        }
        $.contextMenu({
            selector: '.mmenu-user',
            trigger: 'left',
            position: function(opt, x, y){
                let left = document.querySelector('.mmenu-user').getBoundingClientRect().left;
                let right = document.querySelector('.mmenu-user').getBoundingClientRect().right;
                if (right<$(window).width()*0.8 || $(window).width()<992)    opt.$menu.css({top: 45, left: left});
                else  opt.$menu.css({top: 45, right: $(window).width()-right});
            },
            items: common_items
        });
    }

    render() {
        this.menuBarArray = [];
        let items = this.generateMenu(this.props.menu);

        return (
        <>
            <nav className="navbar navbar-expand-lg fixed-top navbar-light bg-light border-bottom unselectable" >
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        {items[0]}
                        <li className="nav-item dropdown py-0">
                            <a className="nav-link dropdown-toggle kps-link mmenu-user py-1" role="button" _data-toggle="dropdown" _aria-haspopup="true" _aria-expanded="false"
                                href={0/*() => false*/}
                            >
                                <FontAwesomeIcon icon={faUser} title = {this.props.user} />
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
            < MenuBar  app = {this.props.app} btns = {this.menuBarArray} />
        </>
        );
    }
}