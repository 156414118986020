
import React from "react";
import $ from 'jquery';
import nextId from "react-id-generator";
import { LOAD } from "../common/load";
import { md5 } from  '../common/md5.js';

export const LoginLevels = {
    INVALID_LPA_LV: -2, //некорректный логин или пароль авторизации
    INVALID_LOGIN_AUTH_LV: -3, //некорректный логин пароль авторизации
    INVALID_PASS_AUTH_LV: -4, //некорректный пароль авторизации
    NO_LPA_LV: -1,      //логин или пароль авторизации не введены
    SIGN_LV: 0,         // форма авторизации
    POST_LPA_LV: 1,         // отправка данных авторизации серверу
    USER_LV: 2,         // получение ответа на отправку данных авторизации от сервера
    SIGN_FIRST_LV: 7,     // первый старт (форма авторизации)
    SETTING_LV: 8,        // страница настроек
}

//id для отображения формы авторизации
const idSignLogin  = nextId("sign_");
const idSignPswrd  = nextId("sign_");


export class Login extends React.Component {

    handleChangeLevelSign = () => {
        var xhr = this.SendSignD(this.handleSignInAnswer.bind(this), this.handleSignInError);
        this.xhr = xhr;
        console.log("xhr -->", xhr);
        let lev = LoginLevels.SIGN_LV;
        // FIXME @gizs: что это вообще такое ниже за проверка? В оригинале выглядела так:
        // if (xhr!==SIGN_LV && xhr!==INVALID_LPA_LV && xhr!==INVALID_LOGIN_AUTH_LV && xhr!==INVALID_PASS_AUTH_LV && xhr!==NO_LPA_LV)  {
        if (xhr!==LoginLevels.SIGN_LV && xhr!==LoginLevels.INVALID_LPA_LV && xhr!==LoginLevels.INVALID_LOGIN_AUTH_LV && xhr!==LoginLevels.INVALID_PASS_AUTH_LV && xhr!==LoginLevels.NO_LPA_LV) {
            lev = LoginLevels.POST_LPA_LV;
        } else
            lev = xhr;
        this.props.app.setState({
            level: lev,
        });
    }

    SendSignD = (callback, err_callback) => {
        let login = $("#" + idSignLogin).val();
        let pswrd = $("#" + idSignPswrd).val();
        let pswrdSend = md5(pswrd);
        pswrdSend = pswrdSend.toUpperCase();
        if (login==="" || pswrd==="") {
            return LoginLevels.NO_LPA_LV;
        }
        let data = {
            "login": login,
            "password": pswrdSend
        }

        let xhr = LOAD.login(data, callback, err_callback);

        return xhr;
    }

    handleSignInAnswer() {
        let xhr = this.xhr;
        let lev = LoginLevels.SIGN_LV;
        let user = "";
        let user_login = "";
        // результат запроса
        if (xhr.status === 200) {
            var aresponse = JSON.parse(xhr.response);
            document._DEBUG(5, "result --> ", aresponse);
            console.log( "status --> ", aresponse.status);
            if (aresponse.status===200 && !this.props.app.state.firstStart)   {
                lev = LoginLevels.USER_LV;
                user = aresponse.fio;
                user_login = aresponse.login;
                this.props.app.setState({
                    level: lev,
                    user: user,
                    user_login: user_login,
                });
                (this.props.app.reqStatusSrv.bind(this))();
            }
            if (aresponse.status===200 && this.props.app.state.firstStart)   {
                lev = LoginLevels.SETTING_LV;
                user = aresponse.fio;
                user_login = aresponse.login;
                this.props.app.setState({
                    level: lev,
                    user: user,
                    user_login: user_login,
                });
            }
        }
        else if(xhr.status === 403) {
            this.clearLoginPassAndSetState(LoginLevels.INVALID_PASS_AUTH_LV, user, user_login);
        } else if(xhr.status === 404) {
            this.clearLoginPassAndSetState(LoginLevels.INVALID_LOGIN_AUTH_LV, user, user_login);
        }
    }

    handleSignInError = () => {
        this.clearLoginPassAndSetState(LoginLevels.INVALID_LOGIN_AUTH_LV, -1, "");
    }

    clearLoginPassAndSetState = (new_level, user, user_login) => {
        $("#" + idSignLogin).val("");
        $("#" + idSignPswrd).val("");
        this.props.app.setState({
            level: new_level,
            user: user,
            user_login: user_login,
        });
    }

    render() {
        let errorText = null;

        switch (this.props.level) {
            case LoginLevels.INVALID_LPA_LV:
                errorText = "Неверный логин или пароль!";
                break
            case LoginLevels.INVALID_LOGIN_AUTH_LV:
                errorText = "Неверный логин!";
                break
            case LoginLevels.INVALID_PASS_AUTH_LV:
                errorText = "Неверный пароль!";
                break
            case LoginLevels.NO_LPA_LV:
                errorText = "Вы не ввели данные для авторизации!";
                break
            case LoginLevels.SIGN_FIRST_LV:
                errorText = "Первый старт (Get started)";
                break
            default:
                errorText = null;
                break
        }

        return (
            <div className="autorization">
                <SignIn
                    onClick = {this.handleChangeLevelSign}
                    error = {errorText} />
            </div>
        )
    }
}


const SignIn = (props) => {
    let error_div = props.error ? (
        <InfString text={props.error}/>
    ) : null;

  let handleKeyPress = function(e) {
    console.log("e:", e);
    if (e.charCode === 13)
        props.onClick();
  }

  return (
    <form className="form-signin">
    <h1 className="h3 mb-3 fw-normal">Войдите в систему</h1>
    {error_div}
    <div className="form-floating mt-3">
      <label htmlFor={idSignLogin}>Имя пользователя</label>
      <input type="email" className="form-control input-email" id={idSignLogin} placeholder="login"
        onKeyPress = {(e) => handleKeyPress(e)} />
    </div>
    <div className="form-floating mt-3">
      <label htmlFor={idSignPswrd}>Пароль</label>
      <input type="password" className="form-control input-passw" id={idSignPswrd} placeholder="Password"
        onKeyPress = {(e) => handleKeyPress(e)} />
    </div>
    <button onClick={props.onClick} className="w-100 btn btn-lg btn-primary input-submit" type="button">Войти</button>
    </form>
  );
}


function InfString(props)  {
    return (
        <div className="alert alert-danger" role="alert">
            {props.text}
        </div>
    );
}

