import React, { createContext, forwardRef, useContext } from "react";
import { VariableSizeGrid as Grid } from "react-window";
import AutoSizer from 'react-virtualized-auto-sizer';
import { TfRow, TfCurrentRow, TfAddRow, TitleRow, TITLE_ROW_INDEX, ADD_ROW_INDEX } from "./tf_row";
import { TfContext } from "./tf_funcs";


const StickyGridContext = createContext();
StickyGridContext.displayName = "StickyGridContext";


//----------------------------------- WINDOW TYPE DATA SHOW -----------------------------
export const GUTTER_SIZE = 0;
export const COLUMN_WIDTH = 100;
export const CELL_PADDING = 15;
export const LEFT_SPACE = 30;
export const ROW_HEIGHT = 28;
export const MAX_COLS_COUNT = 555;

//const getSize = (index) => 28


export const CreateWindowDataShower = ({tf_component, total, add_row}) => {

    const stickyIndices = add_row == null ? [TITLE_ROW_INDEX,] : [TITLE_ROW_INDEX, ADD_ROW_INDEX];

    return (
        <TfContext.Provider value={{ tf_component, stickyIndices }}>
            <AutoSizer className="auto-sizer">
                {
                    ({ height, width }) => {
                        return (
                            <StickyGrid
                                className="Grid"
                                columnCount={1}
                                height={height}
                                innerElementType={innerElementType}
                                rowCount={total /*+ stickyIndices.length*/}
                                width={width}
                                stickyIndices={stickyIndices}
                                onScroll={scroll => {
                                    if (scroll) {
                                        tf_component.state_scroll = [scroll.scrollLeft, scroll.scrollTop];
                                    }
                                }}
                            >
                                {Row}
                            </StickyGrid>
                        );
                    }
                }
            </AutoSizer>
        </TfContext.Provider>
    );
}

const StickyGrid = ({ children, stickyIndices, ...rest }) => {
    const {tf_component} = useContext(TfContext);
    return (
        <StickyGridContext.Provider value={{ stickyIndices }}>
            <Grid
                itemData={{ ItemRenderer: children, stickyIndices }} {...rest}
                initialScrollLeft={tf_component.state_scroll[0]}
                initialScrollTop={tf_component.state_scroll[1]}
                overscanRowCount={40}
                columnWidth={() => tf_component.getFullWidth()}
                rowHeight={index => tf_component.getRowHeight(index)}
                ref={tf_component.GridRef}
            >
                {ItemWrapper}
            </Grid>
        </StickyGridContext.Provider>
    );
}

const innerElementType = forwardRef(({ children, ...rest }, ref) => {
    const {tf_component} = useContext(TfContext);
    return (
        <StickyGridContext.Consumer>
        {({ stickyIndices }) => (
            <div ref={ref} {...rest} className="GridInner">
                {
                    stickyIndices.map(index => {
                        if (index === ADD_ROW_INDEX)
                            return (
                                <TfAddRow
                                    ref={tf_component.addRowRef}
                                    rowIndex={tf_component.state.curCell.row}
                                    style={{height: 28, top: 200, width: 500}}
                                    is_add_row={true}
                                    data_row={null}
                                    />
                            )
                        return (
                            <TitleRow
                                index={index}
                                key={index}
                                style={{ top: tf_component.getRowHeight(index-10), height: tf_component.getRowHeight(index) }}
                            />
                        )
                    })
                }
                {children}

                <TfCurrentRow
                    ref={tf_component.currentRowRef}
                    rowIndex={tf_component.state.curCell.row}
                    style={{height: 28, top: 200, width: 500}}
                    data_row={null}
                    />

            </div>
        )}
        </StickyGridContext.Consumer>
    )
});

const Row = ({ rowIndex, style }) => {
    const {tf_component, stickyIndices} = useContext(TfContext);

    let rowIndexCalced = rowIndex
    let data_row = tf_component.getRow(rowIndexCalced);

    let add = 0;
    for (let ind of stickyIndices) {
        add += tf_component.getRowHeight(ind);
    }

    return (
        <TfRow tf_component={tf_component} rowIndex={rowIndex} style={{...style, top: style.top + add }} data_row={data_row} />
    )
}

const ItemWrapper = ({ data, columnIndex, rowIndex, style }) => {
    const { ItemRenderer, stickyIndices } = data;
    if (stickyIndices && stickyIndices.includes(rowIndex)) {
        return null;
    }
    return <ItemRenderer columnIndex={columnIndex} rowIndex={rowIndex} style={style} />;
};

