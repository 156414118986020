
import { printForm } from './printForm.js';
import { openDialogVariants } from "../common/helpers";
import E_FORM_23x46 from "../static/images/bnFullScr_23x46_ON.png";

import save_23x46 from "../static/images/CHECK_23x46_ON.png";
import cancel_23x46 from "../static/images/CANCEL_23x46_ON.png";
import addS_23x46 from "../static/images/ADDREC_23x46_ON.png";
import delS_23x46 from "../static/images/DELREC_23x46_ON.png";

import itogo_23x46 from "../static/images/ALL_OTH_23x46_ON.png";
import filter_23x46 from "../static/images/FILTER_23x46_ON.png";
import find_23x46 from "../static/images/FindBtn_23x46_ON.png";
import findNext_23x46 from "../static/images/FINDNEXT_23x46_ON.png";
import sort_23x46 from "../static/images/SORT_23x46_ON.png";
import lamp_23x46 from "../static/images/LAMP_23x46_ON.png";
import print_23x46 from "../static/images/PRINT2_23x46_ON.png";
import excelOut_23x46 from "../static/images/EXCEL_23x46_ON.png";
import csv_23x46 from "../static/images/CSV_23x46_ON.png";
import dbf_23x46 from "../static/images/DBF_23x46_ON.png";

import menu_tb from "../static/images/menu_tb.png";

import help_23x46 from "../static/images/HELP_23x46_ON.png";
import exitTF_23x46 from "../static/images/EXIT2_23x46_ON.png";

async function choiceShablName(component, title, text, variants) {
    let result = await openDialogVariants(component, title, text, variants);
    if (result===undefined)
        return;
    let lst = variants.split("\n");
    //alert("Подготовка печатная формы. Алиас: " + component.props.tfalias + "result: " + lst[result]);
    printForm.makeForm(component.props.tfalias, lst[result], component.state.tf.data[component.state.curCell.row].key, []);
}

export class TbControls {

    constructor(tform) {
        this.tform = tform;

        this.buttons = {
            buttons_first: {
                1: [E_FORM_23x46, "Открыть ЭФ", "1:1"],
                2: [save_23x46, "Сохранить", "1:2"],
                3: [cancel_23x46, "Отменить", "1:3"],
                4: [addS_23x46, "Добавить строку", "1:4"],
                5: [delS_23x46, "Удалить строку", "1:5"]
            },
            buttons_second: {
                1: [itogo_23x46, "Итоги", "2:1"],
                2: [filter_23x46, "Фильтр", "2:2"],
                3: [find_23x46, "Поиск", "2:3"],
                4: [findNext_23x46, "Искать далее", "2:4"],
                5: [sort_23x46, "Сортировка", "2:5"],
                6: [lamp_23x46, "Справочник", "2:6"],
                7: [print_23x46, "Печать", "2:7"],
                8: [excelOut_23x46, "Отправить в Excel", "2:8"],
                9: [csv_23x46, "Отправить в csv", "2:9"],
                10:[dbf_23x46, "Отправить в dbf", "2:10"]
            },

            buttons_fourth: {
                10: [menu_tb, "...", "3:10"]
            },

            buttons_end: {
                1: [help_23x46, "Справка", "4:1"],
                2: [exitTF_23x46, "Закрытие ТФ", "4:2"],
            }
        }

        this.processEvent = {
            "1:1": this.tform.gotoScreenForm,
            "4:2": this.tform.handleClickTbBtnCloseTf,
            "1:2": this.tform.handleClickTbBtnSave,
            "2:2": this.tform.handleClickTbBtnFiltr,
            "1:3": this.tform.onCancelEditRow,
            "1:4": this.tform.handleClickAddRow,
            "1:5": this.tform.handleClickDelRow,
            "2:7": this.createPrintForm,
            "2:6": this.gotoCatalog,
            "2:8": this.exportXls,
            "2:9": this.exportCsv

        }

        this.checksEnableOfBtn = {
            "1:1": true,
            "4:1": false,
            "4:2": true,
            "1:2": this.isEditCells,
            "2:2": true,
            "1:3": this.isEditCells,
            "1:4": true,
            "1:5": true,
            "2:7": false,//this.isPrintForm,
            "2:8": false,//true,
            "2:9": false,//true,
            "2:6": ()=> {
                return this.tform.cellHasCatalog() || this.tform.cellHasOptions()
            }
        }

        this.keys = {
            F9 : "1:1",
            'Ctrl+s': "1:2",
            'Ctrl+ы': "1:2",
            'Ctrl+n': "1:4",
            //'Ctrl+i': "Ctrl+I",
            F4 : "1:5",
            //F2 : "F2",
            F6 : "2:2",
            //F5 : "F5",
            //'Alt+F5': "Alt+F5",
            //'Ctrl+d': "Ctrl+D",
            F7 : "2:6",
            F8 : "2:7",
            'Ctrl+x': "2:8",
            'Ctrl+ч': "2:8",
            //'Ctrl+b': "Ctrl+B",
            //Insert : "Ins",
            //'Alt+Insert': "Alt+Ins",
            //'Ctrl+F9': "Ctrl+F9",
            //'Shift+F9': "Shift+F9"
        };

    }

    buttonStyle = (item) => {
        return {
            backgroundImage: 'url('+item[0]+')',
            backgroundRepeat:"no-repeat",
            backgroundPosition: "center center",
            width: "36px",
            height: "34px"
        };
    }

    isEnabledButton = (id) => {
        switch (this.checksEnableOfBtn[id]) {
            case undefined:
                return false;
            case true:
            case false:
                return this.checksEnableOfBtn[id];
            default:
                return (this.checksEnableOfBtn[id])();
        }
    }

    isEditCells = () => {
        return this.tform.tfEditRows.hasEditRow(this.tform.state.curCell.row);
    }

    isPrintForm = () => {
        if (this.tform.state.tf!==undefined && this.tform.state.tf.info!==undefined && this.tform.state.tf.info.PrtFormInfo!==undefined)   {
            return (this.tform.state.tf.info.PrtFormInfo.length>0);
        }
        return false;
    }


    gotoCatalog = () =>{
        if (this.tform.CurFieldRef) {
            this.tform.CurFieldRef.handleBtnCatalogClick();
        } else {
            this.tform.handleClickTbBtnCatalog();
        }
    }

    createPrintForm = () => {
        let variants = this.tform.state.tf.info.PrtFormInfo.replaceAll(';', '\n');
        choiceShablName(this.tform, "Шаблоны ПФ", "Выберите шаблон для печати", variants);
        //////тф Платежные поручения
        //printForm.makeForm(this.tform.props.tfalias, "PLAT_P2003.docx", this.tform.state.tf.data[this.tform.state.curCell.row].key, []);
        /////////////////тф Накладные на отгрузку
        //printForm.makeForm(this.tform.props.tfalias, "Nakl-1T.docx", this.tform.state.tf.data[this.tform.state.curCell.row].key, []);
        //printForm.makeForm(this.tform.props.tfalias, "REQUEST.docx", this.tform.state.tf.data[this.tform.state.curCell.row].key, []);
        //alert("Подготовка печатная формы. Алиас: " + this.tform.props.tfalias + "result: " + result);
    }

    prmsForExport =(type, wtfile) => {
        let prms = {
            status: 1,
            typefile: type,
            web_tfile: wtfile,
            tfalias: this.tform.props.tfalias,
            columns: this.tform.state.tf.info.Columns,
            filtr: this.tform.state.s_filtr,
            sort: this.tform.state.s_sort,
            limit: this.tform.state.tf.total
        }
        return prms;
    }

    exportXls = () =>{
        let prms = this.prmsForExport('xlsx', 'xlsx');
        this.tform.setExportDoc(prms);
    }

    exportCsv = () =>{
        let prms = this.prmsForExport('csv', 'text/csv');
        this.tform.setExportDoc(prms);
    }

    setItemsContextMenu(obj, items) {
        for(var key in obj) {
            if (this.isEnabledButton(obj[key][2]))
                items[obj[key][2]] = {name: obj[key][1]};
            else
                items[obj[key][2]] = {name: obj[key][1], disabled: true};
        }
        return items;
    }


    getProcessEventOnKey = (ckey) =>{
        //console.log("getProcessEventOnKey ckey >>", ckey);
        let id = this.keys[ckey];
        return this.processEvent[id];
    }

    getProcessEventFromId = (id) =>{
        return this.processEvent[id];
    }

    getContextMenuOneTwoGrp = () =>{
        let items = {};
        let obj = this.buttons.buttons_first;
        let items1 = this.setItemsContextMenu(obj, items);
        items1.sep1 = "---------";
        obj = this.buttons.buttons_second;
        let items2 = this.setItemsContextMenu(obj, items1);
        items2.sep2 = "---------";
        return items2;
    }

    getContextMenuEnd = () =>{
        let items = {};
        let obj = this.buttons.buttons_end;
        return this.setItemsContextMenu(obj, items);
    }

    getButtonsGroup = (group) =>{
        let buttons_group = [];
        let obj = this.buttons[group];
        for(var key in obj) {
            buttons_group.push(obj[key]);
        }
        return buttons_group;
    }

}

