import React from 'react';
import { fillDictByExistKeys } from '../../common/helpers.js';
//import { LOAD } from '../common/load.js';

export class FilesListControl {

    constructor(component) {
        this.component = component;
        component.state.files_list_show = 0;
        component.state_files_list = null;
        this._resolve = null;
        this._showing = false;
        this._currentScreenField = null;
    }

    prepareFilesList = (cat) => {
        document._DEBUG(7, "prepareFilesList:", cat, "component:", this.component);
        this.component.state_files_list = cat;
    }

    showFilesList = (cat) => {
        document._DEBUG(7, "showFilesList - screenField", this._currentScreenField);
        document._DEBUG(7, ">> showFilesList:", this.component.state_files_list);
        this._showing = true;
        this.component.state_files_list.title = cat.title;
        this.component.setState({files_list_show: this.component.state.files_list_show + 1},
            () => {
                if (this._currentScreenField)
                    this._currentScreenField.blur();
            });
    }

    hideFilesList = () => {
        this._showing = false;
        this.component.setState({files_list_show: 0});
        if (this._resolve) {
            this._resolve();
        }
    }

    isFilesListShowing = () => {
        return this._showing; //this.component.state.files_list_show != 0;
    }

    setCurrentScreenField = (field) => {
        document._DEBUG(7, "setCurrentScreenField", field);
        this._currentScreenField = field;
    }

    wait = () => {
        let self = this;
        function start(resolve) {
            self._resolve = resolve;
        }
        return new Promise(resolve => start(resolve));
    }

    renderFilesList = () => {
        let files_list = '';
        if (this.component.state.files_list_show) {
            document._DEBUG(5, "renderFilesList component:", this.component);
            let state_files_list = this.component.state_files_list;
            document._DEBUG(5, "renderFilesList files:", state_files_list.files);
            files_list = (
                <FilesList ref={state_files_list.ref} tfalias={state_files_list.tfalias} fieldName={state_files_list.fieldName}
                    onChoose={state_files_list.onChoose} title={state_files_list.title}
                    files={state_files_list.files}
                    fileAddControl={this}
                    app = {this.component.app}
                ></FilesList>
            )
        }
        return (
            <div className="files_list-container">
                {files_list}
            </div>
        )
    }
};

export class FilesList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
        };
        fillDictByExistKeys(this.state, this.props);

        this.inputRef = React.createRef();
    }

    handleClickSend=(e)=>{
        e.stopPropagation();
        this.handleSend();
    }

    handleSend=()=>{
        document._DEBUG(7, 'handleSend--> ');

        let tf_component = this.props.fileAddControl.component;
        let tf_info = tf_component.state.tf.info;
        document._DEBUG(5, "tf_info:", tf_info, "tf:", tf_component.state.tf);

        let rowIndex = tf_component.state.curCell.row;
        document._DEBUG(5, 'rowIndex-->', rowIndex);

        let row = tf_component.getRow(rowIndex);
        document._DEBUG(5, 'row-->', row);

        // let file = this.inputRef.current.files[0];  // file from input
        // let tfAlias = tf_info.TfAlias;
        // let recordKey = row.key;
        // let filename = file.name;
        // let type = "Файл";
        
        //....

        this.props.fileAddControl.hideFilesList();
    }

    handleCancel=()=>{
        this.props.fileAddControl.hideFilesList();
    }

    render() {
        let display = 'none';
        let show = true;
        if(show){
            display = 'flex';
        }

        let files = this.props.files.map((fi, index) => {
            return (
                <div><a href={"http://localhost:8000/file/get/?uid="+fi.row[5]}>{index+1} - {fi.row[4]}</a></div>
            )
        })

        // console.log('FilesList tfalias: ', this.props.tfalias)
        if(show){
            document._DEBUG(5, 'filesListControl files: ', this.props.files, ">>", files);
            return (
                <div style={{display: display}} className="modal screen-block"
                tabIndex='-1'
                role='dialog'
                aria-hidden='true'>
                    <div className="screen-back" onClick={this.handleClose}></div>
                    <div className="modal-content screen-content" style={{height: '80vh'}}>
                        <div className='modal-header'>
                            <div>
                                <h5 className='modal-title'>Выберите из справочника.</h5>
                            </div>
                            <button className='close' type='button' data-dismiss='modal' onClick={this.handleCancel}>
                                <span aria-hidden='true'>&times;</span>
                            </button>
                        </div>

                        <div className='modal-body screen-body' style={{display: 'flex'}}>

                            <div style={{display: 'block'}}>
                            {files}
                            </div>

                        </div>

                        <div className='modal-footer'>
                            <div className='screen-btns'>
                                {/* <button className='btn btn-primary screen-btn' onClick={this.handleClickSend}>Отправить</button> */}
                                <button className='btn btn-primary screen-btn' onClick={this.handleCancel}>Отмена</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }else{
            return(
                <div style={{display: display}} ></div>
            )
        }
    }
}