import React from 'react';
import { TfLoading } from "../tform/tf_error.js";
import { LOAD } from "../../common/load";
import { convertKompasDateToTemplate, findColumnPosition } from "../../common/helpers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan} from '@fortawesome/free-solid-svg-icons';
import { Dialog } from '../dialogs/dialog.js';
import { ScreenForm } from '../screenform.js';
import { KanbanColumn } from './kanban_column.js';
import { ChoiceFromRadioGroup } from '../../common/components/radioGroup.js';
import { InputWithCatalog } from '../../common/components/allInput.js';

export const KANBAN_DRAG_TYPE = {
    column: 'column',
    sticker: 'sticker',
    deleting: 'deleting',
    canceling: 'CANCEL_VALUE',
    finishing: 'FINISH_VALUE',
    not_start: 'NOT_START_VALUE'
}

function DialogDragEnding(props) {   
    let idList;
    let kanban = props.kanban;
    let sticker = kanban.sticker;
    let stateDialog = kanban.state.dialog
    
    const handleOk =()=>{   
        document._DEBUG(5, 'handleOk ==> ', idList)              
        if (stateDialog === KANBAN_DRAG_TYPE.deleting){
            kanban.setState({dialog: false}); 
            sticker.deleteSticker();           
        }else{
            if(idList){
                kanban.setState({dialog: false}); 
                let values = [idList];
                let fields = [kanban.KWEB.ID_STATE];
                sticker.saveSticker({values: values, fields: fields});                            
            }else{
                alert("Вы не сделали выбор!");
            } 
        }  
        sticker = null;       
    }

    const handleCancel =()=>{        
        kanban.setState({dialog: false});
        sticker = null;
    }   
    
    let children = [];
    let title = "Заголовок";
    let id = sticker.props.id
    switch (stateDialog){
        case KANBAN_DRAG_TYPE.deleting:
            children = <div>{"Вы уверены что хотите удалить стикер "+id+"?"}</div>
            title = "Удаление";
            break;
                                 
        default:
            let dictList = kanban.getStatesFromLoaded(stateDialog);                  
            return (
                <ChoiceFromRadioGroup   
                    giveValueOut = {(value)=>{idList = value}}                  
                    dictList = {dictList}
                    value = {dictList[0].value}
                    handleOk={handleOk}  
                    handleCancel = {handleCancel}          
                    title={'Выберите новое состояние'}                  
                    cssModalContent="kanban-modal-content"
                ></ChoiceFromRadioGroup>
            )          
    }          
    
    return (
        <Dialog
            children={children}
            handleOk={handleOk}
            handleCancel={handleCancel}
            title={title}
            styleBox={{}}
            modalContent="kanban-modal-content"
        ></Dialog>       
    )    
}
export class Kanban extends React.Component {
    constructor(props) {
        super(props);
        this.KWEB = this.props.info["#KWEB"].Kanban;
        this.xhrDict = {};
        this.sortDict = {};
        this.filterDict = {};
        this.stickerDragging = null;  
        this.setEmptyDragdata();
        this.isFirstDataRequested = false;
        this.dataFiltersCheck = {
            states: false,
            FINISH_VALUE: false,
            CANCEL_VALUE: false,
            NOT_START_VALUE: false               
        }     
        this.state = { 
            loaded: {
                states: false,
                data: false,
                status: false
            },  
            dragging: false,
            dialog: false,
            sf_show: false
        }
    }

    setEmptyDragdata =()=>{
        this.dragData = {
            dropTarget: false,
            type: false, //обозначает тип места куда будет проведен сброс
            stickerDragging: false,
            stateId: false //код состояния перетаскиваемого стикера
        };  
    }

    getDropColumn =(type, dropTarget)=>{
        let dropColumn
        if(type === KANBAN_DRAG_TYPE.sticker){
            dropColumn = dropTarget.props.column;
           
        }else if(type === KANBAN_DRAG_TYPE.column){
            dropColumn = dropTarget;          
        }
        return dropColumn;
    }

    dragLeaveCommon =(newType, newDropTarget, e)=>{
        document._DEBUG(4, 'dragLeaveCommon ==> ', this, e, newType, newDropTarget)      
        let dragData = this.dragData;
        let leaveTarget = dragData.dropTarget;
        let dropColumn = this.getDropColumn(dragData.type, leaveTarget);
        let newDropColumn = this.getDropColumn(newType, newDropTarget);
      
        if(dropColumn){            
            if(dropColumn !== newDropColumn){
                dropColumn.setState({dragOverActive: false});
            }            
        }else if(leaveTarget){
           leaveTarget.classList.remove('kanban-shadow');
        }
        
        dragData.type = newType;
        dragData.dropTarget = newDropTarget ? newDropTarget : e.target;
        this.dragEnterCommon(e);
    }

    dragEnterCommon =(e)=>{
        let dragData = this.dragData;
        let dropTarget = dragData.dropTarget;
        let dropColumn;
        if(dragData.type === KANBAN_DRAG_TYPE.sticker){
            dropColumn = dropTarget.props.column;
            if(dragData.stateId !== dropColumn.props.id){                    
                if(dropColumn.state.dragOverActive === dropTarget.props.priority){
                    dropColumn.setState({dragOverActive: dropTarget.props.priority+1}) 
                }else{
                    dropColumn.setState({dragOverActive: dropTarget.props.priority}) 
                }            
            }   
        }else if(dragData.type === KANBAN_DRAG_TYPE.column){
            dropColumn = dropTarget;
            if(dragData.stateId !== dropColumn.props.id){ 
                dropColumn.setState({dragOverActive: 'column'})             
            }
        }else{
            e.target.classList.add('kanban-shadow');
        }  
        
        document._DEBUG(5, 'dragEnterCommon ==> ', dragData.type, e.target);
    }

    setStateLoaded(key, value){
        let loaded = this.state.loaded;        
        loaded[key] = value;        
        this.setState({loaded: loaded});
    }
 
    getStatesFromLoaded =(loadedKey)=>{
        let values = null;
        let name = this.KWEB.IN_STATES.NAME;
        let id = this.KWEB.IN_STATES.ID;
        try {                        
            let loaded = this.state.loaded[loadedKey];
            let namePos = findColumnPosition(loaded.info.Columns, name)     
            let idPos = findColumnPosition(loaded.info.Columns, id)
            
            values = loaded.data.map((val, index)=>{
                return {value: val.row[idPos], label: val.row[namePos]}
            });
        } catch (e) {            
            return;
        }  
        return values;
    }

    afterTfLoaded = (xhrKey, forDataFilter=false)=>{
        let xhr = this.xhrDict[xhrKey];
        let objTf = null;
        try {            
            objTf = JSON.parse(xhr.response);  
            document._DEBUG(5, "afterTfLoaded ==> e ", xhrKey, objTf);            
        } catch (e) {            
            return;
        }
        this.setStateLoaded(xhrKey, objTf)  
        if(xhrKey === 'states'){this.dataFiltersCheck[xhrKey] = true;}
        if(forDataFilter) {
            let values = [];
            values = objTf.data.map((val, index)=>{
                let pos = findColumnPosition(objTf.info.Columns, this.KWEB.IN_STATES.ID)
                return val.row[pos];
            })            
            this.addFiltersData(values);
            this.dataFiltersCheck[xhrKey] = true;
        } 
        for(let key in this.dataFiltersCheck){
            if(!this.dataFiltersCheck[key]){
                return;
            }
        }
        if(!this.isFirstDataRequested){
            this.isFirstDataRequested = true;
            this.requestDataFromServer();
        }        
    }
   
    requestDataFromServer =(e, _tfType)=>{
        let filters = [];
        let sort = [];
        let tfalias = this.props.info.TfAlias; 
        let tfType = "data";
        if(_tfType){
            tfType = _tfType;
            tfalias = this.KWEB["IN_"+tfType.toUpperCase()].TF;            
        } 
        document._DEBUG(5, "requestDataFromServer ==> _tfType: ", _tfType)
        if(this.filterDict[tfType]){filters = this.filterDict[tfType]}
        if(this.sortDict[tfType]){sort = this.sortDict[tfType]};
               
        this.xhrDict[tfType] = LOAD.get_tf_with_uniqFilter({
            tfalias: tfalias,
            filters: filters,
            sort: sort,
            onload: this.afterTfLoaded.bind(this, tfType, false)
        });   
    }
    fillFiltersByType =(type, valList)=>{
        document._DEBUG(5, "fillFiltersByType ==> type: ", type)
        let tfaliasStates = this.KWEB.IN_STATES.TF; 
        let field = this.KWEB.IN_STATES.TYPE;        
        if (this.KWEB.IN_STATES[type] && !this.state[type]){
            valList = valList.concat(this.KWEB.IN_STATES[type]); 
            this.xhrDict[type] = LOAD.get_tf_with_uniqFilter({
                tfalias: tfaliasStates,
                filters: this.getFilters(field, this.KWEB.IN_STATES[type]),                
                onload: this.afterTfLoaded.bind(this, type, true)
            });                           
        } 
        return valList;       
    }

    createFiltersStates = ()=>{
        let filters = []; 
        let valList = [];       
        if (this.KWEB.IN_STATES['TYPE']){
            let field = this.KWEB.IN_STATES.TYPE;
            valList = this.fillFiltersByType('FINISH_VALUE', valList);
            valList = this.fillFiltersByType('CANCEL_VALUE', valList);
            valList = this.fillFiltersByType('NOT_START_VALUE', valList);            
     
            filters.push(
                {   "field": field,
                    "operator": "!=",
                    "values": valList
                }
            );         
        }
        document._DEBUG(5, "createFiltersStates ==> ", filters);     
        this.filterDict.states = filters;        
    }
    createFiltersData =(values)=>{
        let filters = [];
        let field = this.KWEB.ID_STATE;
        filters.push(
            {   "field": field,
                "operator": "!=",
                "values": values
            }
        );          
        this.filterDict.data = filters;     
    }
    addFiltersData =(values)=>{
        let dataFilter = this.filterDict.data;
        if(dataFilter){
            dataFilter[0].values = dataFilter[0].values.concat(values);                
        }else{
            this.createFiltersData(values);
        }       
    }

    getFilters =(field, val)=>{
        let f = [];
        f.push(
            {   "field": field,
                "operator": "=",
                "values": val
            }
        );
        return f
    }

    findData = (type, row, isGetTitle=false) =>{        
        let value = ""; 
        if(this.KWEB[type]){
            value = this.getFieldValue(this.KWEB[type], row)            
            if(type === "DATE"){
                value = convertKompasDateToTemplate(value);
            }            
        }
        if(isGetTitle){            
            let columns = this.state.loaded.data.info.Columns;
            let pos = findColumnPosition(columns, this.KWEB[type])
            let title = columns[pos].ColName;
            return [value, title];
        }
        return value;
    } 

    handleChangeData =(changeList)=>{        
        let data = this.state.loaded.data;
        for (let i in changeList ) {
            let dict = changeList[i];            
            let fieldPos = findColumnPosition(data.info.Columns, dict.field)            
            data.data[dict.rowIndex].row[fieldPos] = dict.value;
        }
        
        document._DEBUG(5, "handleChangeData ==> changeList data ", changeList, data);
        this.setStateLoaded('data', data)        
    }

    getFieldValue =(field, row)=>{
        let columns = this.state.loaded.data.info.Columns;
        let pos = findColumnPosition(columns, field);
        let value = row[pos];

        return value;        
    }

    handleDragEnter =(e)=>{
        this.dragEnterCommon(e);       
    }

    onCloseChangerSF =()=>{
        this.setState({sf_show: false});
    }
    onSaveSForm =(_values)=>{ 
        if(!_values || Object.keys(_values).length === 0){return;}
        let sticker = this.state.sf_show;      
        let values = [];        
        let fields = [];
        for (let key in _values) {
            values.push(_values[key].value)
            fields.push(key);                      
        }  
        document._DEBUG(5, "onSaveSForm ==> ", values, fields)
        sticker.saveSticker({values: values, fields: fields})        
    }
    isScreenFormShow=()=>{
        if(this.state.sf_show){
            let rowIndex = this.state.sf_show.props.index;
            let columns = this.state.loaded.data.info.Columns;
            let tf = this.props.tf;
            let data = this.state.loaded.data.data;
            let row = data[rowIndex].row;
       
            document._DEBUG(5, "isScreenFormShow ==> ", "row: ", row, "rowIndex: ", rowIndex);
            return (
                <ScreenForm title={tf.state.title_tf} columns={columns} row={row} rowKey={rowIndex} show={true}
                    onCloseChanger={this.onCloseChangerSF}
                    onSave={this.onSaveSForm}
                    // ref={tf.screenFormRef}
                    app={tf.props.app}
                    // parentTform={tf}
                    rerender={this.state.sf_show}
                    
                    modal={!tf.props.need_show_ef_in_right}
                    // hide_titlebar={tune ? tune.hide_titlebar : false}
                    // hide_buttonsbar={tune ? tune.hide_buttonsbar : false}
                    // efStyleAdd={tune ? tune.efStyleAdd : null}
                    >
                </ScreenForm>
            )
        }else{
            return('')
        }
    }
    createSort =(sort, type)=>{
        if(sort){
            if(typeof sort === "string"){
                this.sortDict[type] = [{order: 'asc', field: sort}]
            }else{
                this.sortDict[type] = sort
            }
        }          
    }

    
    getColumns = () =>{ 
        let objTf = this.state.loaded.states;
        document._DEBUG(5, 'getColumns ==> objTf: ', objTf);
        let namePos = findColumnPosition(objTf.info.Columns, this.KWEB.IN_STATES.NAME)          
        let columnsData = objTf.data.map((val, index)=>{
            return val.row[namePos];            
        });         
        let columns = columnsData.map((val, index)=>{
            let idPos = findColumnPosition(objTf.info.Columns, this.KWEB.IN_STATES.ID)  
            let id = objTf.data[index].row[idPos];                       
            return (
                <KanbanColumn key={index} id={id} header={val} kanban={this}></KanbanColumn> 
            );
        })
        return columns;
    }

    getFooter =()=>{
        let footer ="";
        if(this.state.dragging){
            footer = 
            <div className='kanban-footer'>
                <div className='left-footer'>
                    <div className='footer-block'
                        onDragEnter={this.dragLeaveCommon.bind(this, KANBAN_DRAG_TYPE.not_start, null)}                 
                    >
                        <div className='not_start-line'></div>
                        <div className='not_start-word'>Не разобрано</div>
                    </div>
                </div>
          
                <div className='right-footer'>
                    <div className="footer-block trash" 
                        onDragEnter={this.dragLeaveCommon.bind(this, KANBAN_DRAG_TYPE.deleting, null)}               
                    >
                        <div className='trash-line'></div>
                        <div className='trash-icon'>                          
                            <FontAwesomeIcon icon={faTrashCan} />
                        </div>                                                    
                    </div>
                    <div className='footer-block cancel'
                        onDragEnter={this.dragLeaveCommon.bind(this, KANBAN_DRAG_TYPE.canceling, null)}                 
                    >
                        <div className='cancel-line'></div>
                        <div className='cancel-word'>Не реализована</div>
                    </div>
                    <div className='footer-block finish'
                        onDragEnter={this.dragLeaveCommon.bind(this, KANBAN_DRAG_TYPE.finishing, null)}                     
                    >
                        <div className='finish-line'></div>
                        <div className='finish-word'>Успешно завершена</div>
                    </div>
                </div>
          
            </div>
        }
        return footer;
    }

    getFilterBlock = ()=>{ 
        let filterOptions = this.KWEB.FILTER.OPTIONS       
        
        {/* <ScreenField key={index} index={index} value={val} title={title} rowKey={this.state.rowKey} column={column}
onInput={'onSave' in this.props ? this.onInputScreenField : null}
saveValueInSF={'onSave' in this.props ? this.onSaveValueInSF : null}
catalogControl={this.catalogControl}
directMode = {this.state.directMode}
ref={'onSave' in this.props ? (el)=>{this.ScreenFields[index] = el} : null}>
</ScreenField> */}

        return (
            <div className='kanban-filter-block'>
                {
                    filterOptions.map((filter, index)=>{
                        document._DEBUG(5, 'getFilterBlock ==> filter: ', filter)
                        let posId = filter.ID_IN_FILTER;
                        let posName = filter.ID_IN_FILTER;
                        let colName = filter.NAME_IN_FILTER;
                        
                        return (
                            <div className='kanban-filter'>
                                <InputWithCatalog key={index} label={colName} type='text' component={this}></InputWithCatalog>      
                            </div>                                         
                        )
                    })
                }
            </div>
        )
    }

    componentDidMount() {  
        this.createFiltersStates();     
        this.createSort(this.KWEB.SORT, 'data');
        this.createSort(this.KWEB.IN_STATES.SORT, 'states');     
     
        this.requestDataFromServer(null, "states");                  
        if(this.KWEB.IN_STATUS){ 
            let colorSet = this.KWEB.IN_STATUS.COLOR;
            if(typeof colorSet === "string"){
                this.requestDataFromServer(null, "status");
            }else{
                this.setStateLoaded('status', true)             
            }                          
        }
    }

    render () {        
        for(let key in this.state.loaded){            
            if(!this.state.loaded[key]){
                return (
                    <TfLoading/>
                )
            }
        }
       
        document._DEBUG(5, 'render kanban ==> ', this);        
      
        return (
            <div className='kanban-content'>  
                <div className='kanban-header'>
                    {this.KWEB.FILTER ? this.getFilterBlock() : ''}
                </div>              
                <div className='kanban-body' style={this.KWEB.FILTER ? {height: "calc(100vh - 170px)"}: {}}>
                    {this.getColumns()}                
                </div>  
                {this.getFooter()}  
                {this.state.dialog ? <DialogDragEnding kanban={this}></DialogDragEnding>: ''}  
                {this.isScreenFormShow()}          
            </div>            
        )
    }
}
