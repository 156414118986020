import React from 'react';
//import { GenerateTForm } from "./tform/tform.js";
import { fillDictByExistKeys } from '../../common/helpers.js';
import { LOAD } from '../../common/load.js';

export class FileAddControl {

    constructor(component) {
        this.component = component;
        component.state.file_add_show = 0;
        component.state_file_add = null;
        this._resolve = null;
        this._showing = false;
        this._currentScreenField = null;
    }

    prepareFileAdd = (cat) => {
        document._DEBUG(7, "prepareFileAdd:", cat, "component:", this.component);
        this.component.state_file_add = cat;
    }

    showFileAdd = (cat) => {
        document._DEBUG(7, "showFileAdd - screenField", this._currentScreenField);
        document._DEBUG(7, ">> showFileAdd:", this.component.state_file_add);
        this._showing = true;
        this.component.state_file_add.title = cat.title;
        this.component.setState({file_add_show: this.component.state.file_add_show + 1},
            () => {
                if (this._currentScreenField)
                    this._currentScreenField.blur();
            });
    }

    hideFileAdd = () => {
        this._showing = false;
        this.component.setState({file_add_show: 0});
        if (this._resolve) {
            this._resolve();
        }
    }

    isFileAddShowing = () => {
        return this._showing; //this.component.state.file_add_show != 0;
    }

    setCurrentScreenField = (field) => {
        document._DEBUG(7, "setCurrentScreenField", field);
        this._currentScreenField = field;
    }

    wait = () => {
        let self = this;
        function start(resolve) {
            self._resolve = resolve;
        }
        return new Promise(resolve => start(resolve));
    }

    renderFileAdd = () => {
        let file_add = '';
        if (this.component.state.file_add_show) {
            document._DEBUG(5, "renderFileAdd component:", this.component);
            let state_file_add = this.component.state_file_add;
            file_add = (
                <FileAdd ref={state_file_add.ref} tfalias={state_file_add.tfalias} fieldName={state_file_add.fieldName}
                    onChoose={state_file_add.onChoose} title={state_file_add.title}
                    fileAddControl={this}
                    app = {this.component.app}
                ></FileAdd>
            )
        }
        return (
            <div className="file_add-container">
                {file_add}
            </div>
        )
    }
};

export class FileAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
        };
        fillDictByExistKeys(this.state, this.props);

        this.inputRef = React.createRef();
    }

    handleClickSend=(e)=>{
        e.stopPropagation();
        this.handleSend();
    }

    handleSend=()=>{
        document._DEBUG(7, 'handleSend--> ');

        let tf_component = this.props.fileAddControl.component;
        let tf_info = tf_component.state.tf.info;
        document._DEBUG(5, "tf_info:", tf_info, "tf:", tf_component.state.tf);

        let rowIndex = tf_component.state.curCell.row;
        document._DEBUG(5, 'rowIndex-->', rowIndex);

        let row = tf_component.getRow(rowIndex);
        document._DEBUG(5, 'row-->', row);

        let file = this.inputRef.current.files[0];  // file from input
        let tfAlias = tf_info.TfAlias;
        let recordKey = row.key;
        let filename = file.name;
        let type = "Файл";

        // let uid = "tst_uid";
        // let xhr = LOAD.register_file(tfAlias, recordKey, filename, type, uid, () => {
        //     if (xhr.status == 200) {
        //         let obj = JSON.parse(xhr.response)
        //         document._DEBUG(5, "--- OK:", obj);
        //     }
        // }, () => {
        //     document._DEBUG(5, "--- ERR:", xhr.response);
        // })

        LOAD.send_file(file, (req) => {
            document._DEBUG(5, "response:", req.responseText);
            let lst = req.responseText.split("\nuid:");
            if (lst.length > 1) {
                let uid = lst[1].split('\n')[0].trim()
                document._DEBUG(5, "UID:", uid);

                let xhr = LOAD.register_file(tfAlias, recordKey, filename, type, uid, () => {
                    if (xhr.status === 200) {
                        let obj = JSON.parse(xhr.response)
                        document._DEBUG(5, "--- OK:", obj);
                    }
                }, () => {
                    document._DEBUG(5, "--- ERR:", xhr.response);
                })
            }
        }, (req) => {
            document._DEBUG(5, "error:", req.response);
        });

        //{
            // let file = this.inputRef.files[0];  // file from input
            // let req = new XMLHttpRequest();
            // let formData = new FormData();
            // formData.append("file", file);
            // req.onload = () => {
            //     document._DEBUG(5, "response:", req.responseText);
            //     let lst = req.responseText.split("\nuid:");
            //     if (lst.length > 1) {
            //         let uid = lst[1].split('\n')[0].trim()
            //         document._DEBUG(5, "UID:", uid);
            //     }
            // };
            // req.onerror = () => {
            //     document._DEBUG(5, "error:", req.response);
            // };
            // req.open("POST", 'http://localhost:8000/file/post/');
            // req.send(formData);
        //}

        this.props.fileAddControl.hideFileAdd();
    }

    handleCancel=()=>{
        this.props.fileAddControl.hideFileAdd();
    }

    render() {
        let display = 'none';
        let show = true;
        if(show){
            display = 'flex';
        }
        // console.log('FileAdd tfalias: ', this.props.tfalias)
        if(show){
            document._DEBUG(5, 'fileAddControl tfalias: ', this.props.tfalias, "app:", this.props.app);
            return (
                <div style={{display: display}} className="modal screen-block"
                tabIndex='-1'
                role='dialog'
                aria-hidden='true'>
                    <div className="screen-back" onClick={this.handleClose}></div>
                    <div className="modal-content screen-content" style={{height: '80vh'}}>
                        <div className='modal-header'>
                            <div>
                                <h5 className='modal-title'>Выберите из справочника.</h5>
                            </div>
                            <button className='close' type='button' data-dismiss='modal' onClick={this.handleCancel}>
                                <span aria-hidden='true'>&times;</span>
                            </button>
                        </div>

                        <div className='modal-body screen-body' style={{display: 'flex'}}>

                            <div>
                                <input ref={this.inputRef} type="file" name="file" />
                                {/* <button onClick={()=>{
                                    let file = this.inputRef.files[0];  // file from input
                                    let req = new XMLHttpRequest();
                                    let formData = new FormData();
                                    formData.append("file", file);
                                    req.onload = () => {
                                        document._DEBUG(5, "response:", req.responseText);
                                        let lst = req.responseText.split("\nuid:");
                                        if (lst.length > 1) {
                                            let uid = lst[1].split('\n')[0].trim()
                                            document._DEBUG(5, "UID:", uid);
                                        }
                                    };
                                    req.onerror = () => {
                                        document._DEBUG(5, "error:", req.response);
                                    };
                                    req.open("POST", 'http://localhost:8000/file/post/');
                                    req.send(formData);
                                }} >Send</button> */}
                            </div>

                        </div>

                        <div className='modal-footer'>
                            <div className='screen-btns'>
                                <button className='btn btn-primary screen-btn' onClick={this.handleClickSend}>Отправить</button>
                                <button className='btn btn-primary screen-btn' onClick={this.handleCancel}>Отмена</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }else{
            return(
                <div style={{display: display}} ></div>
            )
        }
    }
}