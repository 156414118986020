import $ from 'jquery';
import { parseUrlParams } from "../../common/helpers";
//import contextMenu from "jquery-contextmenu";
import "jquery-contextmenu/dist/jquery.contextMenu.css";


export const generateTfContextMenu = (tf_component) => {
    let toolbar = (tf_component.state.tf.info!==undefined) ?  tf_component.state.tf.info.ToolBar : [];

    let params = parseUrlParams();
    if (params.contextmenu && params.contextmenu !== 'true') {
        return;
    }

    $.contextMenu( 'destroy', '.context-menu-one' );
    $.contextMenu({
        selector: '.context-menu-one',
        //autoHide: true,
        items: {
            "Скрыть колонку": {name: "Скрыть колонку"},
            "Отобразить колонки": {name: "Отобразить колонки"},
            "Восстановить колонки": {name: "Восстановить колонки"}
        }
    });

    let menuList = generateCellContextMenuList(tf_component, toolbar);

    //document._DEBUG(5, "contextMenu list>> ", menuList);
    let tb_controls = tf_component.tb_controls;

    $.contextMenu( 'destroy', '.context-menu-two' );
    $.contextMenu({
        selector: '.context-menu-two',
        zIndex: 1031,
        items: menuList,
        callback: function(key, options) {
            if (tb_controls.getProcessEventFromId(key)!==undefined)
                (tb_controls.getProcessEventFromId(key))();
        },
    });
}

// создает объект js для списка пунктов контекстного меню ячеек
const generateCellContextMenuList = (tf_component, toolbar) => {
    let items1 = tf_component.tb_controls.getContextMenuOneTwoGrp();
    let items3 = tf_component.tb_controls.getContextMenuEnd();
    //let tform = tf_component;
    let app = tf_component.props.app;
    let c = 1;
    if (toolbar!=null && toolbar!==undefined) {
        for (let el of toolbar) {
            if (el.Disabled) continue;
            let title = (el.Hint!=='') ? el.Hint : "Без названия";
            items1["3:" + c] = {   name: title,
                                    disabled: (el.BPName!=='') ? false : true,
                                    callback: (el.BPName==='') ? null :
                                        // FIXME @gizs в старой версии было другое поведение. Видно разницу по комментарию:
                                        function(key, opt){   app.bpCall.call_bp(el.BPName); /*(new BpLang({component: tform, app: app})).run_bp(el.BPName);*/ }
            };
            c++;
        }
    }
    if (c>1)  items1.sep3 = "---------";
    let obj = Object.assign({}, items1, items3);
    return obj;
}
