
import React from 'react';
import {ScreenField} from './screenField.js';
import { fillDictByExistKeys, compasTitleToWebTitle, clone } from '../common/helpers.js';
import { CatalogControl } from './dialogs/catalog.js';
//import { knotify } from "../common/notify";
import { AppContext } from '../common/app_context.js';
import { ChoiceFiles } from "../bp_lang/bp_choiceFile.js";
import { GenerateTForm } from "./tform/tform";
import { BpLang } from '../bp_lang/bp_lang';
import { TForm as BpTForm } from '../bp_lang/bp_tform';

export class ScreenForm extends React.Component {
    static contextType = AppContext

    constructor(props) {
        super(props);
        this.state = {
            title: '',
            row: [],
            rowKey: null,
            columns: [],
            show: false,
            saveDisabled: true,
            cancelDisabled: true,
            tf: null,
            directMode: 1 //Горизонтальное позиционирование 0, вертикальное позиционирование 1
            // forbiddenList: [],
            // warningList: [],
        };

        this.catalogControl = new CatalogControl(this);

        fillDictByExistKeys(this.state, this.props);
        this.SFieldsChangeValues = {};
        this.ScreenFields = [];

        this._subTformRef = React.createRef();
    }

    componentDidMount() {
        const { app } = this.context
        this.lastActiveComponent = app.activeComponent;
        app.activeComponent = this;
        if (this._subTformRef.current != null) {
            this._processSubTf();
        }
    }

    _processSubTf = async () => {
        let bp_lang = new BpLang({component: this/*this._subTformRef.current*/, app: this.props.app});
        let $ = bp_lang._init_engine(null, null);
        let bp_tform = new BpTForm($._engine);
        bp_tform._connect_to_tform(this._subTformRef.current);
        document._DEBUG(5, "***** (after _connect_to_tform) this._subTformRef.current.addedTfOptions:", this._subTformRef.current.addedTfOptions);
        if (this._subTformRef.current.addedTfOptions != null && this._subTformRef.current.addedTfOptions._from_bp != null &&
            this._subTformRef.current.addedTfOptions._from_bp.after_open_event != null
        ) {
            let cur_row = null;
            let _parentTf = this.props.parentTform;
            if (_parentTf != null)
                cur_row = _parentTf.getCurRowData();

            await bp_lang.run_bp(this._subTformRef.current.addedTfOptions._from_bp.after_open_event, [ bp_tform, cur_row ]);
            bp_tform._init_from_bp(this._subTformRef.current.addedTfOptions);
            document._DEBUG(5, "***** >>> addedTfOptions:", this._subTformRef.current.addedTfOptions);
        }
    }

    componentWillUnmount() {
        const { app } = this.context
        app.activeComponent = this.lastActiveComponent;
    }

    handleOk=()=>{
        this.handleSave();
        this.handleClose();
    }

    handleClose=()=>{
        if('onCloseChanger' in this.props){
            this.props.onCloseChanger()
        }else{
            this.setState({show: false});
        }
    }

    handleSave=()=>{
        let forbiddenList = [];
        let warningList = [];

        for(let i in this.ScreenFields){
            let field = this.ScreenFields[i];
            let column = field.props.column;
            if(field.isForbidden()){
                forbiddenList.push(column);
            }else if(field.isWarning()){
                warningList.push(column);
            }
        }
        console.log('forbiddenList: ', forbiddenList, this.ScreenFields[1]);
        if(forbiddenList.length > 0 || warningList.length > 0){
            let message = '';
            if(forbiddenList.length>0){
                message += 'Следующие поля имеют недопустимое значение: \n';
                for(let i in forbiddenList){
                    message +=  compasTitleToWebTitle(forbiddenList[i].ColTitle)+'\n';
                }
            }
            if(warningList.length>0){
                message += 'Следующие поля имеют предупреждение которое можно игнорировать: \n';
                for(let i in warningList){
                    message +=  compasTitleToWebTitle(warningList[i].ColTitle)+'\n';
                }
            }

            alert(message)
            // this.setState({forbiddenList: forbiddenList, warningList: warningList});
            return;
        }

        if('onSave' in this.props){
            this.props.onSave(this.SFieldsChangeValues, this.state.rowKey);
            this.SFieldsChangeValues = {};
            this.setState({saveDisabled: true});
        }
    }

    onInputScreenField=()=>{
        this.setState({saveDisabled: false});
    }

    onSaveValueInSF=(colName, value, restrict)=>{
        this.SFieldsChangeValues[colName] = {value: value};
    }

    onAppKeydown = (e) => {
        let processed = true;
        document._DEBUG(5, "[screenform] onAppKeydown");
        switch (e.key) {
            case 'Escape':
                document._DEBUG(5, "[screenform] onAppKeydown Escape");
                this.handleClose();
                break
            default:
                processed = false;
                break
        }
        //e.preventDefault();
        return processed;
    }

    getFields = ()=>{
        let fields = this.state.row.map((val, index)=>{
            let column = this.state.columns[index]
            let title = compasTitleToWebTitle(column.ColTitle);
            if(val === null){
                val = '';
            }
            // // FIXME в описаниях запрещено редактировать вычисляемые по таблице колонки, поэтому пришлось сделать обход
            // Перенесено на этап получения данных
            // if (column.FieldKind === 2) {
            //     column.ReadOnly = false;
            // }
            return (
                <ScreenField key={index} index={index} value={val} title={title} rowKey={this.state.rowKey} column={column}
                    onInput={'onSave' in this.props ? this.onInputScreenField : null}
                    saveValueInSF={'onSave' in this.props ? this.onSaveValueInSF : null}
                    catalogControl={this.catalogControl}
                    directMode = {this.state.directMode}
                    ref={'onSave' in this.props ? (el)=>{this.ScreenFields[index] = el} : null}>
                </ScreenField>
            )
        })

        return fields;
    }

    render() {
        let display = 'none';
        if(this.state.show){
            display = 'flex';
        }

        let saveDisabled = this.state.saveDisabled;
        let cancelDisabled = this.state.cancelDisabled;
        console.log('render SF display: ', display);

        let screenAllFields = 'screen-all-fields';
        if (this.state.directMode === 1){
            screenAllFields = 'screen-all-fields-vertical';
        }
        let contentCss = '';
        if (this.state.directMode === 1){
            contentCss = 'screen-vertical'
        }

        let subTfEl = null;
        let _parentTf = this.props.parentTform;
        if (_parentTf != null && _parentTf._from_bp != null && _parentTf._from_bp.SubTf != null &&
            _parentTf._from_bp.SubTf.TfAlias != null
            ) {

            document._DEBUG(5, "??????????", _parentTf)
            let sub_tf_alias = _parentTf._from_bp.SubTf.TfAlias;
            let addedTfOptions = {
                _from_bp: clone(_parentTf._from_bp.SubTf)
            };
            addedTfOptions._from_bp.minimize_buttons_count = true;
            if (addedTfOptions._from_bp.connect_filter != null) {
                if (addedTfOptions._from_bp.connect_filter.DOK_ID === "from_parent" || addedTfOptions._from_bp.connect_filter.DOK_ID_src === "from_parent") {
                    let cur_row = _parentTf.getCurRowData();
                    addedTfOptions._from_bp.connect_filter.DOK_ID = cur_row.key.ID;
                    addedTfOptions._from_bp.connect_filter.DOK_ID_src = "from_parent";
                }
                if (addedTfOptions._from_bp.connect_filter.key != null) {
                    let key = addedTfOptions._from_bp.connect_filter.key;
                    let key__src = addedTfOptions._from_bp.connect_filter.key__src;
                    if (key__src == null) {
                        addedTfOptions._from_bp.connect_filter.key__src = key__src = {};
                    }
                    let cur_row = null;
                    for (const my_key in key) {
                        let to_key = key[my_key];
                        let to_key_src = key__src[my_key];
                        if (to_key.indexOf("parent.") === 0) {
                            key__src[my_key] = to_key;
                            if (cur_row == null) cur_row = _parentTf.getCurRowData();
                            document._DEBUG(5, ">>>>> $$$$$$$$ to_key:", to_key, "cur_row:", cur_row);
                            to_key = cur_row.row[cur_row.row.length-1];//cur_row.key[to_key.split(".")[1]];
                            document._DEBUG(5, "  -->", to_key);
                        } else if (to_key_src != null && to_key_src.indexOf("parent.") === 0) {
                            if (cur_row == null) cur_row = _parentTf.getCurRowData();
                            to_key = cur_row.key[to_key_src.split(".")[1]];
                        }
                        key[my_key] = to_key;
                    }
                }
            }
            let need_show_ef_in_right = false;
            let subTfRightEfEl = null;
            if (_parentTf._from_bp.SubTf.RightEf != null) {
                need_show_ef_in_right = true;
                // subTfRightEfEl = (
                //     <div style={{float: 'right', width: '200px', height: '200px', backgroundColor: '#777777'}}>
                //         {/* <ScreenForm title={"Tst..."} columns={null} row={null} rowKey={null} show={true}
                //             onCloseChanger={null}
                //             onSave={null}
                //             // ref={this.screenFormRef}
                //             app={this.props.app}
                //             parentTform={null}
                //             rerender={null}
                //             tf={null}
                //             modal={true}
                //             >
                //         </ScreenForm> */}
                //     </div>
                // )
            }
            subTfEl = (
                <div style={{minHeight:'30vh', width:'calc(100%-40px)', display:'flex',
                    borderTop:'1px solid #eeeeee',
                    paddingLeft:'20px', paddingRight:'20px', paddingTop:'5px'
                }}>
                    <GenerateTForm
                        tfalias = {sub_tf_alias}
                        catalog = {false}
                        catalogChoose = {()=>{}/*this.handleChoose*/}
                        catalogScreenField = {null/*this.props.currentScreenField*/}
                        //ref = {(el)=>{this.CatalogTform = el}}
                        app = {this.props.app}
                        parent = {this}
                        onEscape = {()=>{}}
                        onEnter = {()=>{}}
                        //catalogOptions = {this.props.catalogOptions}
                        addedTfOptions = {addedTfOptions}
                        hideTitleBar = {true}
                        ref = {this._subTformRef}
                        rerender = {Date.now()}
                        need_show_ef_in_right = {need_show_ef_in_right}
                    />
                    {subTfRightEfEl}
                </div>
            )
        }

        let mainStyle = {display: display};
        let mainClass = "";
        let dialogStyle = {};
        if (this.props.modal)
            mainClass = "modal screen-block";
        else {
            mainClass = "right-panel-block";
            mainStyle['border'] = "none";
        }
        if (this.props.efStyleAdd != null) {
            dialogStyle = Object.assign({}, dialogStyle, this.props.efStyleAdd);
        }
        return (
            <div style={mainStyle} className={mainClass}
            tabIndex='-1'
            role='dialog'
            aria-hidden='true' __rerender={this.props.rerender}>
                {
                    this.props.modal ? <div className="screen-back" onClick={this.handleClose}></div> : null
                }
                <div style={dialogStyle} className={contentCss+" modal-content screen-content"}>
                    <div className='modal-header'>
                        {this.props.hide_titlebar ? null : ( 
                            <div>
                                <h5 className='modal-title'>{this.state.title}</h5>
                            </div> 
                        )}
                        <button className='close' type='button' data-dismiss='modal' onClick={this.handleClose}>
                            <span aria-hidden='true'>&times;</span>
                        </button>
                    </div>

                    <div className='modal-body screen-body' style={{height: '80vh'}}>
                        <div className={screenAllFields}>
                            {this.getFields()}
                        </div>
                    </div>
                    {subTfEl}
                    {this.props.hide_buttonsbar ? null : (
                        <div className='modal-footer'>
                            <div className="notify" style = {{zIndex: '5', position: 'relative', right: '29%', bottom: '1px'}}> </div>
                            <div className='screen-btns'>
                                <button className='btn btn-primary screen-btn' onClick={this.handleOk}>Ок</button>
                                <button className='btn btn-primary screen-btn' onClick={this.handleSave} disabled={saveDisabled}>Сохранить</button>
                                <button className='btn btn-primary screen-btn' disabled={cancelDisabled}>Отмена</button>
                            </div>
                        </div>
                    )}
                </div>
                {this.catalogControl.renderCatalog()}
                <ChoiceFiles component={this}  />
            </div>
        );
    }
}