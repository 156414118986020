import React, { useContext } from "react";
import { GUTTER_SIZE, MAX_COLS_COUNT, ROW_HEIGHT } from "./tf_components";
import { TfCell, TfCurrentCell, TitleCellOnly, AddRowCell } from "./tf_cell";
import { generateTfContextMenu } from "./tf_contextmenu.js"
import { TfContext } from "./tf_funcs";

export const TITLE_ROW_INDEX = -20;
export const ADD_ROW_INDEX = -10;
export const CURRENT_ROW_INDEX = -30;


// Обычная чистая строка
export const TfRow = ({ rowIndex, style, data_row }) => {
    const { tf_component } = useContext(TfContext);

    let line_key = rowIndex;

    var row = [];
    let fixCol = tf_component.state.tf.info.nFixedCols;

    // Проверяем на необходимость подгрузки данных
    let show_cells = true;
    let lines_start = tf_component.state.lines_start;
    let lines_end = tf_component.state.lines_start + tf_component.state.lines_count;

    let rowIndexCalced = rowIndex //- stickyIndices.length;
    if (rowIndexCalced >= lines_end || rowIndexCalced < lines_start) {
        let want_page_0 = Math.floor(rowIndexCalced / 100);
        let want_start = want_page_0 * 100;
        tf_component.tfLoadControl.load(want_start, tf_component.state.s_filtr);
        show_cells = false;
    }
    row.push(
        <div
            className={"GridItem readOnlyCell-css col-title tf-left-cell"}
            unselectable="on"
            style={{
                width: tf_component.calcLeftSpaceWidth(tf_component.state.tf.total) + "px",
            }}
            key={"cell-row-left-" + rowIndex + "--1"}
        >
            {rowIndexCalced+1}
        </div>
    );

    if (data_row != null) {
        let cols_ln = tf_component.state.tf.info.Columns.length; if (cols_ln > MAX_COLS_COUNT) cols_ln = MAX_COLS_COUNT;
        let select = data_row.select;
        let selectStyle = (select) ? { ...style, color: "red"  } : style;

        for (let i=0; i<cols_ln; i++) {
            let fixed = i < fixCol;
            let colInfo = tf_component.state.tf.info.Columns[i];
            if (show_cells) {
                let cell_data = tf_component.CellData(rowIndexCalced, i);
                row.push(
                    <TfCell
                        rowIndex={rowIndex}
                        i={i} style={selectStyle}
                        fixCol={fixCol}
                        fixed={fixed}
                        key={"cell-" + rowIndex + "-" + i}
                        colInfo={colInfo}
                        cell_data={cell_data}
                        />
                );
            }
        }
    }

    return (
        <div
            className="tf-row"
            key={line_key}
            data-row-num={rowIndex}
            style={{
                top: style.top + GUTTER_SIZE
            }}
        >
            {row}
        </div>
    );
}

// Текущая строка - в том числе в режиме редактирования
export class TfCurrentRow extends React.Component {
    static contextType = TfContext

    constructor(props) {
        super(props);
        this.state = {
            curCell: {row: 0, columnKey: 0}
        }
    }

    componentDidUpdate() {
        //document._DEBUG(5, "<<< TfCurrentRow [ componentDidUpdate ]");
        const { tf_component } = this.context;
        //tf_component.generateContextMenu();
        generateTfContextMenu(tf_component);

        let addRowRef = tf_component.addRowRef.current;
        if (addRowRef && addRowRef.state.curCell.columnKey !== this.state.curCell.columnKey) {

            addRowRef.setState({
                curCell: {
                    row: addRowRef.state.curCell.row,
                    columnKey: this.state.curCell.columnKey
                }
            })
        }
    }

    render () {
        const {tf_component, stickyIndices} = this.context;

        //const [curCell, setCurCell] = useState({row: 0, columnKey: 0});
        let curCell = this.state.curCell;

        let rowIndex = this.props.rowIndex;
        let line_key = rowIndex;
        //document._DEBUG(5, "<<< TfCurrentRow [ render ] rowIndex:", rowIndex);

        if (!this.props.is_add_row && rowIndex === ADD_ROW_INDEX)
            return null

        let add = 0;
        for (let ind of stickyIndices) {
            add += tf_component.getRowHeight(ind);
            if (rowIndex === ADD_ROW_INDEX)
                break;
        }
        let style = this.props.style;
        if (rowIndex === ADD_ROW_INDEX)
            style.top = add;
        else
            style.top = (ROW_HEIGHT * rowIndex) + add;

        //document._DEBUG(5, "<<< TfCurrentRow [ render ] style.top:", style.top, "/ add:", add, "curCell.columnKey:", curCell.columnKey);

        var row = [];
        let fixCol = tf_component.state.tf.info.nFixedCols;

        // Проверяем на необходимость подгрузки данных
        let show_cells = true;
        let rowIndexCalced = rowIndex;

        row.push(
            <div
                className={"GridItem readOnlyCell-css col-title tf-left-cell"}
                unselectable="on"
                style={{
                    width: tf_component.calcLeftSpaceWidth(tf_component.state.tf.total) + "px",
                }}
                key={"cell-row-left-" + rowIndex + "--1"}
            >
                {rowIndexCalced === ADD_ROW_INDEX ? "*" : (rowIndexCalced+1)}
            </div>
        );

        if (/*data_row != null || */true) {
            let cols_ln = tf_component.state.tf.info.Columns.length; if (cols_ln > MAX_COLS_COUNT) cols_ln = MAX_COLS_COUNT;

            for (let i=0; i<cols_ln; i++) {
                let fixed = i < fixCol;
                let colInfo = tf_component.state.tf.info.Columns[i];
                if (show_cells) {
                    let cell_data = tf_component.CellData(rowIndexCalced, i);
                    if (curCell.columnKey === i)
                        row.push(
                            <TfCurrentCell
                                rowIndex={rowIndex}
                                i={i} style={style}
                                fixCol={fixCol}
                                fixed={fixed}
                                key={"cell-" + rowIndex + "-" + i}
                                colInfo={colInfo}
                                cell_data={cell_data}
                                />
                        );
                    else
                        row.push(
                            <TfCell
                                rowIndex={rowIndex}
                                i={i} style={style}
                                fixCol={fixCol}
                                fixed={fixed}
                                key={"cell-" + rowIndex + "-" + i}
                                colInfo={colInfo}
                                cell_data={cell_data}
                                />
                        );
                }
            }
        }

        return (
            <div
                className="tf-row"
                key={line_key}
                data-row-num={rowIndex}
                style={{
                    top: style.top + GUTTER_SIZE,
                    backgroundColor: '#ffffff',
                    zIndex: 7,
                }}
            >
                {row}
            </div>
        );
    }
}

export const TitleRow = ({ index, style }) => {
    const {tf_component} = useContext(TfContext);

    let leftSpace = tf_component.calcLeftSpaceWidth(tf_component.state.tf.total);
    let info_columns = tf_component.state.tf.info.Columns;
    let head = [];
    let fixCol = tf_component.state.tf.info.nFixedCols;

    head.push(
        <div
            className={"GridItem context-menu-one col-title tf-title-left-cell"}
            unselectable="on"
            style={{
                height: tf_component.getRowHeight(index),
                width: leftSpace + "px",
            }}
            data-col-num={-1}
            key={"sticky-cell-title--1"}
        >
        </div>
    );

    let i_start = 0;
    let initialLeft = leftSpace;
    let cols_ln = info_columns.length; if (cols_ln > MAX_COLS_COUNT) cols_ln = MAX_COLS_COUNT;

    for (var i=i_start; i<cols_ln; i++) {
        let colInfo = tf_component.state.tf.info.Columns[i];
        let el;
        let fixed = i<fixCol;
        if (index === TITLE_ROW_INDEX)
            el = TitleCellOnly({i, fixed: fixed, colInfo, initialLeft});
        else if (index === ADD_ROW_INDEX) {
            el = null
        }

        head.push(el);
        initialLeft += tf_component.calcCellWidth(colInfo);
    }

    return (
        <div className="sticky tf-title-row minw80vw" style={{...style, zIndex: index === ADD_ROW_INDEX ? 3 : 10}} key={"sticky-0"}>
        {head}
        </div>
    );
}

export class TfAddRow extends React.Component {
    static contextType = TfContext

    constructor(props) {
        super(props);
        this.state = {
            curCell: {row: 0, columnKey: 0}
        }
    }

    componentDidUpdate() {
        const { tf_component } = this.context;
        generateTfContextMenu(tf_component);
    }

    render () {
        const {tf_component, stickyIndices} = this.context;

        let curCell = this.state.curCell;

        let rowIndex = this.props.rowIndex;
        let line_key = rowIndex;

        if (rowIndex !== ADD_ROW_INDEX)
            return null

        let add = 0;
        for (let ind of stickyIndices) {
            add += tf_component.getRowHeight(ind);
            //if (rowIndex === ADD_ROW_INDEX)
            break;
        }
        let style = this.props.style;
        if (rowIndex === ADD_ROW_INDEX)
            style.top = add;
        else
            style.top = (ROW_HEIGHT * rowIndex) + add;

        var row = [];
        let fixCol = tf_component.state.tf.info.nFixedCols;

        // Проверяем на необходимость подгрузки данных
        let show_cells = true;
        let rowIndexCalced = rowIndex;

        row.push(
            <div
                className={"GridItem readOnlyCell-css col-title tf-left-cell"}
                unselectable="on"
                style={{
                    width: tf_component.calcLeftSpaceWidth(tf_component.state.tf.total) + "px",
                }}
                key={"cell-row-left-" + rowIndex + "--1"}
            >
                {rowIndexCalced === ADD_ROW_INDEX ? "*" : (rowIndexCalced+1)}
            </div>
        );

        if (/*data_row != null || */true) {
            let cols_ln = tf_component.state.tf.info.Columns.length; if (cols_ln > MAX_COLS_COUNT) cols_ln = MAX_COLS_COUNT;

            for (let i=0; i<cols_ln; i++) {
                let fixed = i < fixCol;
                let colInfo = tf_component.state.tf.info.Columns[i];
                if (show_cells) {
                    let cell_data = tf_component.CellData(rowIndexCalced, i);
                    //lel ={'GridItem context-menu-one col-title tf-title-cell tf-title-cell-sticky'}
                    if (curCell.columnKey === i)
                        row.push(
                            <TfCurrentCell
                                rowIndex={ADD_ROW_INDEX}
                                i={i} style={style}
                                fixCol={fixCol}
                                fixed={fixed}
                                key={"cell-" + rowIndex + "-" + i}
                                colInfo={colInfo}
                                cell_data={cell_data}
                                initialAddCls={fixed ? 'tf-title-cell tf-title-cell-sticky' : ''}
                                />
                        );
                    else
                        row.push(
                            <AddRowCell
                                rowIndex={ADD_ROW_INDEX}
                                i={i}
                                style={style}
                                fixCol={fixCol}
                                fixed={fixed}
                                colInfo={colInfo}
                                intoTitle={true}
                                key={"cell-edit-" + rowIndex + "-" + i}
                                />
                            // <TfCell
                            //     rowIndex={rowIndex}
                            //     i={i} style={style}
                            //     fixCol={fixCol}
                            //     fixed={fixed}
                            //     key={"cell-" + rowIndex + "-" + i}
                            //     colInfo={colInfo}
                            //     cell_data={cell_data}
                            //     initialAddCls={fixed ? 'tf-title-cell tf-title-cell-sticky' : ''}
                            //     />
                        );
                }
            }
        }

        return (
            <div
                className="sticky tf-title-row minw80vw"
                key={line_key}
                data-row-num={rowIndex}
                style={{
                    top: style.top + GUTTER_SIZE,
                    backgroundColor: '#ffffff',
                    //zIndex: 7,
                    //zIndex: rowIndex === ADD_ROW_INDEX ? 3 : 10
                    zIndex: 10
                }}
            >
                {row}
            </div>
        );
    }
}


