
export const Dialog = ({children, handleOk, handleCancel, title='Диалог', textOk='Принять', textCancel='Отмена', styleBox={height: '80vh'}, hideCancelBtn=false, modalContent="modal-content"}) => {
    const display = 'flex';
    //const cancelClass = hideCancelBtn;
    return (
        <div style={{display: display, justifyContent: 'center'}} className="modal screen-block"
        tabIndex='-1'
        role='dialog'
        aria-hidden='true'>
            <div className="screen-back" onClick={handleCancel}></div>
            <div className={modalContent+" screen-content"} style={styleBox}>
                <div className='modal-header'>
                    <div>
                        <h5 className='modal-title'>{title}.</h5>
                    </div>
                    <button className='close' type='button' data-dismiss='modal' onClick={handleCancel}>
                        <span aria-hidden='true'>&times;</span>
                    </button>
                </div>

                <div className='modal-body screen-body' style={{display: 'flex'}}>
                    {children}
                </div>

                <div className='modal-footer'>
                    <div className='screen-btns'>
                        <button className='btn btn-primary screen-btn' onClick={handleOk}>{textOk}</button>
                        <button className='btn btn-primary screen-btn' onClick={handleCancel}>{textCancel}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
