import React from 'react';
import { LOAD } from '../common/load.js';
import { fillDictByExistKeys, checkInDictAndFill, convertKompasValueToWeb, findColumnPosition } from '../common/helpers.js';
import { FieldKind, FieldType, Restrict} from '../common/helpers.js';

const helpListLimit = 10;
export class ScreenField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           value: '',
           datalist: [],
           valueRestrict: Restrict.rAllowed,
           total: 0,
        };
        fillDictByExistKeys(this.state, this.props);

        this.catalogTF = null; // FIXME это не ТФ, а данные из таблицы
        this.keyValues = {};
        this.catalogColPos = null;
        document._DEBUG(7, "screenField column:", this.props.column);
    }

    getCatalogColPos=()=>{
        if (this.catalogColPos !== null){
            return this.catalogColPos;
        }
        let colPos;
        if(this.hasCatalog()){
            colPos = findColumnPosition(this.catalogTF.info.Columns, this.props.column.LookupFieldName);
        }else if(this.props.column.FieldKind === FieldKind.tfkLookup){
            colPos = findColumnPosition(this.catalogTF.info.Columns, this.props.column.LookupResultField);
        }
        return colPos;
    }

    handleInput(e){
        let val = e.target.value;
        if(e.target.type === 'number'){
            // как сделать триады? FIXME
        }
        this.setState({value: val})
        document._DEBUG(5, 'handleInput--> value: ', val, val == null, typeof val, e, "data:", e.nativeEvent.data);

        if(val !== ""){
            if(this.hasCatalog() || this.props.column.FieldKind === FieldKind.tfkLookup){
                this.getCatalogData(val);
            }
        }

        if (this.props.onInput) {
            this.props.onInput(this.props.rowKey, this.props.index, val);
        }
    }

    handleKeyDown = (e) => {
        //document._DEBUG(7, "!!!", e);
        let need_stop_propagation = false;
        switch (e.key) {
            case 'ArrowLeft':
                need_stop_propagation = true;
                break
            case 'ArrowRight':
                need_stop_propagation = true;
                break
            case 'ArrowUp':
                need_stop_propagation = true;
                break
            case 'ArrowDown':
                need_stop_propagation = true;
                break
            case 'F7':
                this.handleBtnCatalogClick(e);
                e.preventDefault();
                break
            case "Escape":
                let base_value = this.props.value;
                this.onEscape();
                // if (this.props.tfEditRows) {
                //     let cellChanged = this.props.tfEditRows.findChangedCell(this.props.rowKey, this.props.index);
                //     if (cellChanged) {
                //         base_value = cellChanged.base_value;
                //         this.props.tfEditRows.onChangeCellValue(this.props.rowKey, this.props.index, base_value);
                //     }
                // }
                if (this.props.onCancel) {
                    if (this.state.value !== base_value) { // FIXME избавиться от mutating
                        let st = this.state;
                        st.value = base_value;
                    }
                    this.props.onCancel();
                    need_stop_propagation = true;
                } else {
                    if (this.state.value !== base_value) {
                        this.setState({value: base_value});
                    }
                }
                break
            case "Enter":
                if (this.props.onCancel) {
                    this.props.onCancel();
                    need_stop_propagation = true;
                }
                break
            default:
                break
        }
        if (need_stop_propagation) {
            e.stopPropagation();
        }
    }

    onEscape = () => {
        let base_value = this.props.value;
        if (this.props.tfEditRows) {
            let cellChanged = this.props.tfEditRows.findChangedCell(this.props.rowKey, this.props.index);
            if (cellChanged) {
                base_value = cellChanged.base_value;
                this.props.tfEditRows.onChangeCellValue(this.props.rowKey, this.props.index, base_value);
            }
        }
    }

    generateDatalist=(_value, total)=>{
        let colPos = this.getCatalogColPos();
        let datalist = [];
        for (let i in this.catalogTF.data) {
            let value = this.catalogTF.data[i].row[colPos];
            if (value.toLowerCase != null && _value.toLowerCase != null &&
                    value.toLowerCase().includes(_value.toLowerCase())) {
                datalist.push(value);
            }
        }
        this.setState({datalist: datalist, total});
        console.log('datalist: ', datalist);
    }

    saveInTableForm=()=>{
        if('onSaveInTableForm' in this.props){
            this.props.onSaveInTableForm(this.props.rowKey, this.props.column.FieldName, this.state.value, this.props.columnKey,
                this.keyValues, this.state.valueRestrict);
        }
    }

    getType=()=>{
        let datatype = this.props.column.DataType;
        if(this.props.column.FieldKind === FieldKind.tfkLookup){
            return '';
        }
        switch(datatype){
            case FieldType.tftNumeric:
                return 'number'
            case -1:
            case FieldType.tftCharacter:
                if(this.isTime(this.props.column)){
                    return 'time'
                }else{
                    return ''
                }
            case FieldType.tftDate:
                return 'date'
            default:
                return null
        }
    }

    //FIXME Функция для костыля по определению поля с типом время, пока в метаданных такого типа нет
    isTime=(column)=>{
        let f = column.FieldName;
        if(f === 'CREATED_AT' || f === 'CHANGED_AT' || f.substring(0, 5) === 'TIME_'){
            return true;
        }else{
            return false;
        }
    }

    onChooseCatalog=(_value)=>{
        let state = {
            value: _value,
            valueRestrict: Restrict.rAllowed
        }
        document._DEBUG(5, 'onChooseCatalog--> 1', state, "catalogTF", this.catalogTF!=null, this.props.column.FieldKind, "/", FieldKind.tfkLookup);
        this.setState(state, () => {
            this.focus();
        });
        document._DEBUG(5, 'onChooseCatalog--> 2', this.catalogTF ? true: false, this.props.column.FieldKind, "/", FieldKind.tfkLookup);

        let onInput = this.props.onInput ? this.props.onInput : (rowKey, colKey, val) => {}
        // добавляем изменение в измененные строки
        onInput(this.props.rowKey, this.props.index, _value);

        if(this.catalogTF && this.props.column.FieldKind === FieldKind.tfkLookup){
            let colPos = this.getCatalogColPos();
            document._DEBUG(5, 'onChooseCatalog--> colPos:', colPos);
            for(let i in this.catalogTF.data){
                let value = this.catalogTF.data[i].row[colPos]; // FIXME
                if(value === _value){
                    // FIXME сделано только для одного ключа
                    let keyFields = this.props.column.KeyFields;
                    let colKeyPos = findColumnPosition(this.catalogTF.info.Columns, keyFields);
                    let keyValue = this.catalogTF.data[i].row[colKeyPos];
                    this.keyValues[keyFields] = keyValue;

                    // добавляем и это изменение
                    onInput(this.props.rowKey, colKeyPos, keyValue);
                    break;
                }
            }
        }
        document._DEBUG(5, 'onChooseCatalog--> ', _value, 'keyValues: ', this.keyValues);
    }

    handleBtnCatalogClick(e){
        document._DEBUG(5, "----- CHECK hasCatalog:", this.hasCatalog());
        if (this.hasCatalog()) {
            if (e != null)
                e.stopPropagation();
            this.handleCallCatalog();
        }
    }

    handleCallCatalog = () => {
        this.props.catalogControl.setCurrentScreenField(this);
        this.prepareCatalog();
        this.props.catalogControl.showCatalog({
            title: this.props.column.LookupTfAlias,
            show: true,
        });
    }

    getCatalog = (list) => {
        if (this.hasCatalog() || this.hasOptions()) {
            this.prepareCatalog();
            return (
                <div>
                    <button className="catalog-btn btn btn-secondary"
                        onClick={this.handleBtnCatalogClick.bind(this)}
                        style={{height:'28px', width:'28px', lineHeight: '1', padding: '0px' }}
                    >...</button>
                    <datalist id={list}>
                        {this.getOptionsForDatalist()}
                    </datalist>
                </div>
            )
        }else if (this.props.column.FieldKind === FieldKind.tfkLookup){
            return (
                <datalist id={list}>
                    {this.getOptionsForDatalist()}
                </datalist>
            )
        }else{
            return ''
        }
    }

    prepareCatalog = () => {
        document._DEBUG(5, "prepareCatalog -- Options:", this.props.column.Options, "hasCatalog:", this.hasCatalog())
        if (this.hasCatalog())
            this.props.catalogControl.prepareCatalog({
                ref: (el)=>{this.Catalog = el},
                tfalias: this.props.column.LookupTfAlias,
                fieldName: this.props.column.LookupFieldName,
                onChoose: this.onChooseCatalog
            });
        else
            this.props.catalogControl.prepareCatalog({
                ref: (el)=>{this.Catalog = el},
                catalogOptions: this.props.column.Options,
                fieldName: 'Value',
                onChoose: this.onChooseCatalog
            });
    }

    getOptionsForDatalist=()=>{
        if (this.props.column.Options) {
            return this.props.column.Options.map((val, index)=>{
                return <option key={index} value={val.value}>{val.name}</option>
            });
        }
        let options =  this.state.datalist.map((val, index)=>{
            if(this.state.datalist.length === index+1){
                let more = ''
                if(this.state.total > helpListLimit){
                    more = " ...еще вариантов: "+(this.state.total-helpListLimit);
                }
                return <option key={index} value={val}>{more}</option>
            }else{
                return <option key={index} value={val}/>
            }
        });
        return options;
    }

    // есть настроенный справочник
    hasCatalog = () => {
        return (this.props.column.LookupTfAlias !== '');
    }

    // есть настроенный список значений для расшифровки
    hasOptions = () => {
        return (this.props.column.Options != null);
    }

    getCatalogData=(val)=>{
        let filters = [
            {
                "operator": "like",
                "values": ["%"+val+"%"]
            }
        ]
        let limit = helpListLimit;
        if(this.hasCatalog()){
            let fields = [this.props.column.LookupFieldName];
            filters[0].field = this.props.column.LookupFieldName;
            // FIXME получаем только первые 100 записей для проверки по справочнику, а не весь справочник.
            this.catalogColPos = 0;
            this.xhr = LOAD.get_tbl(this.props.column.LookupTfAlias, this.loadCatalogData, {fields, filters, limit, isTfAlias: true});
            // this.xhr = LOAD.get_tf(this.props.column.LookupTfAlias, this.loadCatalogData);
        }else if(this.props.column.FieldKind === FieldKind.tfkLookup){
            // FIXME пока учитываю только вариант с ключом из одного поля
            let fields = [this.props.column.LookupKeyFields, this.props.column.LookupResultField];
            filters[0].field = this.props.column.LookupResultField;
            this.catalogColPos = 1;
            this.xhr = LOAD.get_tbl(this.props.column.LookupTableAlias, this.loadCatalogData, {fields, filters, limit});
        }
    }

    loadCatalogData=()=>{
        let xhr = this.xhr;
        if (xhr.status === 200) {
            let txt = xhr.responseText;
            let objTf = {};
            objTf = JSON.parse(txt);
            console.log('total: ', objTf.total);
            if (objTf.data!=null) {
                this.catalogTF = objTf;
                this.generateDatalist(this.state.value, objTf.total);
            }
        }
    }

    isForbidden=()=>{
        return this.state.valueRestrict === Restrict.rForbid;
    }

    isWarning=()=>{
        return this.state.valueRestrict === Restrict.rWarning;
    }

    checkValueByCatalog=(_value)=>{
        document._DEBUG(5, "checkValueByCatalog 0");
        let result = true;
        if(this.state.value !== "" && ((this.hasCatalog() && this.props.column.LookupLinkType > 1) || this.props.column.FieldKind === FieldKind.tfkLookup)){
            document._DEBUG(5, "checkValueByCatalog 1. this.catalogTF:", this.catalogTF);
            result = false;
            if(this.catalogTF){
                document._DEBUG(5, "checkValueByCatalog 2");
                let colPos = this.getCatalogColPos();
                document._DEBUG(5, "checkValueByCatalog 3. colPos =", colPos);
                for(let i in this.catalogTF.data){
                    let value = this.catalogTF.data[i].row[colPos];
                    if(value === _value){
                        result = true;
                        if(this.props.column.FieldKind === FieldKind.tfkLookup){
                            // FIXME сделано только для одного ключа
                            //let lookupKeyFields = this.props.column.LookupKeyFields;
                            let keyFields = this.props.column.KeyFields;
                            // let colPos = findColumnPosition(this.catalogTF.info.Columns, lookupKeyFields);
                            this.keyValues[keyFields] = this.catalogTF.data[i].row[0];
                        }
                        break;
                    }
                }
            }else{
                console.log('Почему то нет данных справочника!', this.props.column.FieldKind);
                result = false;
            }
            document._DEBUG(5, "checkValueByCatalog result =", result);
            if(result){
                this.setState({valueRestrict: Restrict.rAllowed})
            }else{
                if(this.props.column.FieldKind === FieldKind.tfkLookup){
                    document._DEBUG(5, "checkValueByCatalog result => FORBID");
                    this.setState({valueRestrict: Restrict.rForbid});
                }else{
                    document._DEBUG(5, "checkValueByCatalog result => LookupLinkType:", this.props.column.LookupLinkType, "= FORBID:", this.props.column.LookupLinkType === Restrict.rForbid);
                    this.setState({valueRestrict: this.props.column.LookupLinkType});
                }
            }
        }
        return result;
    }

    onBlur=()=>{
        //setTimeout(()=>{
            let isCatalogShowing = this.props.catalogControl.isCatalogShowing()
            document._DEBUG(7, 'onBlur--> isCatalogShowing =', isCatalogShowing);
            // if (isCatalogShowing) {
            //     return
            // }
            this.checkValueByCatalog(this.state.value)
        //}, 100);
    }

    getInputPlace=(id, cl, type, value)=>{
        let len = this.props.column.Len;
        let readOnly = checkInDictAndFill(this.props.column, 'ReadOnly', false)
        let style = {...this.props.style, height: '28px', paddingLeft: '2px', paddingRight: '2px', border: null};
        let list = 'forCatalog'+this.props.column.FieldName+this.props.index;
        if('tf' in this.props && this.props.tf){list+='_TF'};
        // let maxW = 225;
        // let maxWidth = Math.min(len*10+30, maxW)+'px';
        // style.maxWidth = maxWidth;
        let divClass = 'cell-for-input';
        if(this.props.directMode === 1){
            divClass = 'cell-for-input-vertical';
        }

        let fieldClass = 'cell-input';
        cl += ' ' + fieldClass;

        // if (this.hasOptions()) {
        //     type = '';
        // }

        let w = 20;
        if(value || value === ''){
            let v_str = value + "";
            w = Math.max(v_str.length+5, 20);
        }
        if(this.state.valueRestrict === Restrict.rWarning){
            cl += ' field-warning';
        }else if(this.state.valueRestrict === Restrict.rForbid){
            cl += ' field-forbidden';
        }

        if (len >= 50 && !(this.hasCatalog() || this.hasOptions())){
            cl += ' screen-textarea'
            style.minHeight = '100px';
            if (!this.props.isIntoScreenForm) {
                if(this.props.directMode === 1){
                }else{
                    style.border = "2px solid #777";
                }
            }
            let marginLeft = '-5px';
            if (this.props.directMode === 1){
                marginLeft = '10px'
            }
            return (
                <div className='cell-for-textarea' style={{/*display: 'flex'*/ marginTop: '-5px', marginLeft: marginLeft}}>
                    <textarea className={cl} id={id} value={value} readOnly={readOnly}
                        style={style} list={list} autoComplete={'off'}
                        maxLength={len} wrap='soft'
                        onInput={this.handleInput.bind(this)}
                        ref={(el)=>{this.input = el}}
                        onFocus={() => {
                            this.props.catalogControl.setCurrentScreenField(this);
                            this.input.select()
                        }}
                        onBlur={this.onBlur}
                        onKeyDown={this.handleKeyDown}
                        size={w}
                        onClick={(e) => {
                            if (e.detail === 1) {
                                e.stopPropagation();
                            }
                        }}
                    ></textarea>
                    {this.getCatalog(list)}
                </div>
            )
        }else{
            if(this.props.column.FieldKind === FieldKind.tfkLookup){
                len = 255;
            }
            if (!this.props.isIntoScreenForm) {
                if(this.props.directMode === 1){
                }else{
                    style.border = "2px solid #777";
                }
            }
            //style.width = '100%';
            if (value==null)
                value = "";
            let marginLeft = '-5px';
            if (this.props.directMode === 1){
                marginLeft = '10px'
            }
            return (
                <div className={divClass} style={{marginTop: '-5px', marginLeft: marginLeft}}>
                    <input className={cl} id={id} type={type} value={value} readOnly={readOnly}
                        style={style} list={list}
                        maxLength={len}
                        onInput={this.handleInput.bind(this)}
                        onKeyDown={this.handleKeyDown}
                        ref={(el)=>{this.input = el}}
                        onFocus={() => {
                            this.props.catalogControl.setCurrentScreenField(this);
                            this.input.select()
                        }}
                        onBlur={this.onBlur}
                        autoComplete={'off'}
                        size={w}
                        onClick={(e) => {
                            if (e.detail === 1) {
                                e.stopPropagation();
                            }
                        }}
                    ></input>
                    {this.getCatalog(list)}
                </div>
            )
        }
    }

    getLabel=(id)=>{
        if('tf' in this.props && this.props.tf){
            return '';
        }else{
            let labelCss = '';
            if (this.props.directMode === 1){
                labelCss = 'label-vertical';
            }
            return (<label className={labelCss} htmlFor={id}>{this.props.title}</label>)
        }
    }

    focus = () => {
        this.input.focus();
    }

    blur = () => {
        this.input.blur();
    }

    componentDidMount(){
        document._DEBUG(7, "screenField.componentDidMount");
        //this.props.catalogControl.setCurrentScreenField(this);

        if(!(this.props.column.ReadOnly) && !('saveValueInSF'in this.props)){
            this.input.focus(); // FIXME
        }
        if (this.props.openCatalog) {
            this.handleCallCatalog();
            this.props.resetOpenCatalog();
        }
    }

    componentDidUpdate(prevProps, prevStates){
        document._DEBUG(7, "screenField.componentDidUpdate");

        if(this.state.value !== prevStates.value){
            if('saveValueInSF' in this.props){
                this.props.saveValueInSF(this.props.column.FieldName, this.state.value, this.state.valueRestrict);
            }
            if(this.catalogTF && this.props.column.FieldKind === FieldKind.tfkLookup){
            }
        }
        // FIXME скорей всего тут будет двойной рендер. Надо проверить и поправить.
        if(this.props.value !== prevProps.value){
            let val = this.props.value;
            this.setState({value: val})
        }
    }

    componentWillUnmount(){
        let isCatalogShowing = this.props.catalogControl.isCatalogShowing();
        document._DEBUG(7, 'componentWillUnmount ScreenField--> ', this.state.value, "isCatalogShowing:", isCatalogShowing)
        this.props.catalogControl.setCurrentScreenField(null);

        // Если отображается каталог, значит причина unmount-а именно в открытии каталога, а значит сохранение не требуется
        if(!isCatalogShowing && this.state.value !== this.props.value){
            this.saveInTableForm();
        }
    }

    render() {
        document._DEBUG(7, "screenField.render");
        let id = 'ScreenForm_'+this.props.index;
        let type =this.getType();
        let value = convertKompasValueToWeb(this.state.value, this.props.column.DataType);
        let cl = 'form-control field'
        if(type === 'date'){
            cl += ' date-field';
        }else if(type === 'time'){
            if(this.props.column.Len === 5){
                cl += ' time-field';
            }else{
                cl += ' timesec-field';
            }

        }else if(type === 'number'){
            cl += ' number-field';
        }
        let clInputBlock = 'input-block'
        if (this.props.directMode === 1){
            clInputBlock = 'input-block-vertical'
        }
        if('tf' in this.props && this.props.tf){clInputBlock = ''}
        return (
            <div className={clInputBlock}>
                {this.getLabel(id)}
                {this.getInputPlace(id, cl, type, value)}
            </div>
        );
    }
}