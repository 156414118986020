
import { LOAD } from "../common/load";
import { MakePromise } from "./bp_common";

export class Sql {

    constructor(engine, sql_id, args) {
        // if (sql.is_sql_text) {
        //     this.sql_text = sql;
        //     this.sql_alias = null;
        // } else {
        //     this.sql_alias = sql;
        //     this.sql_text = null;
        // }
        this.engine = engine;
        this.sql_id = sql_id;
        this.sql_arguments = args;
        this.sql_result = {};

        Object.defineProperty(this, "count", { // FIXME реализовать объекты работы с результатом запроса
            get: () => {
                return 0;
            }
        });
    }

    run = async () => {
        document._DEBUG(5, "not implemented (SQL)...");
        let promise = MakePromise();

        if (this.sql_id != null) {
            LOAD.run_sql(this.engine.url, this.sql_id, this.sql_arguments, (result)=>{
                this.sql_result = result;
                if (result && result.columns) {
                    for (let col of result.columns) {
                        document._DEBUG(5, "(sql-col-define)", col)
                        Object.defineProperty(this, col, {
                            get: () => {
                                document._DEBUG(5, "(sql-col-get)", col);
                                let i = result.columns.indexOf(col);
                                let val = null;
                                if (!(result.values == null || result.values.length === 0))
                                    val = result.values[0][i];
                                document._DEBUG(5, "  ->", val);
                                return val;
                            }
                        });
                    }
                }
                promise.ctrl.resolve(this);
            }, (e) => {
                promise.ctrl.resolve(this);
            });
        }
        // if (this.sql_text) {
        //     let items = this.sql_text.items;
        //     let data = {
        //         items: []
        //     };
        //     let items_curent = data.items;
        //     for (let i=0; i<items.length; i++) {
        //         items_curent.push(items[i]);
        //     }
        //     LOAD.run_sql(this.sql_text, data, ()=>{console.log("SQL.Answer GOT")});
        // }
        return promise;
    }

    gonext = () => {
        document._DEBUG(5, "not implemented (SQL.gonext)...");
    }

    goprev = () => {
        document._DEBUG(5, "not implemented (SQL.goprev)...");
    }

}
