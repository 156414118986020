import { notImplemented } from "./bp_common";

export class TForm {

    constructor(engine, component, псевдоним_ТФ, тип_документа, фильтр, режим) {
        this.engine = engine;
        this.component = component;
        this.псевдоним_ТФ = псевдоним_ТФ;
        this.тип_документа = тип_документа;
        this.фильтр = фильтр;
        this.режим = режим;
        this._Caption = this.псевдоним_ТФ; // FIXME Компас по-умолчанию использует что-то другое
        this._web = {
            DOK_ID: null,
            N_DOC: null
        }
        this._tform_connected = null;

        Object.defineProperty(this, "TFAlias", {
            get: () => {return this.псевдоним_ТФ;}
        });
        Object.defineProperty(this, "Fields", { // FIXME реализовать объект Поля
            get: () => {return {count: 0};}
        });
        Object.defineProperty(this, "Caption", {
            get: () => {return this._Caption;},
            set: (val) => {this._Caption = val;}
        });
        // Object.defineProperty(this, "columnvisibility", {
        //     get: () => {
        //         document._DEBUG(5, "not implemented (TForm.columnvisibility)");
        //         return false;
        //     },
        //     set: (val) => {
        //         document._DEBUG(5, "not implemented (TForm.columnvisibility = ...)");
        //     }
        // });
    }

    showmodal = async () => {
        let tfOptions = this._init_from_bp();

        // FIXME на самом деле здесь нужен не только режим каталога
        document._DEBUG(5, "ShowModal [ component ]:", this.component, "DOK_ID", this._web.DOK_ID, "tfOptions:", tfOptions);
        this.component.catalogControl.prepareCatalog({
            ref: (el)=>{this.component.Catalog = el},
            tfalias: this.псевдоним_ТФ,
            fieldName: null,
            onChoose: null,
            tfOptions: tfOptions
        });

        this.component.catalogControl.showCatalog({
            title: this._Caption,
            show: true,
        });

        return this.component.catalogControl.wait();
    }

    show = () => {
        console.log("TForm.show", this.псевдоним_ТФ);
        this.engine.app.handleClickMenuItem([this.псевдоним_ТФ, this.псевдоним_ТФ]);
    }

    showsfp = () => {
        alert("Открыть ЭФ из ТФ: " + this.псевдоним_ТФ + " c фильтром: " + this.фильтр);
        notImplemented("TForm.showsfp");
    }

    _connect_to_tform = (tform) => {
        this._tform_connected = tform;
        if (tform != null && tform.addedTfOptions != null) {
            let tfOptions = tform.addedTfOptions;
            if (tfOptions._from_bp != null && tfOptions._from_bp.connect_filter != null) {
                if (tfOptions._from_bp.connect_filter.TYP_D != null) {
                    this.тип_документа = tfOptions._from_bp.connect_filter.TYP_D;
                }
                if (tfOptions._from_bp.connect_filter.DOK_ID != null) {
                    this._web.DOK_ID = tfOptions._from_bp.connect_filter.DOK_ID;
                }
                if (tfOptions._from_bp.connect_filter.key != null) {
                    this._web.key = tfOptions._from_bp.connect_filter.key;
                }
            }
        }
    }

    _init_from_bp = (tfOptions) => {
        if (this.тип_документа != null || this._web.DOK_ID != null) {
            document._DEBUG(5, "}}}}}}} showmodal. тип_документа:", this.тип_документа, "DOK_ID:", this._web.DOK_ID);
            if (tfOptions == null) {
                tfOptions = {
                    _from_bp: {
                        connect_filter: {}
                    }
                }
            }
            if (this.тип_документа != null) {
                tfOptions._from_bp.connect_filter.TYP_D = this.тип_документа;
            }
            if (this._web.DOK_ID != null) {
                tfOptions._from_bp.connect_filter.DOK_ID = this._web.DOK_ID;
            }
            if (this._web.key != null) {
                tfOptions._from_bp.connect_filter.key = this._web.key;
            }
            if (this._web.tf_editable != null) {
                tfOptions._from_bp.tf_editable = this._web.tf_editable;
            }
            if (this._web.ok_button_text != null) {
                tfOptions._from_bp.ok_button_text = this._web.ok_button_text;
            }
            if (this._web.disable_close_on_buttons != null) {
                tfOptions._from_bp.disable_close_on_buttons = this._web.disable_close_on_buttons;
            }
            if (this._web.ignore_reload_updated_row != null) {
                tfOptions._from_bp.ignore_reload_updated_row = this._web.ignore_reload_updated_row;
            }
            if (this._web.Ef != null) {
                tfOptions._from_bp.Ef = this._web.Ef;
            }
            if (this._web.AfterCatalogEvent != null) {
                tfOptions._from_bp.AfterCatalogEvent = this._web.AfterCatalogEvent;
            }
            if (this._web.AfterFieldChangeEvent != null) {
                tfOptions._from_bp.AfterFieldChangeEvent = this._web.AfterFieldChangeEvent;
            }
            if (this._web.AfterSaveRowEvent != null) {
                tfOptions._from_bp.AfterSaveRowEvent = this._web.AfterSaveRowEvent;
            }
        }
        return tfOptions;
    }

    columnvisibility = (name, value) => {
        document._DEBUG(5, "not implemented ( TForm.columnvisibility() )");
    }

}
