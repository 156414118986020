//------------------------
import React from "react";
import { ScreenForm } from '../../components/screenform.js';
import { fillDictByExistKeys, convertWebValueToKompas, showNowDt, StartsWith, EndsWith } from '../../common/helpers.js';
import { LOAD } from '../../common/load.js';
import { CreateWindowDataShower, COLUMN_WIDTH, GUTTER_SIZE, CELL_PADDING, ROW_HEIGHT } from './tf_components.js';
import { ADD_ROW_INDEX, TITLE_ROW_INDEX } from './tf_row.js';
//import { cloneTFdata } from './tf_funcs.js';
import { TfFilter } from "./tf_filter.js";
import { TfSort } from "./tf_sort.js";
import { ToolBar } from "../../components/toolbar.js";
import { TitleBar } from "../../components/titlebar.js";
import { StatusBar } from "../../components/statusbar.js";
/*import { knotify } from "../../common/notify";*/
import { TfError, TfLoading } from "./tf_error.js";
import { CatalogControl } from "../../components/dialogs/catalog.js";
import { TfEditRows } from "./tfEditRows.js";
//import $ from 'jquery';
import { TfLoadControl } from "./tf_load_control.js";
import { TbControls } from "../../components/tb_controls.js";
import { ExportDocDialog, ExportDocControl } from "../../components/dialogs/export.js";
import { ChoiceDialog } from "../../components/dialogs/choiceDialog.js";
import { AskRgmGodDialog } from "../../components/dialogs/choiceRgmGod.js";
import { TfCheckValidation } from "./tf_validation.js";
// import { parseUrlParams } from "../../common/helpers";
// import contextMenu from "jquery-contextmenu";
// import "jquery-contextmenu/dist/jquery.contextMenu.css";
import { AppContext } from "../../common/app_context.js";
import { FileAddControl } from "../dialogs/file_add.js";
import { FilesListControl } from "../dialogs/files_list.js";
//import { BpLang } from "./../../bp_lang/bp_lang";
import { generateTfContextMenu } from "./tf_contextmenu.js";
import { ChoiceFiles } from "../../bp_lang/bp_choiceFile.js";
import { MakePromise } from "../../bp_lang/bp_common";
import { Kanban } from "../kanban/kanban.js";


const TF_WAIT_VARIANT = 5;         // ожидание данных тф (после запроса этих данных)
const TF_SUCCESS_VARIANT = 6;         // получение тф
const TF_ERROR_VARIANT = 7;   // получена пустая тф или ошибка при запросе тф(отсутсвие тф, ошибка сервера)
const TF_KANBAN_VARIANT = 8; // отобразить данные ввиде канбан
export const SORT_OFF = 0;             // режим настройки сортировки выкл
const SORT_ON = 1;              // режим настройки сортировки активирован (но еще не прощелкивалась ни одна колонка)
const SORT_ON_MULTI = 2;        // режим настройки сортировки активирован и реализован уже как минимум на одной колонке
export const SORT_OFF_TIMEOUT = 3;     // ожидание выхода из режима настройки сортировки (ожидаем ответа на запрос обновления тф после отпускания shift)

export class GenerateTForm extends React.Component {
    static contextType = AppContext

    constructor(props) {
        super(props);
        document._DEBUG(5, "TF start -----------------------------------", this.props);
        document._DEBUG(5, " props:", props);
        let obj_f = {};
        obj_f.column = "";
        let obj_sort = {};
        obj_sort.counter = 0;  //счетчик подключенных к сортировке колонок
        this.state = {
            s_filtr: obj_f,
            s_sort: obj_sort,
            curCell: this.make_NULL_CURCELL(0, 0),
            title_tf:null,
            variant: TF_WAIT_VARIANT,
            tf_r_err: '',
            err_detail: null,
            err_detail_show: false,
            tf:{},
            sf_show: false,
            editData: [],
            add_row: null,
            countSelectRows: 0,
            choice_prm: [],
            choice: false,
            askRgmGod: {input: []},
            _some_action: 0,
        };
        this._clearFromBp();
        this.addedTfOptions = props.addedTfOptions;
        this.updateFromBp();

        this.catalogControl = new CatalogControl(this); // SEE @for-lev: инициализация контроля Catalog
        this.fileAddControl = new FileAddControl(this);
        this.filesListControl = new FilesListControl(this);
        this.tfEditRows = new TfEditRows(this);
        this.tfLoadControl = new TfLoadControl(this);
        this.tb_controls = new TbControls(this);
        this.tfSort = new TfSort(this);
        this.tfCheckValidation = new TfCheckValidation(this);
        this.tfExportDocControl = new ExportDocControl(this);

        this.state_scroll = [0, 0];
        this.state_toolbar = null;
        fillDictByExistKeys(this.state, this.props);
        if ('tf' in this.props) {
            this.state.variant = TF_SUCCESS_VARIANT;
        }
        if (props.catalogOptions) {
            let data = props.catalogOptions.map((val, index)=>{
                return {
                    row: [val.value, val.name],
                }
            })
            this.state.tf = {
                info: {
                    Title: 'TITLE',
                    Columns: [
                        {
                            ColTitle: 'Value',
                            FieldName: 'Value',
                        },
                        {
                            ColTitle: 'Name',
                            FieldName: 'Name',
                        }
                    ]
                },
                data: data,
                total: data.length,
            }
            this.tfLoadControl._calcColumns(this.state.tf.info, this.state.tf.total);
            this.state.variant = TF_SUCCESS_VARIANT;
        }

        this.sortMode = SORT_OFF;      //режим установки сортировки (прощелкивания колонок с зажатым Shift)
        this.rowOnServer = null;
        this.tableLeft = null;
        this.keyFields = {};
        this.state_add_row = null;

        this.filterRef = React.createRef();
        this.restricts = [];
        this.tfBoxStyleRef = React.createRef();
        this.toolbarRef = React.createRef();
        this.screenFormRef = React.createRef();
        this.currentRowRef = React.createRef();
        this.addRowRef = React.createRef();
        this.GridRef = React.createRef();
    }

    updateFromBp = (kweb) => {
        document._DEBUG(5, "{{{{{{{{ [updateFromBp] kweb:", kweb, " TFORM addedTfOptions:", this.addedTfOptions);
        if (this.addedTfOptions != null) {
            if (this.addedTfOptions._from_bp != null)
                this._from_bp = this.addedTfOptions._from_bp;
        }
    }

    _clearFromBp = () => {
        this._from_bp = {
            connect_filter: null
        }
        this.addedTfOptions = null;
    }

    isNoExitModify = () => {
        return false;
        // let flag = false;
        // if (this.state.curCell.edit) {
        //     alert("Текущая ячейка в режиме редактирования. Выход из нее допустим только после клика мыши по ячейки с последующим нажатием Enter для подтверждения или Esc для отмены редактирования");
        //     flag=true;
        // }
        // return flag;
    }

    onAppKeydown = (e) => {
        console.log("!!!! tform:", e);
        let processed = true;
        switch (e.key) {
            case 'ArrowLeft':
                if (this.isNoExitModify())
                    return;
                this.moveCurCell(0, -1);
                break
            case 'ArrowRight':
                if (this.isNoExitModify())
                    return;
                this.moveCurCell(0, 1);
                break
            case 'ArrowUp':
                if (this.isNoExitModify())
                    return;
                if (e.shiftKey) {
                    this.addInLinesIns(0);
                }
                this.moveCurCell(-1, 0);
                break
            case 'ArrowDown':
                if (this.isNoExitModify())
                    return;
                if (e.shiftKey) {
                    this.addInLinesIns(0);
                }
                this.moveCurCell(1, 0);
                break
            case 'Escape':
                if (this.props.onEscape) {
                    this.props.onEscape();
                } else if (this.CurFieldRef) {
                    this.CurFieldRef.onEscape();
                    let curCell = this.state.curCell;
                    curCell.edit = false;
                    this.setState({curCell});
                } else if (this.state.add_row) {
                    this.toggleAddRow();
                } else {
                    processed = false;
                }
                break
            case 'Enter':
                if (this.props.onEnter) {
                    this.props.onEnter();
                    return true;
                }
                if (!this.currentRowRef.current)
                    return false;
                this.enableCellEdit(this.state.curCell.row, {}, this.currentRowRef.current.state.curCell.columnKey);
                break
            case 'Insert':
                if (this.isNoExitModify())
                    return;
                this.addInLinesIns(0);
                this.moveCurCell(1, 0);
                break
            case 'Shift':
                //if (this.sortMode < SORT_ON)  this.sortMode = SORT_ON;
                break
            default:
                processed = false;
                if (this.toolbarRef.current)
                    processed = this.toolbarRef.current.onAppKeydown(e);
                const { app } = this.context;
                document._DEBUG(5, "[tform] onAppKeydown processed:", processed, "activeComponent:", app.activeComponent);
                if (!processed && this.screenFormRef.current && this.screenFormRef.current.onAppKeydown) {
                    processed = this.screenFormRef.current.onAppKeydown(e);
                }
                break
        }
        //e.preventDefault();
        return processed;
    }

    onAppKeyUp = (e) => {
        console.log("!!!! tform:", e);
        let processed = true;
        switch (e.key) {
            case 'ArrowLeft': break;
            case 'ArrowRight': break;
            case 'ArrowUp': break;
            case 'ArrowDown': break;
            case 'Escape': break;
            case 'Enter': break;
            case 'Insert': break;
            case 'Shift':
                if (this.sortMode===SORT_ON_MULTI)  {
                    this.sortMode = SORT_OFF_TIMEOUT;
                    this.setSort();
                }
                break
            default:
                processed = false;
        }
        return processed;
    }

    isIncludedInLinesIns = (rowIndex) =>{
        let line = this.getRow(rowIndex);
        if (line.select!==true) {     //такая выглядящая на первый взгляд странной проверка связана с тем, что select может быть true, falsе, а может и не быть вообще (undefined)
            return false;
        } else {
            return true;
        }
    }

    addInLinesIns = (increment) => {
        //let rowIndex = this.currentRowRef.current.state.curCell.row + increment;
        let rowIndex = this.currentRowRef.current.props.rowIndex + increment;
        this.selectRow(rowIndex, true);
    }

    moveCurCell = (y, x) => {
        // if (y === 0) {
        //     if (this.currentRowRef.current) {
        //         let _curCell = this.currentRowRef.current.state.curCell;
        //         _curCell.columnKey += x;
        //         this.currentRowRef.current.setState({curCell: _curCell}, () => {
        //             this.toolbarRef.current.setState({
        //                 rerender: -this.toolbarRef.current.state.rerender
        //             });
        //         });
        //     }
        //     return;
        // }
        let curCell = {...this.state.curCell};
        let columnKey = this.currentRowRef.current != null ? this.currentRowRef.current.state.curCell.columnKey : this.state.curCell.columnKey;
        let new_col_key = ((columnKey) + x);
        let new_row_key = ((this.state.curCell.row) + y);

        if (new_col_key < 0)
            new_col_key = 0;
        else if (new_col_key >= this.state.tf.info.Columns.length)
            new_col_key = this.state.tf.info.Columns.length - 1;

        if (new_row_key < 0 && !( (new_row_key === ADD_ROW_INDEX) && this.state.add_row != null ))
            new_row_key = 0;
        else if (new_row_key < ADD_ROW_INDEX)
            new_row_key = ADD_ROW_INDEX;
        else if (new_row_key >= this.state.tf.total/*this.getData().length*/)
            new_row_key = this.state.tf.total/*this.getData().length*/ - 1;

        if (new_row_key !== this.state.curCell.row) {
            curCell.row = new_row_key;
        }
        curCell.columnKey = new_col_key;
        document._DEBUG(5, "moveCurCell:", curCell, "last:", this.state.curCell);
        let add_row = this.state.add_row;
        if (y !== 0 && add_row != null) {
            this.state_add_row = add_row;
            add_row = null;
        }
        let new_state = {
            curCell: curCell,
            add_row: add_row
        }
        document._DEBUG(5, ">>> new state:", new_state);

        let res = this.makeEnableCellEditOrNot(curCell.row, null, curCell.columnKey, curCell.edit);
        if (y === 0) {
            if (this.currentRowRef.current) {
                return;
            }
        }
        this.setState(res.state, res.after_state);
    }

    //проверяет переданную структуру с фильтрами на ниличие там хотя бы одного действующего фильтра, возвращает true/false
    checkFiltr() {
        let f = false;
        for (let key in this.state.s_filtr) {
            let f_str = this.state.s_filtr[key];
            if (key !== "column" && key !== "connect_filter" && f_str !== "") { // FIXME потенциальная проблема наложения column, если настройщик создаст такую колонку
                f = true;
                return true;
            }
        }
        return f;
    }

    //обработка щелчка по колонке в шапке тф
    handleClickHeaderTF(e, colNum) {
        if (e.shiftKey ) {
            if (this.sortMode < SORT_ON)  this.sortMode = SORT_ON;
            this.tfSort.changeSort(colNum);
        }
        if (this.filterRef.current && this.sortMode === SORT_OFF)
            this.filterRef.current.handleClickHeaderTF(e, colNum);
        //notify.showNotify(3, 'notify', 1, e.target.innerText);        //пример использования класса уведомлений
    }

    handleClickTbBtnFiltr = () => {
        if (!this.currentRowRef.current)
            return;
        let column = this.currentRowRef.current.state.curCell.columnKey;
        this.filterRef.current.handleClickTbFiltr(column);
    }

    resetOpenCatalog = () => {
        this.openCatalog = false;
    }


    cellHasCatalog = () => {
        if (this.state.tf.info!==undefined && this.currentRowRef.current) {
            let cur_col = this.state.tf.info.Columns[this.currentRowRef.current.state.curCell.columnKey];
            return cur_col==null ? false : (cur_col.LookupTfAlias !== '');
        } else
            return false;
    }

    cellHasOptions = () => {
        if (this.state.tf.info!==undefined && this.currentRowRef.current) {
            let cur_col = this.state.tf.info.Columns[this.currentRowRef.current.state.curCell.columnKey];
            return cur_col==null ? false : (cur_col.Options != null);
        } else
            return false;
    }

    handleClickTbBtnCatalog = () => {
        if (this.CurFieldRef || (!this.cellHasCatalog() && !this.cellHasOptions()))  {
            return;
        }
        if (!this.currentRowRef.current)
            return;
        let rowKey = this.state.curCell.row;
        let colkey = this.currentRowRef.current.state.curCell.columnKey;
        let column = this.state.tf.info.Columns[colkey];
        this.openCatalog = true;
        console.log("handleClickTbBtnCatalog >> ", rowKey, colkey);
        this.enableCellEdit(rowKey, column, colkey);

    }

    handleClickAddRow = () => {
        this.toggleAddRow();
    }

    toggleAddRow = () => {
        let add_row = this.state.add_row;
        let rowKey;
        if (add_row != null) {
            add_row = null;
            rowKey = 0;//this.state.curCell.row;
            this.tfEditRows.clearAll();
        } else {
            add_row = {};
            add_row.row = this.state.tf.info.Columns.map((col,j)=>{
                if (col.IsDefaultVal) {
                    if (StartsWith(col.DefaultVal, "\t<") && EndsWith(col.DefaultVal, ">\t")) {
                        //document._DEBUG(5, "!!!", col, "-", j, "-", col.DefaultVal, col.DefaultVal === "\t<уник>\t");
                    } else {
                        return col.DefaultVal;
                    }
                }
                return null;
            });
            rowKey = ADD_ROW_INDEX;
        }

        let res = this.makeEnableCellEditOrNot(rowKey, null, this.state.curCell.columnKey, false);
        res.state.add_row = add_row;

        this.setState(res.state, res.after_state);
    }

    getSelectedRows = () =>{
        let linesSel = [];
        for (let obj of this.state.tf.data) {
            if (obj.select===true) {
                linesSel.push({index: obj.index, key: obj.key});
            }
        }
        return linesSel;
    }
//------------------------------------------------------------
    handleClickDelRow = () => {
        if (this.state.add_row != null)
            return;
        //LOAD.del_row
        let start_dt = new Date().getTime();
        let requestBody = {};
        requestBody.data = {}
        let linesSel = this.getSelectedRows();
        if (linesSel.length > 0) {
            const del = window.confirm("Вы уверены, что хотите удалить помеченные записи?\n(Кол-во удаляемых записей: " + linesSel.length+")");
            if (!del)
                return;
            requestBody.datas = [];
            for (let line of linesSel) {
                requestBody.datas.push({key: line.key});
            }
            this.selectedRowsToNull = true;
        } else {
            const del = window.confirm("Вы уверены, что хотите удалить выбранную запись?");
            if (!del)
                return;
            let rowKey = this.state.curCell.row;
            // document._DEBUG(5, '[tform] handleClickDelRow rowKey=', rowKey, typeof rowKey);

            let data_row = this.rowByKey(rowKey);
            if (data_row == null) {
                return;
            }
            requestBody.data.key = data_row.key;
        }

        document._DEBUG(5, 'handleClickDelRow ==> requestBody', requestBody);
        let xhr = LOAD.del_row(this.props.tfalias, requestBody, this.delRowResponse.bind(this),
            ()=>{
                document._DEBUG(3, 'Произошла ошибка при связи с сервером.', xhr);
                //this.requestTfFromServer(this.props.tfalias);
            });
        console.log('[tform] handleClickDelRow FIN', showNowDt(start_dt));
    }

    setFilter(obj_f) {
        console.log("setFilter f: >> ", obj_f);
        this.loadFirst(obj_f, this.props.tfalias);
    }

    setSort = () =>{
        console.log("setSort sort: >> ", this.state.s_sort);
        this.loadFirst(this.state.s_filtr, this.props.tfalias);
    }

    loadFirst = (s_filtr, tfalias) => {
        this.tfLoadControl.load_first(s_filtr, tfalias);
    }

    gotoScreenForm = () => {
        let rowIndex = this.state.curCell.row;
        this.handleDoubleClickCell(this.state.tf.info.Columns, null, rowIndex-1);
    }

    handleClickCell = (_columns, colKey, row, rowKey, e) => {
        e.stopPropagation();
        document._DEBUG(5, "handleClickCell e.detail", e, "colKey:", colKey, "row:", row, "rowKey:", rowKey);
        let rowIndexPrev = this.currentRowRef.current.state.curCell.row;
        let colKeyPrev = this.currentRowRef.current.state.curCell.columnKey;
        if (e.shiftKey && !this.isNoExitModify()) {
            if (rowKey>rowIndexPrev) {

                    this.selectRows(rowIndexPrev, rowKey);


            } else {

                    this.selectRows(rowKey, rowIndexPrev);

            }
            e.preventDefault();
        }
        if (e.ctrlKey && !this.isNoExitModify()) {
            this.selectRow(rowKey, true);
        }
        let notExitModify = this.isNoExitModify();
        if ((e.shiftKey || e.ctrlKey) && !notExitModify) {
            this.moveCurCell(rowKey - rowIndexPrev, colKey - colKeyPrev);
        } else {
            let columns = this.state.tf.info.Columns;
            if (e.detail === 1) {
                let column = columns[colKey];
                this.handleOneClickCell(rowKey, column, colKey, e);
            }else if (e.detail === 2) {
                this.handleDoubleClickCell(columns, row, rowKey);
            }
        }
    }

    // FIXME считаю что проверку надо делать при попытке ухода со строки. А внутри строки давать ходить просто подсвечиваю некорректные варианты.
    // при переходе на row как компонент (а также ячейка как компонент) это было бы корректно реализовать там.
    handleOneClickCell = (rowKey, column, colKey, e) => {
        this.enableCellEdit(rowKey, column, colKey);
    }

    enableCellEdit = (rowKey, _column, colKey) => {
        if (this.isNoExitModify())
            return;
        let columns = this.state.tf.info.Columns;
        //let column = columns[colKey];
        let curCellEdit = false;
        document._DEBUG(7, "handleOneClickCell !!!!", !('catalog' in this.props), !this.props.catalog, colKey, columns[colKey], columns[colKey].ReadOnly);
        if ((!('catalog' in this.props) || !(this.props.catalog)) && !(columns[colKey].ReadOnly)) {
            curCellEdit = true;
        }
        // let prev_row = this.state.curCell.row;
        // let prev_col = this.state.curCell.columnKey;
        // let validation = undefined;
        // let new_value;
        // let new_cell = this.tfEditRows.findChangedCell(prev_row, prev_col);
        /*if (new_cell!=null &&  this.state.curCell.edit)  {
            new_value = new_cell.value;
            validation = this.tfCheckValidation.control_validation(this.props.tfalias, new_value, this.state.tf.info.Columns[prev_col], this.state.tf.data, prev_col);
        }
        if  (validation==false)
            return;
        */

        let res = this.makeEnableCellEditOrNot(rowKey, _column, colKey, curCellEdit);
        this.setState(res.state, res.after_state);
    }

    makeEnableCellEditOrNot = (rowKey, _column, colKey, curCellEdit) => {
        let columns = this.state.tf.info.Columns;
        let lastcurCellRow = this.state.curCell.row;

        let pos = this.calcCellPositionIsInViewport(rowKey, colKey, columns[colKey]);
        document._DEBUG(5, ":::", pos, this.state_scroll, pos[3]!==0 || pos[4]!==0);

        if (pos[3]!==0 || pos[4]!==0) {
            let x2 = this.state_scroll[0]+pos[3];
            let y2 = this.state_scroll[1]+pos[4];
            document._DEBUG(5, "::: SCROLL", x2, y2);
            this.GridRef.current.scrollTo({scrollLeft: x2, scrollTop: y2});
        }

        let column = columns[colKey];
        let curCell = {row: rowKey, column: column, columnKey: colKey, edit: curCellEdit};
        // if (this.CurFieldRef && rowKey === this.state.curCell.row &&
        //         this.currentRowRef.current != null && colKey === this.currentRowRef.current.state.curCell.columnKey) {
        //             document._DEBUG(5, '  handleOneClickCell >> BAD');
        //     return;
        // }

        if (this.currentRowRef.current) {
            this.currentRowRef.current.setState(
                {curCell: {row: rowKey, columnKey: colKey}}, () => {
                    this.toolbarRef.current.setState({
                        rerender: -this.toolbarRef.current.state.rerender
                    });
                })
        }
        // if(this.CurField && this.CurField.isForbidden() && (this.state.curCell.row !== rowKey || this.state.curCell.column !== column)){
        //     alert('Введенное значение являеться недопустимым!');
        //     return;
        // }
        let state = {
            curCell: curCell,
        }
        if (rowKey !== ADD_ROW_INDEX && this.state.add_row)
            state.add_row = null;

        let res = {
            state: state
        }
        if (lastcurCellRow !== curCell.row && this.screenFormRef.current != null) {
            this.setState({sf_show: false});
            res.after_state = () => {
                this.setState({sf_show: Date.now()});
            }
        }
        return res;
    }

    recalcRowCol = (row, col, columns, pos) => {
        let dx = pos[3];
        let dy = pos[4];
        while (dx !== 0 || dy !== 0) {
            if (dx > 0) { // выбранная ячейка правее зоны видимости
                if (col-1 < 0) {
                    dx = 0;
                    document._DEBUG(5, "--- FIN col...", col-1, "/", 0);
                } else {
                    let last_col = col;
                    col--;
                    document._DEBUG(5, "--- RIGHTER...", dx, "|", last_col, '-->', col);
                }
            } else if (dx < 0) { // выбранная ячейка левее зоны видимости
                if (col+1 >= columns.length) {
                    dx = 0;
                    document._DEBUG(5, "--- FIN columns.length...", col+1, "/", columns.length);
                } else {
                    let last_col = col;
                    col++;
                    document._DEBUG(5, "--- LEFTER...", dx, "|", last_col, '-->', col);
                }
            }
            if (dx === 0 && dy === 0) {
                break;
            }
            pos = this.calcCellPositionIsInViewport(row, col, columns[col]);
            dx = pos[3];
            dy = pos[4];
        }
        return [row, col];
    }

    calcCellPositionIsInViewport = (row, col, colInfo) => {
        let y = row * ROW_HEIGHT;
        if (!colInfo)
            colInfo = this.state.tf.info.Columns[col];
        let x = colInfo.initialLeft;

        let titleHeight = this.getRowHeight(TITLE_ROW_INDEX);
        let leftFixedWidth = this.calcLeftSpaceWidth(this.state.tf.total) + this.state.tf.info.Columns.fixedWidth;
        let _outerRef = this.GridRef.current._outerRef;
        let grid_rect = [this.state_scroll[0]+leftFixedWidth, this.state_scroll[1],
            this.state_scroll[0]+_outerRef.clientWidth, this.state_scroll[1]+_outerRef.clientHeight-titleHeight];

        let widthCalced = colInfo.widthCalced;
        let dx = x < grid_rect[0] ? (x-grid_rect[0]) : ( x+widthCalced > grid_rect[2] ? x+widthCalced - grid_rect[2] : 0);
        let dy = y < grid_rect[1] ? (y-grid_rect[1]) : ( y+ROW_HEIGHT > grid_rect[3] ? y+ROW_HEIGHT - grid_rect[3] : 0);

        return [grid_rect, x, y, dx, dy, [leftFixedWidth, titleHeight]];
    }

    handleDoubleClickCell=(columns, row, rowKey)=>{
        if('catalog' in this.props && this.props.catalog){
            this.props.catalogChoose();
        }else{
            this.setState({sf_show: Date.now()});
        }
    }

    handleClickTbBtnCloseTf=()=>{
        //if ('catalog' in this.props && this.props.catalog)
        if (this.props.onEscape != null)
            this.props.onEscape();
        else if (this.props.handleClickTbBtnCloseTf != null)
            this.props.handleClickTbBtnCloseTf();
    }

    handleClickTbBtnSave = () => {
        let row = this.state.add_row == null ? this.state.curCell.row : ADD_ROW_INDEX; // FIXME-!!!
        let tf = this.state.tf;
        document._DEBUG(5, "handleClickTbBtnSave: [tf]:", tf);
        this.saveRowOnServer(row);
        //this.tfEditRows.clearAll();
        this.setState({
            tf: tf
        })
    }

    setExportDoc = (prms) => {
        this.setState({exportDoc: prms});
    }

    isExportDocDialog = () => {
        if (this.state.exportDoc!=null && this.state.exportDoc.status!==0) {
            console.log("!!exportDoc >> ", this.state.exportDoc);
            if (this.state.exportDoc.status===1) {
                return(
                    < ExportDocDialog  prms = {this.state.exportDoc}
                        setExportDoc = {(prms) => this.setExportDoc(prms)}
                    />
                );
            }
            if (this.state.exportDoc.status===2) {
                this.tfExportDocControl.handleExportDoc(this.state.exportDoc);
            }
        }   else {
                return null;
        }
    }

    isChoiceDialog = ()=>{
        if (this.state.choice_prm.length!==0)    {
            //console.log("isChoiceDialog choice_prm", this.state.choice_prm);
            return (
                <ChoiceDialog
                    title = {this.state.choice_prm[0]}  textChoice = {this.state.choice_prm[1]} variants = {this.state.choice_prm[2]}
                    screenOut = {this}
                    promise = {this.state.choice_prm[3]}
                />
            )
        }
        else
            return ('');
    }

    askRgmGodDialogClose = () => {
        let askRgmGod = this.state.askRgmGod;
        askRgmGod.input = [];
        askRgmGod.output = '';
        this.setState({
            askRgmGod: askRgmGod
        })
    }

    setChoiceRgmGodDialog(nameVar) {
        let askRgmGod = this.state.askRgmGod;
        askRgmGod.input = [];
        askRgmGod.output = nameVar;
        this.setState({
            askRgmGod: askRgmGod
        })
    }

    isAskRgmGodDialog = () =>{
        //console.log("isAskRgmGodDialog >>", this.state.askRgmGod);
        if (this.state.askRgmGod.input.length!==0) {
            return (
                <AskRgmGodDialog
                    input = {this.state.askRgmGod.input} promise = {this.state.askRgmGod.promise}
                    askRgmGodDialogClose = {() => this.askRgmGodDialogClose()}
                    setChoiceRgmGodDialog = {(nameVar) => this.setChoiceRgmGodDialog(nameVar)}
                />

            )
        }
    }

    isScreenFormShow=(tune)=>{
        if(this.state.sf_show){
            let rowKey = this.state.curCell.row;
            let columns = this.state.tf.info.Columns;
            document._DEBUG(7, "... rowKey:", rowKey);
            let data_row = this.rowByKey(rowKey);
            if (data_row == null) {
                document._DEBUG(7, "BAD rowKey:", rowKey);
                return ('')
            }
            let row = data_row.row;

            document._DEBUG(5, "isScreenFormShow ==> ", "row: ", row, "rowKey: ", rowKey);
            return (
                <ScreenForm title={this.state.title_tf} columns={columns} row={row} rowKey={rowKey} show={true}
                    onCloseChanger={this.onCloseChangerSF}
                    onSave={this.onSaveSForm}
                    ref={this.screenFormRef}
                    app={this.props.app}
                    parentTform={this}
                    rerender={this.state.sf_show}
                    tf={this.state.tf}
                    modal={!this.props.need_show_ef_in_right}
                    hide_titlebar={tune ? tune.hide_titlebar : false}
                    hide_buttonsbar={tune ? tune.hide_buttonsbar : false}
                    efStyleAdd={tune ? tune.efStyleAdd : null}
                    >
                </ScreenForm>
            )
        }else{
            return('')
        }
    }

    onCloseChangerSF=()=>{
        this.setState({sf_show: false});
    }

    onSaveSForm=(values, rowKey)=>{
        this.rowOnServer = rowKey;
        //let tf = this.state.tf;

        let data_row = this.rowByKey(rowKey);
        if (data_row != null) {
            let changed = false;
            for (let fieldName in values){
                let value = values[fieldName].value;
                let colPos = this.findColumnPosition(fieldName);
                this.tfEditRows.onChangeCellValue(rowKey, colPos, value);
                data_row.row[colPos] = value;
                changed = true;
            }
            if (changed) {
                this.saveRowOnServer(rowKey);
            }
            // this.setState({
            //     tf: tf,
            //     rerender: Date.now()
            // });
        }
        // if (this.state.curCell && this.state.curCell.edit) {
        //     this.onCancelEditField();
        // }
    }

    onSaveSField=(rowKey, fieldName, value, columnKey, _keyFields, restrict)=>{
        this.onSaveSFieldBase(rowKey, fieldName, value, columnKey, _keyFields, restrict, ()=>{
            if (this._from_bp != null) {
                if (this._from_bp.AfterFieldChangeEvent != null) {
                    document._DEBUG(5, "@@@ this._from_bp.AfterFieldChangeEvent:", this._from_bp.AfterFieldChangeEvent, typeof this._from_bp.AfterFieldChangeEvent);
                    //if (typeof this._from_bp.AfterFieldChangeEvent === 'function') {
                    this.props.app.bpCall.call_func_or_bp(this._from_bp.AfterFieldChangeEvent, [fieldName], this);
                    //}
                }
            }
        });
    }

    onSaveSFieldBase=(rowKey, fieldName, value, columnKey, _keyFields, restrict, afterStateChange)=>{
        console.log('onSaveSField: ', restrict);
        if(!(this.restricts[rowKey])){
            this.restricts[rowKey] = [];
        }
        this.restricts[rowKey][columnKey]=restrict;
        let tf = this.state.tf;
        let colPos;
        if(fieldName === '' || fieldName == null){ // FIXME
            colPos = columnKey;
        }else{
            colPos = this.findColumnPosition(fieldName);
        }
        let data_row = this.rowByKey(rowKey);
        if (data_row == null) {
            return;
        }

        //this.tfEditRows.onChangeCellValue(rowKey, colPos, value);
        data_row.row[colPos] = value;

        for (let fieldName in _keyFields){
            let saveInKeyFields = true;
            let keyValue = _keyFields[fieldName];
            for (let i in this.state.tf.info.Columns){
                if(fieldName === this.state.tf.info.Columns[i].FieldName){

                    //this.tfEditRows.onChangeCellValue(rowKey, i, keyValue);
                    data_row.row[i] = keyValue;
                    saveInKeyFields = false;
                    break;
                }
            }
            if(saveInKeyFields){
                this.keyFields[fieldName] = keyValue;
            }
        }
        this.setState({tf: tf}, afterStateChange);
    }

    onCancelEditField = async () => {
        console.log("onCancelEditField");
        let columnKey = 0;
        if (this.currentRowRef.current)
            columnKey = this.currentRowRef.current.state.curCell.columnKey;
        let validation = true;
        let new_value;
        let new_cell = this.tfEditRows.findChangedCell(this.state.curCell.row, columnKey);
        if (new_cell!=null && this.state.curCell.edit)  {
            new_value = new_cell.value;
            validation = false;
            this.tfCheckValidation.control_validation(this.props.tfalias, new_value, this.state.tf.info.Columns[columnKey], this.state.tf.data, columnKey);
        }
        if  (!validation)
            return;

        let promise = MakePromise();
        this.setState({
            curCell: this.make_NULL_CURCELL(this.state.curCell.row, columnKey)
        }, () => {
            promise.ctrl.resolve();
        })
        return promise;
    }

    onCancelEditRow = () =>{
        let rowKey = this.state.curCell.row;
        let data_row = this.rowByKey(rowKey);
        let tf = this.state.tf;
        if (data_row == null) {
            this.tfEditRows.clearAll();
            return;
        }
        console.log("onCancelEditRow: data_row >> ", data_row);
        console.log("onCancelEditRow: tf >> ", tf);

        for (let i in this.state.tf.info.Columns){
            let column = this.state.tf.info.Columns[i];
            let value = data_row.row[i];
            if(column.FieldKind === 0){
                let changedCell = this.tfEditRows.findChangedCell(rowKey, i);
                if (changedCell != null && changedCell.base_value !== value) {
                    this.tfEditRows.onChangeCellValue(rowKey, i, changedCell.base_value);
                    data_row.row[i] = changedCell.base_value;


                }
            }
        }
        this.setState({tf: tf});
    }

    saveRowOnServer = async (rowKey, is_after_save_edit) => {

        if (this.state.curCell.edit && !is_after_save_edit) {
            if (this.state.curCell && this.state.curCell.edit) {
                await this.onCancelEditField();
            }
        }

        let start_dt = new Date().getTime();
        document._DEBUG(5, '[tform] saveRowOnServer rowKey=', rowKey, typeof rowKey, "[tf]:", this.state.tf);
        let requestBody = {};
        requestBody.fields = [];
        requestBody.data = {}
        let data_row = null;
        if (rowKey === ADD_ROW_INDEX) {
            data_row = this.state_add_row || this.rowByKey(rowKey);
            this.state_add_row = null;
        } else if (rowKey === 0 && this.state_add_row != null) {
            data_row = this.state_add_row;
            this.state_add_row = null;
            rowKey = ADD_ROW_INDEX;
        } else {
            data_row = this.rowByKey(rowKey);
        }
        document._DEBUG(5, '[tform] saveRowOnServer data_row:', data_row, "IS_ADD_ROW:", rowKey === ADD_ROW_INDEX);
        if (data_row == null) {
            return;
        }
        if (rowKey === ADD_ROW_INDEX) {
            if (this._from_bp != null) {
                let tmp_row = this.state.curCell.row;
                let tmp_state_add_row = this.state.add_row;
                let st = this.state;
                st.add_row = data_row;
                st.curCell.row = ADD_ROW_INDEX;
                if (this._from_bp.BeforeInsertEvent) {
                    await this.props.app.bpCall.call_func_or_bp(this._from_bp.BeforeInsertEvent, null, this);
                }
                st.curCell.row = tmp_row;
                st.add_row = tmp_state_add_row;
            }
        }
        requestBody.data.key = data_row.key;
        requestBody.data.row = [];
        requestBody.reload = true;
        for (let i in this.state.tf.info.Columns){
            let column = this.state.tf.info.Columns[i];
            let value = data_row.row[i];
            if(column.FieldKind === 0){
                let changedCell = this.tfEditRows.findChangedCell(rowKey, i);
                //if(this.startData[rowKey].row[i] !== value){
                if (changedCell != null && changedCell.base_value !== value) {
                    if(column.FieldKind === 0){
                        // FIXME не очень это хорошо - а вдруг будет присутствовать в keyFields
                        requestBody.fields.push(column.FieldName);
                        requestBody.data.row.push(convertWebValueToKompas(value, column.DataType));
                    }
                }
            }
        }
        if (rowKey === ADD_ROW_INDEX) {
            if (this._from_bp != null) {
                if (this._from_bp.connect_filter != null) {
                    if (this._from_bp.connect_filter.TYP_D != null) {
                        requestBody.fields.push("TYP_D");
                        requestBody.data.row.push(this._from_bp.connect_filter.TYP_D);
                    }
                    if (this._from_bp.connect_filter.DOK_ID != null) {
                        requestBody.fields.push("DOK_ID");
                        requestBody.data.row.push(this._from_bp.connect_filter.DOK_ID);
                    }
                    if (this._from_bp.connect_filter.key != null) {
                        for (const my_key in this._from_bp.connect_filter.key) {
                            let to_key = this._from_bp.connect_filter.key[my_key];
                            requestBody.fields.push(my_key);
                            requestBody.data.row.push(to_key);
                        }
                    }
                }
            }
        }
        for (let fieldName in this.keyFields){
            requestBody.fields.push(fieldName);
            requestBody.data.row.push(this.keyFields[fieldName]);
        }

        let save_row_func = (rowKey === ADD_ROW_INDEX) ? LOAD.add_row : LOAD.save_row;
        let can_multy = (rowKey === ADD_ROW_INDEX) ? false : true;
        let is_multy = false;
        document._DEBUG(5, '[tform] saveRowOnServer IS_ADD_ROW:', rowKey === ADD_ROW_INDEX);

        if (requestBody.fields.length > 0 && can_multy) {
            let linesSel = this.getSelectedRows();
            if (linesSel.length > 0) {
                requestBody.keys = [];
                for (let line of linesSel) {
                    requestBody.keys.push(line.key);
                }
                if (requestBody.keys.indexOf(requestBody.data.key) < 0) {
                    requestBody.keys.push(requestBody.data.key);
                }
                const ok = window.confirm("Вы уверены, что хотите изменить помеченные записи?\n(Кол-во изменяемых записей: " + requestBody.keys.length+")");
                if (!ok)
                    return;
                is_multy = true;
            }
        }

        document._DEBUG(5, '[tform] saveRowOnServer: requestBody', requestBody);
        if (requestBody.fields.length > 0) {
            document._DEBUG(5, 'requestBody--> ', requestBody);
            let xhr = save_row_func(this.props.tfalias, requestBody, (e) => {this.saveRowResponse(e, rowKey, is_multy)},
                ()=>{
                    document._DEBUG(3, 'Произошла ошибка при связи с сервером.', xhr);
                    if (rowKey === ADD_ROW_INDEX) {
                        this.requestTfFromServer(this.props.tfalias);
                    }
                });
        }
        console.log('[tform] saveRowOnServer FIN', showNowDt(start_dt));
    }

    saveRowResponse = async (e, rowKey, is_multy) => {
        let xhr = e.currentTarget

        switch(xhr.status) {
            case 200:
                let objTf = JSON.parse(xhr.response);
                document._DEBUG(5, 'saveRowResponse--> ', rowKey, xhr, objTf);

                this.tfEditRows.clearAll();

                let ignore_reload_updated_row = false;
                if (this._from_bp != null && this._from_bp.ignore_reload_updated_row)
                    ignore_reload_updated_row = true;

                if (rowKey === ADD_ROW_INDEX) {
                    // this.requestTfFromServer(this.props.tfalias);
                    // return
                } else {
                    if (!ignore_reload_updated_row) {
                        let data_row = this.rowByKey(rowKey);
                        if (data_row != null && data_row.row != null && objTf.data != null && objTf.data.row != null) {
                            let row = data_row.row;
                            let src = objTf.data.row;
                            for (let i=0; i<row.length; i++) {
                                if (row[i] !== src[i])
                                    row[i] = src[i];
                            }
                        }
                    }
                    if (this._from_bp != null) {
                        if (this._from_bp.AfterSaveRowEvent != null) {
                            let data_row = this.rowByKey(rowKey);
                            document._DEBUG(5, "@@@ this._from_bp.AfterSaveRowEvent:", data_row);
                            await this.props.app.bpCall.call_func_or_bp(this._from_bp.AfterSaveRowEvent, [data_row], this);
                        }
                    }
                }

                this.setState({
                    tf: this.state.tf,
                    add_row: null,
                })

                this.keyFields = {};

                if (is_multy) {
                    this.requestTfFromServer(this.props.tfalias);
                    return
                }

                if (rowKey === ADD_ROW_INDEX) {
                    this.requestTfFromServer(this.props.tfalias);
                    return
                }

                break
            default:
                document._DEBUG(3, '[saveRowResponse] Неизвестная ошибка', xhr);
                break
        }
    }

    delRowResponse = (e) => {
        let xhr = e.currentTarget

        switch(xhr.status) {
            case 200:
                this.requestTfFromServer(this.props.tfalias);
                break
            default:
                document._DEBUG(3, '[delRowResponse] Неизвестная ошибка', xhr);
        }
    }

    findColumnPosition = (fieldName) => {
        for (let pos in this.state.tf.info.Columns){
            if(this.state.tf.info.Columns[pos].FieldName === fieldName){
                return pos;
            }
        }
    }

    //проверяет надо ли показывать текущую строку в тф в соответствии с имеющимися фильтрами
    showRow(row, obj_f) {
        let show = true;
        for (let key in obj_f){
            let f_str = obj_f[key];
            if (key !== "column" && key !== "connect_filter"&& f_str!=="") {
                let cell_t = row[key];
                if (cell_t == null)   return false;
                if (typeof cell_t !== "string")   cell_t = String(cell_t);
                let cell_low = cell_t.toLowerCase();
                let fs_low = f_str.toLowerCase();
                let pos = cell_low.indexOf(fs_low);
                if (pos===-1)  return false;
            }
        }
        return show;
    }

    requestTfFromServer = (_tfalias) => {
        console.log("..requestTfFromServer");
        if (this.props.catalogOptions) {
            return;
        }
        if (this.currentRowRef.current) {
            if (this.state.curCell.row > 0) {
                let res = this.makeEnableCellEditOrNot(0, null, 0, false);
                this.setState(res.state, res.after_state);
            }
        }
        let tfalias = _tfalias;
        let new_state = {};
        if (tfalias !== "") {
            this.colNum = "";
            this.state_scroll = [0, 0];
            this.loadFirst(this.state.s_filtr, tfalias);

            new_state = {
                variant: TF_WAIT_VARIANT,
                countSelectRows: 0,
                add_row: null,
                lines_start: 0,
            };
        } else {
            console.log("..requestTfFromServer 1");
            new_state = {
                variant: TF_ERROR_VARIANT,
                countSelectRows: 0,
                tf_r_err: 'Попытка открыть табличную форму по пустому алиасу!',
                add_row: null,
            };
        }

        console.log("STATE -> wait TF");
        this.setState(new_state);
    }

    showTfLoadError(status, objTf) {
        switch (status)
        {
            case 200:
                this.showError("Таблица, связанная с выбранной табличной формой не определена!");
                break;
            case 404:
                this.showError("Ошибка 404, Описание Табличной формы "+this.props.tfalias+" не найдено!", objTf.message);
                break;
            case 500:
                this.showError("Ошибка 500 при запросе табличной формы "+this.props.tfalias+".", objTf.message);
                break;
            default:
                let err_add = (" Статус: "+status) ? status : "";
                this.showError("Ошибка при запросе табличной формы неизвестного типа.<br>" + err_add, objTf.message);
                break;
        }
    }

    showError(error, detail) {
        let new_state = {};
        new_state.variant = TF_ERROR_VARIANT;
        new_state.tf = {};
        new_state.tf_r_err = error;
        if (detail && detail.length > 0) {
            new_state.err_detail = detail;
        }
        this.setState(new_state);
    }

    componentDidMount() {
        var start_dt = new Date().getTime();
        document._DEBUG(5, '[tform] componentDidMount. tfalias:', this.props.tfalias, "app:", this.props.app, "parent:", this.props.parent);
        const { app } = this.context
        this.lastActiveComponent = app.activeComponent;
        app.activeComponent = this;

        this.requestTfFromServer(this.props.tfalias);
        console.log('[tform] componentDidMount FIN', showNowDt(start_dt));
    }

    componentWillUnmount() {
        const { app } = this.context
        app.activeComponent = this.lastActiveComponent;
    }

    shouldComponentUpdate (nextProps) {
        var start_dt = new Date().getTime();
        this.shouldComponentUpdate_dt = start_dt;
        document._DEBUG(7, '[tform] shouldComponentUpdate. tfalias:', this.props.tfalias, "->", nextProps.tfalias);
        document._DEBUG(7, '[tform] shouldComponentUpdate FIN', showNowDt(start_dt));
        return true;
    }

    componentDidUpdate(prevProps, prevState) {
        document._DEBUG(7, '[tform] componentDidUpdate curCell:', this.state.curCell, 'prevCell:', prevState.curCell);

        // FIXME этот код нужно проверить. Вероятно, он бесполезен!!!
        if (this.props.title_tf !== prevProps.title_tf) {
            if (this.props.title_tf !== this.state.title_tf) {
                this.tfEditRows.clearAll();
                let obj_f = {};
                obj_f.column = "";
                this.setState({
                    title_tf: this.props.title_tf,  // FIXME
                    s_filtr: obj_f
                });
            }
        }

        if (this.props.tfalias !== prevProps.tfalias) {
            document._DEBUG(5, '[tform] shouldComponentUpdate.requestTfFromServer. tfalias:', this.props.tfalias);
            //this.requestTfFromServer(prevProps.tfalias);
            this.tfEditRows.clearAll();
            this.requestTfFromServer(this.props.tfalias);
            return;// false;
        }

        let start_dt = new Date().getTime();
        if (this.state.variant === TF_SUCCESS_VARIANT) {

        }
        if (prevState.curCell && this.state.curCell && this.state.curCell.row !== prevState.curCell.row) {
            document._DEBUG(7, '[tform] componentDidUpdate 1');
            this.saveRowOnServer(prevState.curCell.row);
        } else if (this.rowOnServer) {
            document._DEBUG(7, '[tform] componentDidUpdate 2');
            this.saveRowOnServer(this.rowOnServer)
            this.rowOnServer = null;
        }

        //let notifyContainer = document.querySelector('.notify_down');
        //notify.showNotify(1, notifyContainer, 1, "Привет, мир! Это тост-сообщение. ееееееееееееееееееееееееееееееееееееееееееее");

        generateTfContextMenu(this);

        console.log('[tform] componentDidUpdate END', showNowDt(start_dt), "(", showNowDt(this.shouldComponentUpdate_dt), ")");
    }

    // Метод необходимо использовать из-за того, что работаем в оконном режиме данных.
    // Определяет, входит ли строка в текущее окно данных.
    IndexInRows = (rowIndex) => {
        let j = rowIndex - this.state.lines_start;
        if (j >= this.getData().length) {
            j = -2;
        }
        return j;
    }

    rowByKey = (rowIndex) => {
        if ((rowIndex === ADD_ROW_INDEX) && this.state.add_row != null) {
            return this.state.add_row;
        }
        let j = this.IndexInRows(rowIndex);
        if (j < 0) {
            return null;
        }
        return this.getData()[j];
    }

    getData = () => {
        return this.state.tf.data != null ? this.state.tf.data : [];
    }

    getRow = (rowIndex) => {
        if ((rowIndex === ADD_ROW_INDEX) && this.state.add_row != null) {
            return this.state.add_row;
        }
        let j = this.IndexInRows(rowIndex);
        return this.getData()[j];
    }

    CellData = (rowIndex, colIndex, on_not_found) => {
        let data_row = this.getRow(rowIndex);
        let cell;
        if (data_row != null && data_row.row != null) {
            cell = data_row.row[colIndex];
        } else {
            cell = null;//{value: null, index: null};
            if (on_not_found) {
                on_not_found[0] = true
            }
        }
        return cell;
    }

    getCurRowData = () => {
        let cur_row = this.state.curCell.row;
        let data_row = this.rowByKey(cur_row);
        if (data_row && data_row.row) {
            return data_row;
        }
        return null
    }

    selectRows = (start, end) =>{
        let tf = this.state.tf;
        let data = tf.data;
        let counter = this.state.countSelectRows;
        for (var i=start; i<=end; i++) {
            if (this.getData() ===[] || this.state.tf === {} || this.state.tf == null ) {
                return 0;
            }
            let select = data[i].select;
            data[i].select = true;
            counter = (select!==true) ? counter+1 : counter;
        }
        this.setState({tf: tf, countSelectRows: counter});
    }

    selectRow = (rowIndex, flag) =>{
        if (this.getData() ===[] || this.state.tf === {} || this.state.tf == null ) {
            return 0;
        }
        let tf = this.state.tf;
        let data = tf.data;
        let select = data[rowIndex].select;
        let counter = this.state.countSelectRows;
        if (!flag) {
            data[rowIndex].select = true;
            counter = (select!==true) ? this.state.countSelectRows+1 : this.state.countSelectRows;
        } else {
            data[rowIndex].select = (select!==true) ? true : false;
            counter = (select!==true) ? counter+1 : counter-1;
        }
        console.log("selectRow select counter >>", select, counter, rowIndex);
        this.setState({tf: tf, countSelectRows: counter});
    }

    calcLeftSpaceWidth = (countAll) => {
        return 10+(String(countAll).length)*10;
    }

    calcCellWidth = (colInfo, curCellEdit) => {
        let w = (colInfo.ColWidth == null || colInfo.ColWidth === 0) ? (COLUMN_WIDTH - GUTTER_SIZE) : (colInfo.ColWidth*5 + CELL_PADDING);
        if (curCellEdit && w < 150) {
            w = 150;
        }
        return w;
    }

    getFullWidth = () => {
        let widthCalced = (this.state.tf != null && this.state.tf.info != null) ? this.state.tf.info.Columns.widthCalced : 0;
        return widthCalced;
    }

    getRowHeight = (index) => {
        let titleWords = (this.state.tf != null && this.state.tf.info != null) ? this.state.tf.info.Columns.titleWords : 1;
        if (index >= 0 || index === ADD_ROW_INDEX)
            return ROW_HEIGHT;
        else if (index === TITLE_ROW_INDEX)
            return ROW_HEIGHT + (titleWords-1) * 16;
        else
            return 0;
    }

    rowKeyByIndex = (index) => {
        return index - 0 + this.state.lines_start;
    }

    make_NULL_CURCELL = (_row, _col) => {
        return {row: _row, column: {}, columnKey: _col, edit: false};
    }

    listButton =()=>{
        this.setState({variant: TF_WAIT_VARIANT})
        this.requestTfFromServer(this.props.tfalias);
    }

    isHaveKanbanMode = () => {
        if (this.state.tf.info["#KWEB"] != null && this.state.tf.info["#KWEB"]["Kanban"] != null )  {
            let kanban = this.state.tf.info["#KWEB"]["Kanban"];
            let error = false;
            let alert_text = "Данные для построения Канбана указаны некорректно:";

            if(!kanban.IN_STATES){error = true; alert_text += "\n Нет параметров справочника по которой строить"}
            else{
                if(!kanban.IN_STATES.TF){error = true; alert_text += "\n  Нет табличной формы справочника по которому строить канбан"};
                if(!kanban.IN_STATES.ID){error = true; alert_text += "\n  Не указано поле код справочника по которому строить канбан"};
                if(!kanban.IN_STATES.NAME){error = true; alert_text += "\n  Не указано имя состояния которое выводить пользователю"};
            };

            if(!error){
                let btnKanbanColor = "btn-primary";
                let btnListColor = "btn-primary";
                document._DEBUG(5, 'isHaveKanbanMode1==> ',this.state.variant, btnKanbanColor, btnListColor);
                switch (this.state.variant) {
                    case TF_KANBAN_VARIANT:
                        btnListColor = "btn-secondary";
                        break;
                    case TF_SUCCESS_VARIANT:
                        btnKanbanColor = "btn-secondary";
                        break;
                    default:
                        break;
                }
                document._DEBUG(5, 'isHaveKanbanMode2==> ',this.state.variant, btnKanbanColor, btnListColor);


                return (
                    <div className="kanban-btns-TFGroup">
                        <button type="button" onClick={()=>{this.setState({variant: TF_KANBAN_VARIANT})}} className={btnKanbanColor+" btn kanban-btn-TF"}>Канбан</button>
                        <button type="button" onClick={this.listButton} className={btnListColor+" btn kanban-btn-TF"}>Список</button>
                    </div>
                )
            }else{
                alert(alert_text);
            }
        }
    }

    render() {
        document._DEBUG(5, '[tform] render variant=', this.state.variant, 'state_scroll:', this.state_scroll);
        document._DEBUG(1, this.state.tf.data);

        let start_dt = new Date().getTime();
        let tfBoxStyle = {
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'space-between',
            //marginTop: '10px',
            flex: 1,
        };
        // if (this.props.need_show_ef_in_right) {
        //     tfBoxStyle.flexDirection = 'row';
        // }
        //console.log("filtr >> ", this.state.s_filtr, this.state.variant, this.props.tfalias);
        //console.log("choice_prm >>", this.state.choice_prm, this.state.choice);
        console.log("countSelectRows !!!!!>>", this.state.countSelectRows);

        switch (this.state.variant) {
            case TF_WAIT_VARIANT:
                return (
                    <TfLoading />
                )
            case TF_ERROR_VARIANT:
                return (
                    <TfError
                        error = {this.state.tf_r_err}
                        detail = {this.state.err_detail}
                    />
                )
            default:
                let start = this.state.lines_start + 1;
                let count = this.getData().length;
                let end = start + count - 1;
                let stroki = count > 0 ? (start + " : " + end) : "0";
                let titleBarEl = null;
                if (!this.props.hideTitleBar) {
                    titleBarEl = (
                        <TitleBar
                            app = {this.props.app}
                            tform = {this}
                            title = {this.state.title_tf}
                        />
                    )
                }
                let tfContainerStyle = {border: '1px solid #ccc', marginTop: '10px', flex: 1}
                if (this.props.need_show_ef_in_right) {
                    tfContainerStyle['maxHeight'] = 'calc(100% - 90px)';
                }
                let efStyleAdd = null;
                if (this._from_bp != null) {
                    if (this._from_bp.Ef != null && this._from_bp.Ef.Style != null) {
                        efStyleAdd = this._from_bp.Ef.Style;
                    }
                }
                let ret;
                let controlsTf = (
                    <div>
                        {/*вставка в разметку для использования механизма уведомлений*/}
                        <div className="notify" style = {{zIndex: '5', position: 'fixed', right: '1px', top: '51px'}}> </div>
                        <div style={{paddingLeft: '0px', paddingRight: '0px'}}>
                            {titleBarEl}
                            <div style={{display:'flex', flexDirection: 'row' }}>

                                <ToolBar
                                    toolbar = {this.state.tf.info.ToolBar}
                                    tform = {this}
                                    app = {this.props.app}
                                    ref = {this.toolbarRef}
                                    addedTfOptions = {this.addedTfOptions}
                                />

                                <TfFilter
                                    ref = {this.filterRef}
                                    s_filtr = {this.state.s_filtr}
                                    tfInfoColumns = {this.state.tf.info.Columns}
                                    setFilter = {this.setFilter.bind(this)}
                                    handleClickHeaderTF = {this.handleClickHeaderTF.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                )
                let controlsKanban = (
                    <div>
                        {/*вставка в разметку для использования механизма уведомлений*/}
                        <div className="notify" style = {{zIndex: '5', position: 'fixed', right: '1px', top: '51px'}}> </div>
                        <div style={{paddingLeft: '0px', paddingRight: '0px'}}>
                            {titleBarEl}
                        </div>
                    </div>
                )
                if(this.state.variant === TF_KANBAN_VARIANT){
                    ret = (
                        <div style={tfBoxStyle}  className="tf-page" __rerender={this.props.rerender}>
                            {controlsKanban}
                            <Kanban info={this.state.tf.info} tf={this}></Kanban>                            
                        </div>
                    );
                }else{
                    ret = (
                        <div style={tfBoxStyle}  className="tf-page" __rerender={this.props.rerender}>
                            {controlsTf}
                            <div style={tfContainerStyle} className="tf-container" >

                                <div className="tf-size-field">
                                    <CreateWindowDataShower
                                        tf_component={this}
                                        total={this.state.tf.total}
                                        add_row={this.state.add_row}
                                    />
                                </div>

                                <style className="tf_box_style" ref={this.tfBoxStyleRef}>
                                </style>

                                {this.props.need_show_ef_in_right ? this.isScreenFormShow({hide_titlebar:true, hide_buttonsbar:true, efStyleAdd:efStyleAdd}) : null}

                            </div>

                            <StatusBar
                                strings = {stroki}
                                total = {this.state.tf.total}
                                selectRows = {this.state.countSelectRows}
                            />

                            <ChoiceFiles component={this}   />
                            {this.isExportDocDialog()}

                            {this.isChoiceDialog()}

                            {this.isAskRgmGodDialog()}

                            {this.props.need_show_ef_in_right ? null : this.isScreenFormShow({efStyleAdd:efStyleAdd})}

                            {this.catalogControl.renderCatalog()}

                            {this.fileAddControl.renderFileAdd()}

                            {this.filesListControl.renderFilesList()}

                        </div>
                    );
                }

                document._DEBUG(5, '[tform] render -- FIN', showNowDt(start_dt), "(", showNowDt(this.shouldComponentUpdate_dt), ")");
                return ret;
        }
    }
}

//{this.isAskRgmGodDialog()}
