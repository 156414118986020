import React from "react";

export class CommonTemplate extends React.Component {

    print = () => {
        window.print();
    }

    render() {
        return (
            <div className="main-template mainPrint">
                <div className="common-template commonPrint">
                    <div className="head-template noPrint rightBlock">
                        <button onClick={()=>{alert('Пока в разработке')}} className="btn btn-primary">Загрузить</button>                        
                        <button onClick={this.print} className="btn btn-primary btn-print">Печатать</button>
                    </div>
                    <div className="body-template bodyPrint">
                        {this.props.body}
                    </div>
                </div>                
            </div>
        )
    }
}