import React from "react";
//import $ from 'jquery';
//import { BpLang } from "./../bp_lang/bp_lang";

export function TitleBar({app, tform, title}) {
    let buttons_end = tform.tb_controls.getButtonsGroup("buttons_end");
    let styleHelp = tform.tb_controls.buttonStyle(buttons_end[0]);
    let styleClose = tform.tb_controls.buttonStyle(buttons_end[1]);
    let handleClickhHelp = () => {
                app.bpCall.call_bp("OTLADKA.BP_EXAMPLE");
    };
    let btnStyle = {width: "36px",height: "34px"};
    let btnHelpStyle = {width: "36px",height: "34px", display: "none"};

    return (
        <div className="btn-toolbar my-1" style={{display:'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div className="flex">
                <p className="text-success tf-title-text" style={{fontWeight: 'bold'}} >{title}</p>
                {tform.isHaveKanbanMode()}
            </div>            
            <div className="btn-group"  >
                <button type="button" className="btn btn-outline-warning btn-sm" style = {btnHelpStyle}
                        onClick={handleClickhHelp} key={buttons_end[0][2]}>
                    <div className="fullsize" style={styleHelp} title={buttons_end[0][1]}></div>
                </button>

                <button type="button" className="btn btn-outline-warning btn-sm" style = {btnStyle}
                        onClick={tform.handleClickTbBtnCloseTf} key={buttons_end[1][2]}>
                    <div className="fullsize" style={styleClose} title={buttons_end[1][1]}></div>
                </button>
            </div>
        </div>
    );
}